import moment from "moment"
import { useEffect } from "react"
import { connect } from "react-redux"
import { modelOperations } from "../../redux/model"
import Popup from "../Popup/Popup"


const PopupHistory = ({
    closePopup,
    lang,
    onGetTransactionsPayout,
    getTransactionsPayout,
    getVendorInfo
}) => {

    useEffect(()=>{
        onGetTransactionsPayout({DEFAULT_COLUMN:lang})
    }, [])

    return (
        <Popup closePopup={closePopup} width="1000px" title="Payout history">

            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <td className="text-left"> Request id </td>
                        <td className="text-left"> Date </td>
                        <td className="text-left"> Amount </td>
                        <td className="text-left"> Type </td>
                        <td className="text-left"> Name </td>
                        <td className="text-left"> Account </td>
                        <td className="text-left"> Status </td>
                    </tr>
            </thead>
                <tbody>
                    {
                        // amount: 0.13
                        // coins_qty: 13
                        // currency: "ETH"
                        // payment_type: "Ethereum"
                        // status: 0
                        // transaction_date: 1631270061
                        // transaction_id: 77
                        getTransactionsPayout.map(({transaction_id, status, type, account, transaction_date, currency, coins_qty, amount, payment_type}, k)=>{
                            const amountA = Number(amount.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === getVendorInfo?.CURRENCY).map(({PRICE})=>PRICE).join(''))
                            const amountToken = Number(amount.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>PRICE).join(''))


                            return(<tr key={k}>
                                <td className="text-left"> {transaction_id}</td>
                                <td className="text-left"> {moment.unix(transaction_date).format('DD-MM-YYYY')} </td>
                                <td className="text-left"> 
                                {/* {amount} {currency} ({coins_qty} Hiiroad)  */}
                                <div style={{lineHeight: "8px", textAlign: 'center',display: 'flex', alignItems: 'center'}}> <i className='hiiroad'></i> <b style={{marginRight:6}}>{amountToken}</b>({ amountA } {getVendorInfo?.CURRENCY})</div>

                                </td>
                                <td className="text-left"> {type} </td>
                                <td className="text-left"> {payment_type} </td>
                                <td className="text-left"> {account} </td>
                                <td className="text-left">
                                    {status === 0 && <span className="label label-default">Pending</span>}
                                    {status === 1 && <span className="label label-dnager">Declined</span>}
                                    {status === 2 && <span className="label label-success">Approved</span>}
                                </td>
                            </tr>)   
                        })
                    }
                {/* <tr>
                        <td className="text-left"> 122343312</td>
                        <td className="text-left"> 22-11-2000 </td>
                        <td className="text-left"> 233 CAD (10 Hiiroad) </td>
                        <td className="text-left"> Blockchain </td>
                        <td className="text-left"> Bitcoin </td>
                        <td className="text-left"> 0xlkhsoir7w98348w734 </td>
                        <td className="text-left"> <span className="label label-default">Pending</span> </td>
                    </tr> */}
                </tbody>
            </table>

            <div className="text-center">

                <button type="button" className="btn btn-primary" onClick={()=>closePopup()} >Close </button>
            </div>

        </Popup>
    ) 
}
const mapStateToPorps = (state) => ({
    lang:state.lang.LANG,
    getTransactionsPayout:state.model.getTransactionsPayout,
    getVendorInfo:state.model.getVendorInfo,

})

const mapDispatchToProps = {
    onGetTransactionsPayout:modelOperations.getTransactionsPayout,

}

export default connect(mapStateToPorps, mapDispatchToProps)(PopupHistory);