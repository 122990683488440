import {createAction} from '@reduxjs/toolkit';


const registerRequest = createAction('auth/registerRequest')
const registerSuccess = createAction('auth/registerSuccess')
const registerError = createAction('auth/registerError')

const loginRequest = createAction('auth/loginRequest')
const loginSuccess = createAction('auth/loginSuccess')
const loginError = createAction('auth/loginError')

const logoutRequest = createAction('auth/logoutRequest')
const logoutSuccess = createAction('auth/logoutSuccess')
const logoutError = createAction('auth/logoutError')


export default{
    registerRequest,
    registerSuccess,
    registerError,

    loginRequest,
    loginSuccess,
    loginError,

    logoutRequest,
    logoutSuccess,
    logoutError,

    forgotPasswordVendorRequest:createAction('model/forgotPasswordVendorRequest'),
    forgotPasswordVendorSuccess:createAction('model/forgotPasswordVendorSuccess'),
    forgotPasswordVendorError:createAction('model/forgotPasswordVendorError'),

    registrationVendorRequest:createAction('model/registrationVendorRequest'),
    registrationVendorSuccess:createAction('model/registrationVendorSuccess'),
    registrationVendorError:createAction('model/registrationVendorError'),
}