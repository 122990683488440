
export const REDIRECT_URI = "https://hiiroad.com";


export const WEB_PICTURES_FILE= `${REDIRECT_URI}:8444/files/file/WEB_PICTURES/`
export const WEB_PICTURES_FILE_RESIZER= `${REDIRECT_URI}:8444/files/fileresizer/WEB_PICTURES/`
export const WEB_PICTURES_UPLOAD= `${REDIRECT_URI}:8444/files/upload/WEB_PICTURES`
export const PREVIEW_URL = process.env.NODE_ENV === 'production' ? 'https://hiiroad.com/' : "http://localhost:3030/" 
export const DOMAIN = 'https://hiiroad.com/';
export const SITE_DOMAIN = 'https://hiiroad.com/';
export const REGUEST_URI_PRIVATE = `${REDIRECT_URI}:8444/api/private/portal/wpa`
export const REGUEST_URI_PUBLIC = `${REDIRECT_URI}:8444/api/public/portal/wpa`