import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import ToLink from "./ToLink"


const menu = [
    {id:0, name:'Products', link:'/products', icon:'fa fa-shopping-cart fw', subMenu:[]},
    {id:10, name:'Products attributes', link:'/products-attributes', icon:'fa fa-shopping-cart fw', subMenu:[]},
    {id:1, name:'Catalogs', link:'', icon:'icon-cag', subMenu:[
        {id:0, name:'Catalogs and Groups', link:'/catalogs-and-groups', icon:'icon-cag', subMenu:[]},
        {id:1, name:'Marketplace catalogs', link:'/marketplace-catalogs', icon:'icon-mrcag', subMenu:[]},
    ]},
    // {id:2, name:'Products attributes', link:'/lists', icon:'fa fa-shopping-cart fw', subMenu:[]},
    {id:3, name:'Shops and Storages', link:'', icon:'icon-ls', subMenu:[
        {id:0, name:'Sales points and Storages', link:'/locations-and-storages', icon:'icon-cag', subMenu:[]},
        {id:1, name:'Inventory', link:'/in-stock', icon:'icon-is', subMenu:[]},
    ]},
    {id:4, name:'Orders', link:'/orders', icon:'icon-or', subMenu:[]},
    // {id:5, name:'Translate', link:'/translate', icon:'fa fa-globe fw', subMenu:[]},
    {id:6, name:'My WEB sites', link:'/configuration', icon:'icon-settings', subMenu:[]},
    {id:7, name:'Clients', link:'/сlients', icon:'icon-customer', subMenu:[]},
    // {id:8, name:'Notifications Manager', link:'/notifications-manager', icon:'icon-customer', subMenu:[]}, 
    {id:9, name:'Marketplace management', link:'', icon:'icon-ls', subMenu:[
        // {id:0, name:'Vendors products', link:'/vendors-products', icon:'icon-cag', subMenu:[]},
        // {id:1, name:'Products management ', link:'/products-management', icon:'icon-is', subMenu:[]},
        {id:1, name:'Vendors and products ', link:'/vendors-and-products', icon:'icon-is', subMenu:[]},
        {id:7, name:'Vendors banners', link:'/vendors-banners', icon:'icon-is', subMenu:[]},
        {id:2, name:'Catalogs and groups', link:'/catalogs-and-groups_marketplace', icon:'icon-is', subMenu:[]},
        // {id:2, name:'Catalogs', link:'/catalogs', icon:'icon-is', subMenu:[]},
        {id:3, name:'Loyalty management', link:'/loyalty-management', icon:'icon-is', subMenu:[]},
        {id:4, name:'Attributes and catalogs', link:'/lists', icon:'icon-is', subMenu:[]},
        {id:5, name:'Translate', link:'/translate', icon:'icon-is', subMenu:[]},
        {id:6, name:'Notifications Manager', link:'/notifications-manager', icon:'icon-customer', subMenu:[]}, 
        {id:6, name:'Rates management', link:'/rates-management', icon:'icon-customer', subMenu:[]}, 
        {id:6, name:'Marketplace payment settings ', link:'/payments-processing', icon:'icon-customer', subMenu:[]}, 
        {id:6, name:'Web site configuration ', link:'/web-site-configuration', icon:'icon-customer', subMenu:[]}, 
    ]},
]

const Navigation = ({
    isMarketplace
}) => {


    return (
        <>
            <div id="navigation" style={{display:'flex',     padding: '11px 15px'}}>
                <span className="fa fa-bars" style={{lineHeight: '19px', marginRight: 4}}></span> 
                Navigation
            </div>
            <ul id="menu" className="in" style={{display: 'flex', width: '100%', margin: 0}}>

                {
                    menu.map((props, k)=>{
                        const {id} = props
                        if([9].indexOf(id) !== -1 && isMarketplace === 0){
                           return true
                        }
                        return(<ToLink {...props} key={k} />)
                    })
                }
        
                 {/* <li id="menu-products">
                    <NavLink to="/products"><i className="fa fa-shopping-cart fw"></i> Products</NavLink>
                </li> */}

                {/* <li id="menu-extension" style={{minWidth: 140, position:'relative'}}>
                    <a href="#collapse11" data-toggle="collapse" className="parent collapsed"><i className="icon-cag"></i> Catalogs</a> 
                    <ul id="collapse11" className="collapse" style={{ position: 'absolute', top: 42, zIndex: 100, width: 'max-content'}}>
                        <li id="menu-translate">
                            <NavLink to="/catalogs-and-groups"><i className="icon-cag"></i> Catalogs and Groups</NavLink>
                        </li>
                        <li id="menu-translate">
                            <NavLink to="/marketplace-catalogs"><i className="icon-mrcag"></i> Marketplace catalogs</NavLink>
                        </li>
                    </ul>
                </li> */}

                
                
{/*                 
                <li id="menu-product-properties">
                    <NavLink to="/lists"><i className="fa fa-shopping-cart fw"></i> Products attributes  </NavLink>
                </li> */}

                {/* <li id="menu-extension" style={{minWidth: 210, position:'relative'}}>
                    <a href="#collapse14" data-toggle="collapse" className="parent collapsed"><i className="icon-ls"></i> Shops and Storages</a> 
                    <ul id="collapse14" className="collapse" style={{ position: 'absolute', top: 42, zIndex: 100, width: 'max-content'}}>
                        <li id="menu-translate">
                            <NavLink to="/locations-and-storages"><i className="icon-ls"></i> Sales points and Storages</NavLink>
                        </li>
                        <li id="menu-translate">
                            <NavLink to="/in-stock"><i className="icon-is"></i> Inventory</NavLink>
                        </li>
                    </ul>
                </li> */}


                {/* <li id="menu-translate">
                    <NavLink to="/orders"><i className="icon-or"></i> Orders</NavLink>
                </li>
                <li id="menu-translate">
                    <NavLink to="/translate"><i className="fa fa-globe fw"></i> Translate</NavLink>
                </li> */}
                {/* <li id="menu-translate">
                    <NavLink to="/payment-methods"><i className="icon-pay"></i> Payment methods</NavLink>
                </li> */}

                {/* <li id="menu-translate">
                    <NavLink to="/configuration"><i className="icon-settings"></i> My WEB sites</NavLink>
                </li>
                

                <li id="menu-translate">
                    <NavLink to="/сlients"><i className="icon-customer"></i> Clients</NavLink>
                </li>

                <li id="menu-translate">
                    <NavLink to="/notifications-manager"><i className="icon-customer"></i> Notifications Manager</NavLink>
                </li> */}
                

                
               
                {/* <li id="menu-seo">
                    <NavLink to="/seo"><i className="fa fa-bar-chart-o fw"></i> SEO</NavLink>
                </li> */}
                {/* <li id="menu-catalog" >
                    <a href="#collapse1" data-toggle="collapse" className="parent collapsed"><i className="fa fa-tags fw"></i> Catalog</a> 
                    <ul id="collapse1" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/category&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Categories</a> </li>
                        <li className="active"><a href="https://demo.opencart.com/admin/index.php?route=catalog/product&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Products</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/recurring&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Recurring Profiles</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/filter&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Filters</a> </li>
                        <li><a href="#collapse5" data-toggle="collapse" className="parent collapsed">Attributes</a> <ul id="collapse5" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/attribute&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Attributes</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/attribute_group&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Attribute Groups</a> </li>
                    </ul>
                </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/option&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Options</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/manufacturer&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Manufacturers</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/download&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Downloads</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/review&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Reviews</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=catalog/information&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Information</a> </li>
            </ul>
                </li>
                <li id="menu-extension">
                    <a href="#collapse14" data-toggle="collapse" className="parent collapsed"><i className="fa fa-puzzle-piece fw"></i> Extensions</a> 
                    <ul id="collapse14" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketplace/marketplace&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Marketplace</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketplace/installer&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Installer</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketplace/extension&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Extensions</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketplace/modification&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Modifications</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketplace/event&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Events</a> </li>
                    </ul>
                </li>
                <li id="menu-design">
                    <a href="#collapse20" data-toggle="collapse" className="parent collapsed"><i className="fa fa-television fw"></i> Design</a> 
                    <ul id="collapse20" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=design/layout&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Layouts</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=design/theme&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Theme Editor</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=design/translation&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Language Editor</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=design/banner&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Banners</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=design/seo_url&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">SEO URL</a> </li>
                    </ul>
                </li>
                <li id="menu-sale">
                    <a href="#collapse26" data-toggle="collapse" className="parent collapsed"><i className="fa fa-shopping-cart fw"></i> Sales</a> 
                    <ul id="collapse26" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=sale/order&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Orders</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=sale/recurring&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Recurring Profiles</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=sale/return&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Returns</a> </li>
                        <li><a href="#collapse29" data-toggle="collapse" className="parent collapsed">Gift Vouchers</a> <ul id="collapse29" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=sale/voucher&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Gift Vouchers</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=sale/voucher_theme&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Voucher Themes</a> </li>
                    </ul>
                </li>
                </ul>
                </li>
                <li id="menu-customer">
                    <a href="#collapse33" data-toggle="collapse" className="parent collapsed"><i className="fa fa-user fw"></i> Customers</a> 
                    <ul id="collapse33" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=customer/customer&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Customers</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=customer/customer_group&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Customer Groups</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=customer/customer_approval&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Customer Approvals</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=customer/custom_field&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Custom Fields</a> </li>
                    </ul>
                </li>
                <li id="menu-marketing">
                    <a href="#collapse38" data-toggle="collapse" className="parent collapsed"><i className="fa fa-share-alt fw"></i> Marketing</a> 
                    <ul id="collapse38" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketing/marketing&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Marketing</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketing/coupon&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Coupons</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=marketing/contact&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Mail</a> </li>
                    </ul>
                </li>
                <li id="menu-system">
                    <a href="#collapse42" data-toggle="collapse" className="parent collapsed"><i className="fa fa-cog fw"></i> System</a> 
                    <ul id="collapse42" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=setting/store&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Settings</a> </li>
                        <li><a href="#collapse43" data-toggle="collapse" className="parent collapsed">Localisation</a> <ul id="collapse43" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/location&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Store Location</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/language&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Languages</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/currency&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Currencies</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/stock_status&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Stock Statuses</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/order_status&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Order Statuses</a> </li>
                        <li><a href="#collapse48" data-toggle="collapse" className="parent collapsed">Returns</a> <ul id="collapse48" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/return_status&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Return Statuses</a></li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/return_action&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Return Actions</a></li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/return_reason&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Return Reasons</a></li>
                    </ul>
                </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/country&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Countries</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/zone&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Zones</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/geo_zone&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Geo Zones</a> </li>
                <li><a href="#collapse52" data-toggle="collapse" className="parent collapsed">Taxes</a> <ul id="collapse52" className="collapse">
                <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/tax_class&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Tax Classes</a></li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/tax_rate&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Tax Rates</a></li>
                </ul>
                </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/length_class&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Length Classes</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=localisation/weight_class&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Weight Classes</a> </li>
                </ul>
                </li>
                <li><a href="#collapse56" data-toggle="collapse" className="parent collapsed">Maintenance</a> <ul id="collapse56" className="collapse">
                <li><a href="https://demo.opencart.com/admin/index.php?route=tool/backup&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Backup / Restore</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=tool/upload&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Uploads</a> </li>
                <li><a href="https://demo.opencart.com/admin/index.php?route=tool/log&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Error Logs</a> </li>
                </ul>
                </li>
                </ul>
                </li>
                <li id="menu-report">
                    <a href="#collapse61" data-toggle="collapse" className="parent collapsed"><i className="fa fa-bar-chart-o fw"></i> Reports</a> 
                    <ul id="collapse61" className="collapse">
                        <li><a href="https://demo.opencart.com/admin/index.php?route=report/report&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Reports</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=report/online&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Who's Online</a> </li>
                        <li><a href="https://demo.opencart.com/admin/index.php?route=report/statistics&amp;user_token=DbsVZXpvVqa0VYPsCJJUzazAfozlUUKp">Statistics</a> </li>
                    </ul>
                </li> */}
            </ul>

            {/* <div id="stats">
                <ul>
                    <li>
                        <div>Orders Completed <span className="pull-right">0%</span></div>
                        <div className="progress">
                            <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:"0%"}}> <span className="sr-only">0%</span></div>
                        </div>
                    </li>
                    <li>
                        <div>Orders Processing <span className="pull-right">0%</span></div>
                        <div className="progress">
                            <div className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:"0%"}}> <span className="sr-only">0%</span></div>
                        </div>
                    </li>
                    <li>
                        <div>Other Statuses <span className="pull-right">0%</span></div>
                        <div className="progress">
                            <div className="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:"0%"}}> <span className="sr-only">0%</span></div>
                        </div>
                    </li>
                </ul>
            </div> */}
         </>
    ) 
}
const mapStateToProps = (state) => ({
    isMarketplace:state.auth.isMarketplace

})
const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);