import { useEffect, useState } from "react"
import Popup from "../../components/Popup/Popup"
import { PayPalButton } from "react-paypal-button-v2";
import { connect } from "react-redux";
// import { userPrivateActions, userPrivateOperations } from "../../redux/userPrivate";
// import {DOMAIN} from './../../config/Url'
import { useHistory } from "react-router-dom";
import { modelOperations } from "../../redux/model";

// const CLIENT_ID = 'AT3UZX4bt49tIMRK-gKxRwlX7JGFVWgJoaXphxB2FlctR1U50LaGCGHL9BQPn7flhxl7Icxx8y8KaE59'

const PopupPayPal = ({
    lang,
    closePopup,
    total,
    currency,
    orderId,
    onUserTransactionPaypal,
    userTransactionPaypal,
    onGetUserPaypalClientId,
    // getUserPaypalClientId,
    payment,
    setErrorCode,

    onGetPaypalClientId,
    getPaypalClientId,
    onTransactionBuyCoinsPaypal,
    transactionBuyCoinsPaypal
}) => {

    /*
    Card Type: Visa

    Card Number: 4032030600165808

    Expiration Date: 09/2024

    CVV: 852
    */

    const [rel, setRel] = useState(0);


    const [uniqueId] = useState(Date.now().toString(36) + Math.random().toString(36).substring(2))

    const history = useHistory();
    useEffect(()=>{
        setRel(0)
        // onGetUserPaypalClientId({DOMAIN})

        onGetPaypalClientId({DEFAULT_COLUMN:lang})
    }, [])

    useEffect(()=>{
        console.log({getPaypalClientId});
    }, [getPaypalClientId])


    useEffect(()=>{
        if(transactionBuyCoinsPaypal === 1){
            // history.push('/thanks') 
            // history.push({
            //     pathname: '/thanks',
            //     search: `?record=${payment?.ID_RECORD}`,
            // });

            closePopup()
        }else if(transactionBuyCoinsPaypal === -1){
            setRel(-1)
        }
    }, [transactionBuyCoinsPaypal])


    return (
        <Popup closePopup={()=>{closePopup()}}>
            <div className="mx-3 my-2">
                {rel === -1 && 
                    <h2 className="text-center my-5" style={{color:'red'}} >Something went wrong. We`ll take you back to checkout so you can try again.</h2>
                }
                {rel === 1 && 
                    <h2 className="text-center my-5" style={{color:'red'}} >Declined</h2>
                }

                {rel === 0 && getPaypalClientId === '' && 
                    <h2 className="text-center my-5"  >Loading...</h2>
                }
                {rel === 0 && getPaypalClientId !== '' && 
                <PayPalButton

                    createOrder={(data, actions) => {
                        return actions.order.create({
                        purchase_units: [{
                            reference_id:uniqueId,
                            amount: {
                                currency_code: currency,
                                value: total
                            }
                        }],

                        });
                    }}
    
                    onSuccess={(details, data) => {

                        console.log({details, data});
                    // alert("Transaction completed by " + details.payer.name.given_name);

                    // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //     method: "post",
                    //     body: JSON.stringify({
                    //     orderId: data.orderID
                    //     })
                    // });

                        // onUserTransactionPaypal({DOMAIN, PAYPAL_ORDER_ID:data.orderID})
                    
                        onTransactionBuyCoinsPaypal({
                            DEFAULT_COLUMN:lang,
                            RATE_SHORT_NAME:currency,
                            PRICE:total,
                            PAYPAL_ORDER_ID:data.orderID
                        })
                    }}
                    options={{
                        clientId: getPaypalClientId,
                        currency
                    }}
                />
                }
                
 
            </div>
        </Popup>
    ) 
}

const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    getPaypalClientId:state.model.getPaypalClientId,
    transactionBuyCoinsPaypal:state.model.transactionBuyCoinsPaypal,
    // errCode:state.userPrivate.err_code,
    // orderId:state.userPrivate.orderId,
    // userTransactionPaypal:state.userPrivate.userTransactionPaypal,
    // getUserPaypalClientId:state.userPrivate.getUserPaypalClientId,
})

const mapDispatchToProps = {
    // onUserTransactionPaypal:userPrivateOperations.userTransactionPaypal,
    // onGetUserPaypalClientId:userPrivateOperations.getUserPaypalClientId,
    onGetPaypalClientId:modelOperations.getPaypalClientId,
    onTransactionBuyCoinsPaypal:modelOperations.transactionBuyCoinsPaypal,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupPayPal);
