import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { authOperations, authSelectors } from '../../redux/auth';
import { langActions } from '../../redux/lang';
import { modelOperations } from '../../redux/model';
import logo from './../../assets/logo/logo_shop2.png'
import jsonLang from './../../json/lang.json'

import picture from './../../assets/svg/picture.svg'
import PopupVendorCard from './PopupVendorCard';
import {WEB_PICTURES_FILE, PREVIEW_URL, WEB_PICTURES_FILE_RESIZER} from './../../config/Url'

const Header = ({
    lang,
    isAuthenticated, 
    onLogout,
    onGetVendorInfo,
    getVendorInfo,
    errCode
}) =>{
    const history = useHistory()

    // const [popupEditKey, setPopupEditKey] = useState(false);
    // const [popupReload, setPopupReload] = useState(false);
    const [popupVendorCard, setPopupVendorCard] = useState(false);
    // const [key, setKey] = useState('')
    // const [use, setUse] = useState(0)

    useEffect(()=>{
        console.log({popupVendorCard});
    }, [popupVendorCard])

    useEffect(()=>{
        // onGetUserBlockchainAddress({DEFAULT_COLUMN:lang})
        onGetVendorInfo({DEFAULT_COLUMN:lang})
    }, [])

    useEffect(()=>{
        if(errCode === 1){
            // onGetUserBlockchainAddress({DEFAULT_COLUMN:lang})
            onGetVendorInfo({DEFAULT_COLUMN:lang})
        }
    }, [errCode])

    // useEffect(()=>{
    //     // console.log('MetaMask is installed!', window.ethereum);
    //     if(!isAuthenticated) return false;
        
    //     if (typeof window.ethereum !== 'undefined') {
    //         console.log('MetaMask is installed!');
    //     }

    //     onGetUserBlockchainAddress({DEFAULT_COLUMN:lang})
    //     onGetBalanceInfo({DEFAULT_COLUMN:lang})
    // }, [isAuthenticated])
    // useEffect(()=>{
    //     if(errCode === 1){
    //          onGetUserBlockchainAddress({DEFAULT_COLUMN:lang})
    //          onGetBalanceInfo({DEFAULT_COLUMN:lang})
    //     }
    // }, [errCode])

    // useEffect(()=>{
    //     console.log(getUserBlockchainAddress);

    //     if(Object.keys(getUserBlockchainAddress).length !== 0){
    //         const {USED_ADDRESS_TYPE, INTERNAL_ADDRESS, EXTERNAL_ADDRESS} = getUserBlockchainAddress
    //         setKey(USED_ADDRESS_TYPE === 0 ? INTERNAL_ADDRESS : EXTERNAL_ADDRESS)
    //         setUse(USED_ADDRESS_TYPE)
    //     }

    // }, [getUserBlockchainAddress])

    const logout = () =>{
        onLogout()
        history.push('login')
    }
    
    // const [sel, setSel] = useState(lang)

    // return (
    //     <>
    //     <header id="header" className="navbar navbar-static-top">
    //             <div className="container-fluid">
    //                 <div id="header-logo" className="navbar-header">
    //                     <NavLink to="/" className="navbar-brand" style={{width: '100%', marginLeft: 0,textAlign: 'center'}}><img src={logo} style={{width: 128, margin: '0px auto', position: 'relative', top: -7}} /></NavLink>
    //                 </div>
                    
    //                 {isAuthenticated && 
    //                     <>
    //                         <a href="#" id="button-menu" className="hidden-md hidden-lg"><span className="fa fa-bars"></span></a>
    //                         <ul className="nav navbar-nav navbar-right">
    //                             <li className="dropdown"><a href="#" className="dropdown-toggle" data-toggle="dropdown"><img src="https://demo.opencart.com/image/cache/profile-45x45.png" alt="demo demo" title="demo" id="user-profile" className="img-circle" />{fullName}<i className="fa fa-caret-down fa-fw"></i></a>
    //                                 <ul className="dropdown-menu dropdown-menu-right">
    //                                     <li><a href="#"><i className="fa fa-user-circle-o fa-fw"></i> Your Profile</a></li>
    //                                     <li role="separator" className="divider"></li>
    //                                     <li className="dropdown-header">Stores</li>
    //                                     <li><a href="#" target="_blank">Your Store</a></li>
    //                                     <li role="separator" className="divider"></li>
    //                                     {/* <li className="dropdown-header">Help</li> */}
    //                                     {/* <li><a href="http://www.opencart.com" target="_blank"><i className="fa fa-opencart fa-fw"></i> OpenCart Homepage</a></li>
    //                                     <li><a href="http://docs.opencart.com" target="_blank"><i className="fa fa-file-text-o fa-fw"></i> Documentation</a></li>
    //                                     <li><a href="http://forum.opencart.com" target="_blank"><i className="fa fa-comments-o fa-fw"></i> Support Forum</a></li> */}
    //                                 </ul>
    //                             </li>
    //                             <li><a onClick={logout}><i className="fa fa-sign-out"></i> <span className="hidden-xs hidden-sm hidden-md">Logout</span></a></li>
    //                         </ul>
    //                     </>
    //                 }
    //                 <div className="nav navbar-nav navbar-right" style={{ margin: 12, alignItems: 'center'}}>
    //                     {isAuthenticated && <div style={{marginRight:12}}>
    //                         {/* <div><i className="fa fa-pencil" onClick={()=>setPopupEditKey(true)} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} /> Public key: {balance?.PUBLICK_ADDRESS}</div> */}
    //                         <div><i className="fa fa-pencil" onClick={()=>setPopupEditKey(true)} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} /> Public key: {key} ({use === 0 ? 'internal' : 'external'})</div>
    //                         <div><b className="" onClick={()=>setPopupReload(true)} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} >&#8635;</b> Hiiroad coins balance: {getBalanceInfo?.BALANCE / getBalanceInfo?.BALANCE_DECIMALS} </div>
    //                     </div>}
    //                     <select style={{width: 'auto'}} className="form-control" value={lang} onChange={({target:{value}}) => updateLang(value)}>
    //                         {jsonLang.map(({name, type, id})=>(<option key={id}  value={type}>{name}</option>))}
    //                     </select>
    //                 </div>
    //             </div>
    //         </header>
    //         {popupEditKey && <PopupEditKey closePopup={()=>setPopupEditKey(false)} />}
    //         {popupReload && <PopupReload closePopup={()=>setPopupReload(false)} />}
    //         </>
    // )

    return (
        <>
        <header id="header" className="navbar navbar-static-top">
                <div className="container-fluid">
                    <div id="header-logo" className="navbar-header">
                        <NavLink to="/" className="navbar-brand" style={{width: '100%', marginLeft: 0,textAlign: 'center'}}><img src={logo} style={{width: 128, margin: '0px auto', position: 'relative', top: -7}} /></NavLink>
                    </div>
                    
                    {/* {isAuthenticated && 
                        <>
                            <a href="#" id="button-menu" className="hidden-md hidden-lg"><span className="fa fa-bars"></span></a>
                            <ul className="nav navbar-nav navbar-right">
                                <li className="dropdown"><a href="#" className="dropdown-toggle" data-toggle="dropdown"><img src="https://demo.opencart.com/image/cache/profile-45x45.png" alt="demo demo" title="demo" id="user-profile" className="img-circle" />{fullName}<i className="fa fa-caret-down fa-fw"></i></a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#"><i className="fa fa-user-circle-o fa-fw"></i> Your Profile</a></li>
                                        <li role="separator" className="divider"></li>
                                        <li className="dropdown-header">Stores</li>
                                        <li><a href="#" target="_blank">Your Store</a></li>
                                        <li role="separator" className="divider"></li>
                                       
                                    </ul>
                                </li>
                                <li><a onClick={logout}><i className="fa fa-sign-out"></i> <span className="hidden-xs hidden-sm hidden-md">Logout</span></a></li>
                            </ul>
                        </>
                    } */}
                    {/* <div className="nav navbar-nav navbar-right" style={{ margin: 12, alignItems: 'center'}}>
                        {isAuthenticated && <div style={{marginRight:12}}>
                           
                            <div><i className="fa fa-pencil" onClick={()=>setPopupEditKey(true)} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} /> Public key: {key} ({use === 0 ? 'internal' : 'external'})</div>
                            <div><b className="" onClick={()=>setPopupReload(true)} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} >&#8635;</b> Hiiroad coins balance: {getBalanceInfo?.BALANCE / getBalanceInfo?.BALANCE_DECIMALS} </div>
                        </div>}
                        <select style={{width: 'auto'}} className="form-control" value={lang} onChange={({target:{value}}) => updateLang(value)}>
                            {jsonLang.map(({name, type, id})=>(<option key={id}  value={type}>{name}</option>))}
                        </select>
                    </div> */}
                    {isAuthenticated && 
                    <ul className="nav navbar-nav navbar-right">
                        <li className="dropdown">
                            <a href="#" onClick={()=>setPopupVendorCard(true)} style={{display: 'flex', alignItems: 'center'}}>
                                
                                {getVendorInfo?.PROFILE_IMAGE !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${getVendorInfo?.PROFILE_IMAGE}/240`} style={{width: 45, height: 45, objectFit: 'contain', marginRight:12}} /> }
                                {getVendorInfo?.PROFILE_IMAGE === '' && <img src={picture} alt="demo demo" title="demo" id="user-profile" className="img-circle" style={{borderRadius:0, marginRight:12}} width="35px" height="45px" /> }
                                {getVendorInfo?.PROFILE_NAME}

                                {
                                    getVendorInfo?.BALANCES?.filter(({TYPE_INDEX})=>[2, 3].indexOf(TYPE_INDEX) > -1).map(({TYPE_INDEX, BALANCE}, k)=>{

                                        const balance = Number(BALANCE.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === getVendorInfo?.CURRENCY).map(({PRICE})=>PRICE).join(''))
                                        const balanceToken = Number(BALANCE.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>PRICE).join(''))

                                        return(<div key={k} style={{marginLeft:20}}>
                                            <div style={{lineHeight: "30px"}}>
                                                {/* Reward balance: */}
                                                {TYPE_INDEX === 2 && 'Reward balance:'}
                                                {TYPE_INDEX === 3 && 'Payout balance:'}
                                            </div>
                                            <div style={{lineHeight: "8px", textAlign: 'center',display: 'flex', alignItems: 'center'}}> <i className='hiiroad'></i> <b style={{marginRight:6}}>{balanceToken}</b>({ balance } {getVendorInfo?.CURRENCY})</div>
                                           
                                        </div>)   
                                    })
                                }
                                {/* <div style={{marginLeft:20}}>
                                    <div style={{lineHeight: "30px"}}>Reward balance:</div>
                                    <div style={{lineHeight: "8px", textAlign: 'center'}}>{ getVendorInfo?.BALANCE && Number((getVendorInfo?.BALANCE).toFixed(2)) } {getVendorInfo?.CURRENCY}</div>
                                    <div style={{lineHeight: "30px"}}>({ getVendorInfo?.COINS_QTY && Number((getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS ).toFixed(2)) }  Hiiroad coins)</div>
                                </div>
                                <div style={{marginLeft:20}}>
                                    <div style={{lineHeight: "30px"}}>Payout balance:</div>
                                    <div style={{lineHeight: "8px", textAlign: 'center'}}>{ getVendorInfo?.BALANCE && Number((getVendorInfo?.BALANCE).toFixed(2)) } {getVendorInfo?.CURRENCY}</div>
                                    <div style={{lineHeight: "30px"}}>({ getVendorInfo?.COINS_QTY && Number((getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS ).toFixed(2)) }  Hiiroad coins)</div>
                                </div> */}
                            </a>
                            
                        </li>
                        <li>
                            <a>
                                {/* <div style={{lineHeight: "31px", position: 'relative', top: '5px'}}>Maxim Kotov (admin)</div> */}
                                <div  onClick={()=>logout()} > <i className="fa fa-sign-out"></i> <span className="hidden-xs hidden-sm hidden-md">Logout</span></div>
                            </a>
                        </li>
                    </ul>}
                </div>
            </header>

            {popupVendorCard && <PopupVendorCard closePopup={()=>setPopupVendorCard(false)} />}
            </>
    )
}

const mapStateToPorps = (state) => ({
    isAuthenticated:authSelectors.getIsAuthenticated(state),
    fullName:authSelectors.getAdminName(state),
    lang:state.lang.LANG,
    balance:state.auth.balance,
    errCode:state.model.err_code,
    // getUserBlockchainAddress:state.model.getUserBlockchainAddress,
    // getBalanceInfo:state.model.getBalanceInfo,
    getVendorInfo:state.model.getVendorInfo,

})

const mapDispatchToProps = {
    onLogout:authOperations.logout,
    updateLang:langActions.updateLang,
    // onUpdateUserBlockchainAddress:modelOperations.updateUserBlockchainAddress,
    // onGetUserBlockchainAddress:modelOperations.getUserBlockchainAddress,
    // onGetBalanceInfo:modelOperations.getBalanceInfo,
    onGetVendorInfo:modelOperations.getVendorInfo,
}

export default connect(mapStateToPorps, mapDispatchToProps)(Header);