import { useEffect } from "react"


export default () =>{

    useEffect(()=>{
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [])

    return (
        <div className="viewLoader">
            <div>
                <div className="dot-loader"></div>
                <div className="dot-loader"></div>
                <div className="dot-loader"></div>
            </div>
        </div>
    )
}