import React from 'react';
import { Route } from 'react-router-dom';

// export default ({children}) => {
//     return(
//     <><p>app</p>{children}</>
//     )
// }

const AppLayout = ({ children }) => (                       
    <>
      {children}                                     
    </>
  );  
  
export default ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )} />
    )
  };