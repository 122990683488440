import {createAction} from '@reduxjs/toolkit';


// const getItemsListRequest = createAction('model/getItemsListRequest')
const loader = createAction('model/loader')

const getAppPagesListRequest = createAction('model/getAppPagesListRequest')
const getAppPagesListSuccess = createAction('model/getAppPagesListSuccess')
const getAppPagesListError = createAction('model/getAppPagesListError')


const getSaitListRequest = createAction('model/getSaitListRequest')
const getSaitListSuccess = createAction('model/getSaitListSuccess')
const getSaitListError = createAction('model/getSaitListError')

const getTranslatorRequest = createAction('model/getTranslatorRequest')
const getTranslatorSuccess = createAction('model/getTranslatorSuccess')
const getTranslatorError = createAction('model/getTranslatorError')

const updatePageRequest = createAction('model/updatePageRequest')
const updatePageSuccess = createAction('model/updatePageSuccess')
const updatePageError = createAction('model/updatePageError')

const updateTranslatorRequest = createAction('model/updateTranslatorRequest')
const updateTranslatorSuccess = createAction('model/updateTranslatorSuccess')
const updateTranslatorError = createAction('model/updateTranslatorError')

const removeTranslatorRequest = createAction('model/removeTranslatorRequest')
const removeTranslatorSuccess = createAction('model/removeTranslatorSuccess')
const removeTranslatorError = createAction('model/removeTranslatorError')

const searchGetRequestsRequest = createAction('model/searchGetRequestsRequest')
const searchGetRequestsSuccess = createAction('model/searchGetRequestsSuccess')
const searchGetRequestsError = createAction('model/searchGetRequestsError')

const searchGetLinkRequest = createAction('model/searchGetLinkRequest')
const searchGetLinkSuccess = createAction('model/searchGetLinkSuccess')
const searchGetLinkError = createAction('model/searchGetLinkError')

const getWidgetsSchemeRequest = createAction('model/getWidgetsSchemeRequest')
const getWidgetsSchemeSuccess = createAction('model/getWidgetsSchemeSuccess')
const getWidgetsSchemeError = createAction('model/getWidgetsSchemeError')

const createUpdateWidgetSchemeRequest = createAction('model/createUpdateWidgetSchemeRequest')
const createUpdateWidgetSchemeSuccess = createAction('model/createUpdateWidgetSchemeSuccess')
const createUpdateWidgetSchemeError = createAction('model/createUpdateWidgetSchemeError')

const getWidgetSchemeByIdRequest = createAction('model/getWidgetSchemeByIdRequest')
const getWidgetSchemeByIdSuccess = createAction('model/getWidgetSchemeByIdSuccess')
const getWidgetSchemeByIdError = createAction('model/getWidgetSchemeByIdError')

const getWidgetSchemeWithWidgetsRequest = createAction('model/getWidgetSchemeWithWidgetsRequest')
const getWidgetSchemeWithWidgetsSuccess = createAction('model/getWidgetSchemeWithWidgetsSuccess')
const getWidgetSchemeWithWidgetsError = createAction('model/getWidgetSchemeWithWidgetsError')

const getKeywordLinkRequest = createAction('model/getKeywordLinkRequest')
const getKeywordLinkSuccess = createAction('model/getKeywordLinkSuccess')
const getKeywordLinkError = createAction('model/getKeywordLinkError')


const createUpdateKeywordRequest = createAction('model/createUpdateKeywordRequest')
const createUpdateKeywordSuccess = createAction('model/createUpdateKeywordSuccess')
const createUpdateKeywordError = createAction('model/createUpdateKeywordError')

const getKeywordsRequest = createAction('model/getKeywordsRequest')
const getKeywordsSuccess = createAction('model/getKeywordsSuccess')
const getKeywordsError = createAction('model/getKeywordsError')

const getKeywordRequest = createAction('model/getKeywordRequest')
const getKeywordSuccess = createAction('model/getKeywordSuccess')
const getKeywordError = createAction('model/getKeywordError')

const createUpdateKeyRequest = createAction('model/createUpdateKeyRequest')
const createUpdateKeySuccess = createAction('model/createUpdateKeySuccess')
const createUpdateKeyError = createAction('model/createUpdateKeyError')

const removeKeywordRequest = createAction('model/removeKeywordRequest')
const removeKeywordSuccess = createAction('model/removeKeywordSuccess')
const removeKeywordError = createAction('model/removeKeywordError')

const getWidgetListRequest = createAction('model/getWidgetListRequest')
const getWidgetListSuccess = createAction('model/getWidgetListSuccess')
const getWidgetListError = createAction('model/getWidgetListError')

const getItemsListWidgetRequest = createAction('model/getItemsListWidgetRequest')
const getItemsListWidgetSuccess = createAction('model/getItemsListWidgetSuccess')
const getItemsListWidgetError = createAction('model/getItemsListWidgetError')

const updateItemStatusRequest = createAction('model/updateItemStatusRequest')
const updateItemStatusSuccess = createAction('model/updateItemStatusSuccess')
const updateItemStatusError = createAction('model/updateItemStatusError')

const getItemByIdRequest = createAction('model/getItemByIdRequest')
const getItemByIdSuccess = createAction('model/getItemByIdSuccess')
const getItemByIdError = createAction('model/getItemByIdError')

const createUpdateItemRequest = createAction('model/createUpdateItemRequest')
const createUpdateItemSuccess = createAction('model/createUpdateItemSuccess')
const createUpdateItemError = createAction('model/createUpdateItemError')

const getGallariesListWidgetQtyRequest = createAction('model/getGallariesListWidgetQtyRequest')
const getGallariesListWidgetQtySuccess = createAction('model/getGallariesListWidgetQtySuccess')
const getGallariesListWidgetQtyError = createAction('model/getGallariesListWidgetQtyError')

const getGallaryByIDRequest = createAction('model/getGallaryByIDRequest')
const getGallaryByIDSuccess = createAction('model/getGallaryByIDSuccess')
const getGallaryByIDError = createAction('model/getGallaryByIDError')

const createUpdateGallaryRequest = createAction('model/createUpdateGallaryRequest')
const createUpdateGallarySuccess = createAction('model/createUpdateGallarySuccess')
const createUpdateGallaryError = createAction('model/createUpdateGallaryError')

const updateWidgetActiveRequest = createAction('model/updateWidgetActiveRequest')
const updateWidgetActiveSuccess = createAction('model/updateWidgetActiveSuccess')
const updateWidgetActiveError = createAction('model/updateWidgetActiveError')

const getWidgetByIdRequest = createAction('model/getWidgetByIdRequest')
const getWidgetByIdSuccess = createAction('model/getWidgetByIdSuccess')
const getWidgetByIdError = createAction('model/getWidgetByIdError')

const createUpadetWidgetRequest = createAction('model/createUpadetWidgetRequest')
const createUpadetWidgetSuccess = createAction('model/createUpadetWidgetSuccess')
const createUpadetWidgetError = createAction('model/createUpadetWidgetError')


const getItemGroupListRequest = createAction('model/getItemGroupListRequest')
const getItemGroupListSuccess = createAction('model/getItemGroupListSuccess')
const getItemGroupListError = createAction('model/getItemGroupListError')

const createItemGroupRequest = createAction('model/createItemGroupRequest')
const createItemGroupSuccess = createAction('model/createItemGroupSuccess')
const createItemGroupError = createAction('model/createItemGroupError')

const remoevItemGroupRequest = createAction('model/remoevItemGroupRequest')
const remoevItemGroupSuccess = createAction('model/remoevItemGroupSuccess')
const remoevItemGroupError = createAction('model/remoevItemGroupError')

const getItemListSortGroupRequest = createAction('model/getItemListSortGroupRequest')
const getItemListSortGroupSuccess = createAction('model/getItemListSortGroupSuccess')
const getItemListSortGroupError = createAction('model/getItemListSortGroupError')

const linkUnlinkItemGroupRequest = createAction('model/linkUnlinkItemGroupRequest')
const linkUnlinkItemGroupSuccess = createAction('model/linkUnlinkItemGroupSuccess')
const linkUnlinkItemGroupError = createAction('model/linkUnlinkItemGroupError')

const removeWidgetRequest = createAction('model/removeWidgetRequest')
const removeWidgetSuccess = createAction('model/removeWidgetSuccess')
const removeWidgetError = createAction('model/removeWidgetError')

const getWidgetConvertationListRequest = createAction('model/getWidgetConvertationListRequest')
const getWidgetConvertationListSuccess = createAction('model/getWidgetConvertationListSuccess')
const getWidgetConvertationListError = createAction('model/getWidgetConvertationListError')

const GetItemGroupTypesRequest = createAction('model/GetItemGroupTypesRequest')
const GetItemGroupTypesSuccess = createAction('model/GetItemGroupTypesSuccess')
const GetItemGroupTypesError = createAction('model/GetItemGroupTypesError')

const createUpdateItemGroupRequest = createAction('model/createUpdateItemGroupRequest')
const createUpdateItemGroupSuccess = createAction('model/createUpdateItemGroupSuccess')
const createUpdateItemGroupError = createAction('model/createUpdateItemGroupError')

const getWidgetRecordListRequest = createAction('model/getWidgetRecordListRequest')
const getWidgetRecordListSuccess = createAction('model/getWidgetRecordListSuccess')
const getWidgetRecordListError = createAction('model/getWidgetRecordListError')

const getWidgetRecordByIdRequest = createAction('model/getWidgetRecordByIdRequest')
const getWidgetRecordByIdSuccess = createAction('model/getWidgetRecordByIdSuccess')
const getWidgetRecordByIdError = createAction('model/getWidgetRecordByIdError')

const createUpdateWidgetRecordRequest = createAction('model/createUpdateWidgetRecordRequest')
const createUpdateWidgetRecordSuccess = createAction('model/createUpdateWidgetRecordSuccess')
const createUpdateWidgetRecordError = createAction('model/createUpdateWidgetRecordError')

const changeLevelItemInGroupRequest = createAction('model/changeLevelItemInGroupRequest')
const changeLevelItemInGroupSuccess = createAction('model/changeLevelItemInGroupSuccess')
const changeLevelItemInGroupError = createAction('model/changeLevelItemInGroupError')

const getItemGroupByIdRequest = createAction('model/getItemGroupByIdRequest')
const getItemGroupByIdSuccess = createAction('model/getItemGroupByIdSuccess')
const getItemGroupByIdError = createAction('model/getItemGroupByIdError')

const getWidgetGroupTypeRequest = createAction('model/getWidgetGroupTypeRequest')
const getWidgetGroupTypeSuccess = createAction('model/getWidgetGroupTypeSuccess')
const getWidgetGroupTypeError = createAction('model/getWidgetGroupTypeError')

const getWidgetsNoSchemaWithTypeRequest = createAction('model/getWidgetsNoSchemaWithTypeRequest')
const getWidgetsNoSchemaWithTypeSuccess = createAction('model/getWidgetsNoSchemaWithTypeSuccess')
const getWidgetsNoSchemaWithTypeError = createAction('model/getWidgetsNoSchemaWithTypeError')

const linkWidgetToWidgetSchemeRequest = createAction('model/linkWidgetToWidgetSchemeRequest')
const linkWidgetToWidgetSchemeSuccess = createAction('model/linkWidgetToWidgetSchemeSuccess')
const linkWidgetToWidgetSchemeError = createAction('model/linkWidgetToWidgetSchemeError')

const getLinkedWidgetsRequest = createAction('model/getLinkedWidgetsRequest')
const getLinkedWidgetsSuccess = createAction('model/getLinkedWidgetsSuccess')
const getLinkedWidgetsError = createAction('model/getLinkedWidgetsError')

const updateWidgetLevelInWidgetSchemeRequest = createAction('model/updateWidgetLevelInWidgetSchemeRequest')
const updateWidgetLevelInWidgetSchemeSuccess = createAction('model/updateWidgetLevelInWidgetSchemeSuccess')
const updateWidgetLevelInWidgetSchemeError = createAction('model/updateWidgetLevelInWidgetSchemeError')


const linkProductToWidgetSchemeRequest = createAction('model/linkProductToWidgetSchemeRequest')
const linkProductToWidgetSchemeSuccess = createAction('model/linkProductToWidgetSchemeSuccess')
const linkProductToWidgetSchemeError = createAction('model/linkProductToWidgetSchemeError')


const getSchemeByProductRequest = createAction('model/getSchemeByProductRequest')
const getSchemeByProductSuccess = createAction('model/getSchemeByProductSuccess')
const getSchemeByProductError = createAction('model/getSchemeByProductError')


const updateItemPropertiesRequest = createAction('model/updateItemPropertiesRequest')
const updateItemPropertiesSuccess = createAction('model/updateItemPropertiesSuccess')
const updateItemPropertiesError = createAction('model/updateItemPropertiesError')


const getSchemeByItemListRequest = createAction('model/getSchemeByItemListRequest')
const getSchemeByItemListSuccess = createAction('model/getSchemeByItemListSuccess')
const getSchemeByItemListError = createAction('model/getSchemeByItemListError')

const getItemWidgetsPricingRequest = createAction('model/getItemWidgetsPricingRequest')
const getItemWidgetsPricingSuccess = createAction('model/getItemWidgetsPricingSuccess')
const getItemWidgetsPricingError = createAction('model/getItemWidgetsPricingError')

const updateItemPricingWidgetRequest = createAction('model/updateItemPricingWidgetRequest')
const updateItemPricingWidgetSuccess = createAction('model/updateItemPricingWidgetSuccess')
const updateItemPricingWidgetError = createAction('model/updateItemPricingWidgetError')

const getItemsPricingRequest = createAction('model/getItemsPricingRequest')
const getItemsPricingSuccess = createAction('model/getItemsPricingSuccess')
const getItemsPricingError = createAction('model/getItemsPricingError')

const updateItemPricingRequest = createAction('model/updateItemPricingRequest')
const updateItemPricingSuccess = createAction('model/updateItemPricingSuccess')
const updateItemPricingError = createAction('model/updateItemPricingError')

const removeWidgetRecordRequest = createAction('model/removeWidgetRecordRequest')
const removeWidgetRecordSuccess = createAction('model/removeWidgetRecordSuccess')
const removeWidgetRecordError = createAction('model/removeWidgetRecordError')

const updateItemPackingWidgetRequest = createAction('model/updateItemPackingWidgetRequest')
const updateItemPackingWidgetSuccess = createAction('model/updateItemPackingWidgetSuccess')
const updateItemPackingWidgetError = createAction('model/updateItemPackingWidgetError')

const updateItemConvertationWidgetRequest = createAction('model/updateItemConvertationWidgetRequest')
const updateItemConvertationWidgetSuccess = createAction('model/updateItemConvertationWidgetSuccess')
const updateItemConvertationWidgetError = createAction('model/updateItemConvertationWidgetError')

const enableDisablePackagesPricingRequest = createAction('model/enableDisablePackagesPricingRequest')
const enableDisablePackagesPricingSuccess = createAction('model/enableDisablePackagesPricingSuccess')
const enableDisablePackagesPricingError = createAction('model/enableDisablePackagesPricingError')

const convertPriceRequest = createAction('model/convertPriceRequest')
const convertPriceSuccess = createAction('model/convertPriceSuccess')
const convertPriceError = createAction('model/convertPriceError')

const updateItemPackingPricingRequest = createAction('model/updateItemPackingPricingRequest')
const updateItemPackingPricingSuccess = createAction('model/updateItemPackingPricingSuccess')
const updateItemPackingPricingError = createAction('model/updateItemPackingPricingError')
const updateItemPackingPricingClear = createAction('model/updateItemPackingPricingError')

const updateItemTypePricingRequest = createAction('model/updateItemTypePricingRequest')
const updateItemTypePricingSuccess = createAction('model/updateItemTypePricingSuccess')
const updateItemTypePricingError = createAction('model/updateItemTypePricingError')

const updateItemStartPricingRequest = createAction('model/updateItemStartPricingRequest')
const updateItemStartPricingSuccess = createAction('model/updateItemStartPricingSuccess')
const updateItemStartPricingError = createAction('model/updateItemStartPricingError')

const getWidgetTypesRequest = createAction('model/getWidgetTypesRequest')
const getWidgetTypesSuccess = createAction('model/getWidgetTypesSuccess')
const getWidgetTypesError = createAction('model/getWidgetTypesError')

const getPropertiesByProductRequest = createAction('model/getPropertiesByProductRequest')
const getPropertiesByProductSuccess = createAction('model/getPropertiesByProductSuccess')
const getPropertiesByProductError = createAction('model/getPropertiesByProductError')

const getWidgetsNoPropertiesProductRequest = createAction('model/getWidgetsNoPropertiesProductRequest')
const getWidgetsNoPropertiesProductSuccess = createAction('model/getWidgetsNoPropertiesProductSuccess')
const getWidgetsNoPropertiesProductError = createAction('model/getWidgetsNoPropertiesProductError')

const addPropertiesWidgetByProductRequest = createAction('model/addPropertiesWidgetByProductRequest')
const addPropertiesWidgetByProductSuccess = createAction('model/addPropertiesWidgetByProductSuccess')
const addPropertiesWidgetByProductError = createAction('model/addPropertiesWidgetByProductError')

const removePropertiesWidgetByProductRequest = createAction('model/removePropertiesWidgetByProductRequest')
const removePropertiesWidgetByProductSuccess = createAction('model/removePropertiesWidgetByProductSuccess')
const removePropertiesWidgetByProductError = createAction('model/removePropertiesWidgetByProductError')

const getProductListWithOptionsRequest = createAction('model/getProductListWithOptionsRequest')
const getProductListWithOptionsSuccess = createAction('model/getProductListWithOptionsSuccess')
const getProductListWithOptionsError = createAction('model/getProductListWithOptionsError')

const updateGalleryFlagProductOrOptionRequest = createAction('model/updateGalleryFlagProductOrOptionRequest')
const updateGalleryFlagProductOrOptionSuccess = createAction('model/updateGalleryFlagProductOrOptionSuccess')
const updateGalleryFlagProductOrOptionError = createAction('model/updateGalleryFlagProductOrOptionError')

const getProductListWithOptionsAndPricesRequest = createAction('model/getProductListWithOptionsAndPricesRequest')
const getProductListWithOptionsAndPricesSuccess = createAction('model/getProductListWithOptionsAndPricesSuccess')
const getProductListWithOptionsAndPricesError = createAction('model/getProductListWithOptionsAndPricesError')

const copyPriceToOptionsRequest = createAction('model/copyPriceToOptionsRequest')
const copyPriceToOptionsSuccess = createAction('model/copyPriceToOptionsSuccess')
const copyPriceToOptionsError = createAction('model/copyPriceToOptionsError')

const updatePriceProductOrOptionRequest = createAction('model/updatePriceProductOrOptionRequest')
const updatePriceProductOrOptionSuccess = createAction('model/updatePriceProductOrOptionSuccess')
const updatePriceProductOrOptionError = createAction('model/updatePriceProductOrOptionError')

const createUpdateLocationRequest = createAction('model/createUpdateLocationRequest')
const createUpdateLocationSuccess = createAction('model/createUpdateLocationSuccess')
const createUpdateLocationError = createAction('model/createUpdateLocationError')

const getLocationsRequest = createAction('model/getLocationsRequest')
const getLocationsSuccess = createAction('model/getLocationsSuccess')
const getLocationsError = createAction('model/getLocationsError')

const getLocationByIdRequest = createAction('model/getLocationByIdRequest')
const getLocationByIdSuccess = createAction('model/getLocationByIdSuccess')
const getLocationByIdError = createAction('model/getLocationByIdError')

const getWidgetsListNotCatalogRequest = createAction('model/getWidgetsListNotCatalogRequest')
const getWidgetsListNotCatalogSuccess = createAction('model/getWidgetsListNotCatalogSuccess')
const getWidgetsListNotCatalogError = createAction('model/getWidgetsListNotCatalogError')

const getCatalogsListRequest = createAction('model/getCatalogsListRequest')
const getCatalogsListSuccess = createAction('model/getCatalogsListSuccess')
const getCatalogsListError = createAction('model/getCatalogsListError')

const linkUnlinkWidgetToCatalogRequest = createAction('model/linkUnlinkWidgetToCatalogRequest')
const linkUnlinkWidgetToCatalogSuccess = createAction('model/linkUnlinkWidgetToCatalogSuccess')
const linkUnlinkWidgetToCatalogError = createAction('model/linkUnlinkWidgetToCatalogError')

const getProductsInStockRequest = createAction('model/getProductsInStockRequest')
const getProductsInStockSuccess = createAction('model/getProductsInStockSuccess')
const getProductsInStockError = createAction('model/getProductsInStockError')

const getLocationShortListRequest = createAction('model/getLocationShortListRequest')
const getLocationShortListSuccess = createAction('model/getLocationShortListSuccess')
const getLocationShortListError = createAction('model/getLocationShortListError')

const getProductsInStockNotLocationRequest = createAction('model/getProductsInStockNotLocationRequest')
const getProductsInStockNotLocationSuccess = createAction('model/getProductsInStockNotLocationSuccess')
const getProductsInStockNotLocationError = createAction('model/getProductsInStockNotLocationError')

const initProductInLocationRequest = createAction('model/initProductInLocationRequest')
const initProductInLocationSuccess = createAction('model/initProductInLocationSuccess')
const initProductInLocationError = createAction('model/initProductInLocationError')

const getProductBarcodesListRequest = createAction('model/getProductBarcodesListRequest')
const getProductBarcodesListSuccess = createAction('model/getProductBarcodesListSuccess')
const getProductBarcodesListError = createAction('model/getProductBarcodesListError')

const addProductBarcodeRequest = createAction('model/addProductBarcodeRequest')
const addProductBarcodeSuccess = createAction('model/addProductBarcodeSuccess')
const addProductBarcodeError = createAction('model/addProductBarcodeError')

const moveProductToLocationRequest = createAction('model/moveProductToLocationRequest')
const moveProductToLocationSuccess = createAction('model/moveProductToLocationSuccess')
const moveProductToLocationError = createAction('model/moveProductToLocationError')

const getLocationsWithProductsQtyRequest = createAction('model/getLocationsWithProductsQtyRequest')
const getLocationsWithProductsQtySuccess = createAction('model/getLocationsWithProductsQtySuccess')
const getLocationsWithProductsQtyError = createAction('model/getLocationsWithProductsQtyError')

const getMovementProductHistoryRequest = createAction('model/getMovementProductHistoryRequest')
const getMovementProductHistorySuccess = createAction('model/getMovementProductHistorySuccess')
const getMovementProductHistoryError = createAction('model/getMovementProductHistoryError')

const removeImageFromGalleryRequest = createAction('model/removeImageFromGalleryRequest')
const removeImageFromGallerySuccess = createAction('model/removeImageFromGallerySuccess')
const removeImageFromGalleryError = createAction('model/removeImageFromGalleryError')

const updateSpecialPriceProductOrOptionRequest = createAction('model/updateSpecialPriceProductOrOptionRequest')
const updateSpecialPriceProductOrOptionSuccess = createAction('model/updateSpecialPriceProductOrOptionSuccess')
const updateSpecialPriceProductOrOptionError = createAction('model/updateSpecialPriceProductOrOptionError')

const getProductFromLuceneRequest = createAction('model/getProductFromLuceneRequest')
const getProductFromLuceneSuccess = createAction('model/getProductFromLuceneSuccess')
const getProductFromLuceneError = createAction('model/getProductFromLuceneError')

const portalRemoveProductByIdRequest = createAction('model/portalRemoveProductByIdRequest')
const portalRemoveProductByIdSuccess = createAction('model/portalRemoveProductByIdSuccess')
const portalRemoveProductByIdError = createAction('model/portalRemoveProductByIdError')

const getAttributesByProductRequest = createAction('model/getAttributesByProductRequest')
const getAttributesByProductSuccess = createAction('model/getAttributesByProductSuccess')
const getAttributesByProductError = createAction('model/getAttributesByProductError')

const getWidgetsNoAttributesProductRequest = createAction('model/getWidgetsNoAttributesProductRequest')
const getWidgetsNoAttributesProductSuccess = createAction('model/getWidgetsNoAttributesProductSuccess')
const getWidgetsNoAttributesProductError = createAction('model/getWidgetsNoAttributesProductError')

const addAttributeByProductRequest = createAction('model/addAttributeByProductRequest')
const addAttributeByProductSuccess = createAction('model/addAttributeByProductSuccess')
const addAttributeByProductError = createAction('model/addAttributeByProductError')

const removeAttributeByProductRequest = createAction('model/removeAttributeByProductRequest')
const removeAttributeByProductSuccess = createAction('model/removeAttributeByProductSuccess')
const removeAttributeByProductError = createAction('model/removeAttributeByProductError')

const getProductCategoriesRequest = createAction('model/getProductCategoriesRequest')
const getProductCategoriesSuccess = createAction('model/getProductCategoriesSuccess')
const getProductCategoriesError = createAction('model/getProductCategoriesError')

const updateProductCategoryRequest = createAction('model/updateProductCategoryRequest')
const updateProductCategorySuccess = createAction('model/updateProductCategorySuccess')
const updateProductCategoryError = createAction('model/updateProductCategoryError')

const addRemovePropertiesWidgetByProductRequest = createAction('model/addRemovePropertiesWidgetByProductRequest')
const addRemovePropertiesWidgetByProductSuccess = createAction('model/addRemovePropertiesWidgetByProductSuccess')
const addRemovePropertiesWidgetByProductError = createAction('model/addRemovePropertiesWidgetByProductError')

const getAttributesByGroupAndProductRequest = createAction('model/getAttributesByGroupAndProductRequest')
const getAttributesByGroupAndProductSuccess = createAction('model/getAttributesByGroupAndProductSuccess')
const getAttributesByGroupAndProductError = createAction('model/getAttributesByGroupAndProductError')

const dragAndDropPropertiesWidgetByProductRequest = createAction('model/dragAndDropPropertiesWidgetByProductRequest')
const dragAndDropPropertiesWidgetByProductSuccess = createAction('model/dragAndDropPropertiesWidgetByProductSuccess')
const dragAndDropPropertiesWidgetByProductError = createAction('model/dragAndDropPropertiesWidgetByProductError')

const getPricingAttributesByProductRequest = createAction('model/getPricingAttributesByProductRequest')
const getPricingAttributesByProductSuccess = createAction('model/getPricingAttributesByProductSuccess')
const getPricingAttributesByProductError = createAction('model/getPricingAttributesByProductError')

const changePricingOptionsFlagRequest = createAction('model/changePricingOptionsFlagRequest')
const changePricingOptionsFlagSuccess = createAction('model/changePricingOptionsFlagSuccess')
const changePricingOptionsFlagError = createAction('model/changePricingOptionsFlagError')

const notLinkedPricingAttributesByProductRequest = createAction('model/notLinkedPricingAttributesByProductRequest')
const notLinkedPricingAttributesByProductSuccess = createAction('model/notLinkedPricingAttributesByProductSuccess')
const notLinkedPricingAttributesByProductError = createAction('model/notLinkedPricingAttributesByProductError')

const addPricingAttributeByProductRequest = createAction('model/addPricingAttributeByProductRequest')
const addPricingAttributeByProductSuccess = createAction('model/addPricingAttributeByProductSuccess')
const addPricingAttributeByProductError = createAction('model/addPricingAttributeByProductError')

const removePricingAttributeByProductRequest = createAction('model/removePricingAttributeByProductRequest')
const removePricingAttributeByProductSuccess = createAction('model/removePricingAttributeByProductSuccess')
const removePricingAttributeByProductError = createAction('model/removePricingAttributeByProductError')

const getMeasuresRequest = createAction('model/getMeasuresRequest')
const getMeasuresSuccess = createAction('model/getMeasuresSuccess')
const getMeasuresError = createAction('model/getMeasuresError')

const createUpdateManualMeasureRequest = createAction('model/createUpdateManualMeasureRequest')
const createUpdateManualMeasureSuccess = createAction('model/createUpdateManualMeasureSuccess')
const createUpdateManualMeasureError = createAction('model/createUpdateManualMeasureError')

const getMeasureByIdRequest = createAction('model/getMeasureByIdRequest')
const getMeasureByIdSuccess = createAction('model/getMeasureByIdSuccess')
const getMeasureByIdError = createAction('model/getMeasureByIdError')

const removeManualMeasureRequest = createAction('model/removeManualMeasureRequest')
const removeManualMeasureSuccess = createAction('model/removeManualMeasureSuccess')
const removeManualMeasureError = createAction('model/removeManualMeasureError')

const getItemListLinkGroupRequest = createAction('model/getItemListLinkGroupRequest')
const getItemListLinkGroupSuccess = createAction('model/getItemListLinkGroupSuccess')
const getItemListLinkGroupError = createAction('model/getItemListLinkGroupError')

const getItemListNotLinkGroupRequest = createAction('model/getItemListNotLinkGroupRequest')
const getItemListNotLinkGroupSuccess = createAction('model/getItemListNotLinkGroupSuccess')
const getItemListNotLinkGroupError = createAction('model/getItemListNotLinkGroupError')

const dragAndDropSpecialProductsForProductRequest = createAction('model/dragAndDropSpecialProductsForProductRequest')
const dragAndDropSpecialProductsForProductSuccess = createAction('model/dragAndDropSpecialProductsForProductSuccess')
const dragAndDropSpecialProductsForProductError = createAction('model/dragAndDropSpecialProductsForProductError')

const dragAndDropItemListLinkGroupRequest = createAction('model/dragAndDropItemListLinkGroupRequest')
const dragAndDropItemListLinkGroupSuccess = createAction('model/dragAndDropItemListLinkGroupSuccess')
const dragAndDropItemListLinkGroupError = createAction('model/dragAndDropItemListLinkGroupError')

const getSpecialProductsForProductRequest = createAction('model/getSpecialProductsForProductRequest')
const getSpecialProductsForProductSuccess = createAction('model/getSpecialProductsForProductSuccess')
const getSpecialProductsForProductError = createAction('model/getSpecialProductsForProductError')

const getSpecialProductsNotLinkedProductRequest = createAction('model/getSpecialProductsNotLinkedProductRequest')
const getSpecialProductsNotLinkedProductSuccess = createAction('model/getSpecialProductsNotLinkedProductSuccess')
const getSpecialProductsNotLinkedProductError = createAction('model/getSpecialProductsNotLinkedProductError')

const linkUnlinkSpecialProductsForProductRequest = createAction('model/linkUnlinkSpecialProductsForProductRequest')
const linkUnlinkSpecialProductsForProductSuccess = createAction('model/linkUnlinkSpecialProductsForProductSuccess')
const linkUnlinkSpecialProductsForProductError = createAction('model/linkUnlinkSpecialProductsForProductError')

const getLinkedCatalogsRequest = createAction('model/getLinkedCatalogsRequest')
const getLinkedCatalogsSuccess = createAction('model/getLinkedCatalogsSuccess')
const getLinkedCatalogsError = createAction('model/getLinkedCatalogsError')

const getNotLinkedCatalogsRequest = createAction('model/getNotLinkedCatalogsRequest')
const getNotLinkedCatalogsSuccess = createAction('model/getNotLinkedCatalogsSuccess')
const getNotLinkedCatalogsError = createAction('model/getNotLinkedCatalogsError')

const drugAndDropCatalogsRequest = createAction('model/drugAndDropCatalogsRequest')
const drugAndDropCatalogsSuccess = createAction('model/drugAndDropCatalogsSuccess')
const drugAndDropCatalogsError = createAction('model/drugAndDropCatalogsError')

const getFeedbackShortInfoRequest = createAction('model/getFeedbackShortInfoRequest')
const getFeedbackShortInfoSuccess = createAction('model/getFeedbackShortInfoSuccess')
const getFeedbackShortInfoError = createAction('model/getFeedbackShortInfoError')

const getFeedbackListRequest = createAction('model/getFeedbackListRequest')
const getFeedbackListSuccess = createAction('model/getFeedbackListSuccess')
const getFeedbackListError = createAction('model/getFeedbackListError')

const getAllProductsInStockRequest = createAction('model/getAllProductsInStockRequest')
const getAllProductsInStockSuccess = createAction('model/getAllProductsInStockSuccess')
const getAllProductsInStockError = createAction('model/getAllProductsInStockError')

const clearErrorText = createAction('model/clearErrorText')

const getWidgetRecordsRequest = createAction('model/getWidgetRecordsRequest')
const getWidgetRecordsSuccess = createAction('model/getWidgetRecordsSuccess')
const getWidgetRecordsError = createAction('model/getWidgetRecordsError')

const updateVisibleGroupNameRequest = createAction('model/updateVisibleGroupNameRequest')
const updateVisibleGroupNameSuccess = createAction('model/updateVisibleGroupNameSuccess')
const updateVisibleGroupNameError = createAction('model/updateVisibleGroupNameError')

const getVisibleGroupNameRequest = createAction('model/getVisibleGroupNameRequest')
const getVisibleGroupNameSuccess = createAction('model/getVisibleGroupNameSuccess')
const getVisibleGroupNameError = createAction('model/getVisibleGroupNameError')

const getMainSaitSettingsRequest = createAction('model/getMainSaitSettingsRequest')
const getMainSaitSettingsSuccess = createAction('model/getMainSaitSettingsSuccess')
const getMainSaitSettingsError = createAction('model/getMainSaitSettingsError')

const updateMainSaitSettingsRequest = createAction('model/updateMainSaitSettingsRequest')
const updateMainSaitSettingsSuccess = createAction('model/updateMainSaitSettingsSuccess')
const updateMainSaitSettingsError = createAction('model/updateMainSaitSettingsError')

const createSaitRequest = createAction('model/createSaitRequest')
const createSaitSuccess = createAction('model/createSaitSuccess')
const createSaitError = createAction('model/createSaitError')

const getSaitSettingsListRequest = createAction('model/getSaitSettingsListRequest')
const getSaitSettingsListSuccess = createAction('model/getSaitSettingsListSuccess')
const getSaitSettingsListError = createAction('model/getSaitSettingsListError')

const getContactsAndSocialSaitSettingsRequest = createAction('model/getContactsAndSocialSaitSettingsRequest')
const getContactsAndSocialSaitSettingsSuccess = createAction('model/getContactsAndSocialSaitSettingsSuccess')
const getContactsAndSocialSaitSettingsError = createAction('model/getContactsAndSocialSaitSettingsError')

const updateContactsAndSocialSaitSettingsRequest = createAction('model/updateContactsAndSocialSaitSettingsRequest')
const updateContactsAndSocialSaitSettingsSuccess = createAction('model/updateContactsAndSocialSaitSettingsSuccess')
const updateContactsAndSocialSaitSettingsError = createAction('model/updateContactsAndSocialSaitSettingsError')

const getPartnersSaitSettingsRequest = createAction('model/getPartnersSaitSettingsRequest')
const getPartnersSaitSettingsSuccess = createAction('model/getPartnersSaitSettingsSuccess')
const getPartnersSaitSettingsError = createAction('model/getPartnersSaitSettingsError')

const getPartnerByIdSaitSettingsRequest = createAction('model/getPartnerByIdSaitSettingsRequest')
const getPartnerByIdSaitSettingsSuccess = createAction('model/getPartnerByIdSaitSettingsSuccess')
const getPartnerByIdSaitSettingsError = createAction('model/getPartnerByIdSaitSettingsError')

const createUpdatePartnerSaitSettingsRequest = createAction('model/createUpdatePartnerSaitSettingsRequest')
const createUpdatePartnerSaitSettingsSuccess = createAction('model/createUpdatePartnerSaitSettingsSuccess')
const createUpdatePartnerSaitSettingsError = createAction('model/createUpdatePartnerSaitSettingsError')

const removePartnerSaitSettingsRequest = createAction('model/removePartnerSaitSettingsRequest')
const removePartnerSaitSettingsSuccess = createAction('model/removePartnerSaitSettingsSuccess')
const removePartnerSaitSettingsError = createAction('model/removePartnerSaitSettingsError')


// const getLegalInfoSaitSettingsListRequest = createAction('model/getLegalInfoSaitSettingsListRequest')
// const getLegalInfoSaitSettingsListSuccess = createAction('model/getLegalInfoSaitSettingsListSuccess')
// const getLegalInfoSaitSettingsListError = createAction('model/getLegalInfoSaitSettingsListError')


export default{

    

    createUpdateGlobalPartnerSaitSettingsRequest:createAction('model/createUpdateGlobalPartnerSaitSettingsRequest'),
    createUpdateGlobalPartnerSaitSettingsSuccess:createAction('model/createUpdateGlobalPartnerSaitSettingsSuccess'),
    createUpdateGlobalPartnerSaitSettingsError:createAction('model/createUpdateGlobalPartnerSaitSettingsError'),

    getGlobalPartnerByIdSaitSettingsRequest:createAction('model/getGlobalPartnerByIdSaitSettingsRequest'),
    getGlobalPartnerByIdSaitSettingsSuccess:createAction('model/getGlobalPartnerByIdSaitSettingsSuccess'),
    getGlobalPartnerByIdSaitSettingsError:createAction('model/getGlobalPartnerByIdSaitSettingsError'),

    removeGlobalPartnerSaitSettingsRequest:createAction('model/removeGlobalPartnerSaitSettingsRequest'),
    removeGlobalPartnerSaitSettingsSuccess:createAction('model/removeGlobalPartnerSaitSettingsSuccess'),
    removeGlobalPartnerSaitSettingsError:createAction('model/removeGlobalPartnerSaitSettingsError'),

    updateGlobalPaymentSystemStripRequest:createAction('model/updateGlobalPaymentSystemStripRequest'),
    updateGlobalPaymentSystemStripSuccess:createAction('model/updateGlobalPaymentSystemStripSuccess'),
    updateGlobalPaymentSystemStripError:createAction('model/updateGlobalPaymentSystemStripError'),

    updateGlobalPaymentSystemSquareupRequest:createAction('model/updateGlobalPaymentSystemSquareupRequest'),
    updateGlobalPaymentSystemSquareupSuccess:createAction('model/updateGlobalPaymentSystemSquareupSuccess'),
    updateGlobalPaymentSystemSquareupError:createAction('model/updateGlobalPaymentSystemSquareupError'),

    updateGlobalPaymentSystemBraintreeRequest:createAction('model/updateGlobalPaymentSystemBraintreeRequest'),
    updateGlobalPaymentSystemBraintreeSuccess:createAction('model/updateGlobalPaymentSystemBraintreeSuccess'),
    updateGlobalPaymentSystemBraintreeError:createAction('model/updateGlobalPaymentSystemBraintreeError'),

    updateGlobalPaymentSystemPaypalRequest:createAction('model/updateGlobalPaymentSystemPaypalRequest'),
    updateGlobalPaymentSystemPaypalSuccess:createAction('model/updateGlobalPaymentSystemPaypalSuccess'),
    updateGlobalPaymentSystemPaypalError:createAction('model/updateGlobalPaymentSystemPaypalError'),

    globalPaymentSystemsTypesRequest:createAction('model/globalPaymentSystemsTypesRequest'),
    globalPaymentSystemsTypesSuccess:createAction('model/globalPaymentSystemsTypesSuccess'),
    globalPaymentSystemsTypesError:createAction('model/globalPaymentSystemsTypesError'),

    webGlobalPaymentSystemByTypeRequest:createAction('model/webGlobalSaitPaymentSystemByTypeRequest'),
    webGlobalPaymentSystemByTypeSuccess:createAction('model/webGlobalSaitPaymentSystemByTypeSuccess'),
    webGlobalPaymentSystemByTypeError:createAction('model/webGlobalSaitPaymentSystemByTypeError'),

    updateGlobalLegalInfoDeclineTextRequest:createAction('model/updateGlobalLegalInfoDeclineTextRequest'),
    updateGlobalLegalInfoDeclineTextSuccess:createAction('model/updateGlobalLegalInfoDeclineTextSuccess'),
    updateGlobalLegalInfoDeclineTextError:createAction('model/updateGlobalLegalInfoDeclineTextError'),

    updateGlobalLegalInfoDescriptionRequest:createAction('model/updateGlobalLegalInfoDescriptionRequest'),
    updateGlobalLegalInfoDescriptionSuccess:createAction('model/updateGlobalLegalInfoDescriptionSuccess'),
    updateGlobalLegalInfoDescriptionError:createAction('model/updateGlobalLegalInfoDescriptionError'),

    createUpdateGlobalLegalInfoTitleRequest:createAction('model/createUpdateGlobalLegalInfoTitleRequest'),
    createUpdateGlobalLegalInfoTitleSuccess:createAction('model/createUpdateGlobalLegalInfoTitleSuccess'),
    createUpdateGlobalLegalInfoTitleError:createAction('model/createUpdateGlobalLegalInfoTitleError'),

    getGlobalLegalInfoDeclineTextRequest:createAction('model/getGlobalLegalInfoDeclineTextRequest'),
    getGlobalLegalInfoDeclineTextSuccess:createAction('model/getGlobalLegalInfoDeclineTextSuccess'),
    getGlobalLegalInfoDeclineTextError:createAction('model/getGlobalLegalInfoDeclineTextError'),

    getGlobalLegalInfoDescriptionRequest:createAction('model/getGlobalLegalInfoDescriptionRequest'),
    getGlobalLegalInfoDescriptionSuccess:createAction('model/getGlobalLegalInfoDescriptionSuccess'),
    getGlobalLegalInfoDescriptionError:createAction('model/getGlobalLegalInfoDescriptionError'),

    getGlobalLegalInfoTitleRequest:createAction('model/getGlobalLegalInfoTitleRequest'),
    getGlobalLegalInfoTitleSuccess:createAction('model/getGlobalLegalInfoTitleSuccess'),
    getGlobalLegalInfoTitleError:createAction('model/getGlobalLegalInfoTitleError'),

    updateGlobalLegalInfoStatusRequest:createAction('model/updateGlobalLegalInfoStatusRequest'),
    updateGlobalLegalInfoStatusSuccess:createAction('model/updateGlobalLegalInfoStatusSuccess'),
    updateGlobalLegalInfoStatusError:createAction('model/updateGlobalLegalInfoStatusError'),

    removeGlobalLegalInfoRequest:createAction('model/removeGlobalLegalInfoRequest'),
    removeGlobalLegalInfoSuccess:createAction('model/removeGlobalLegalInfoSuccess'),
    removeGlobalLegalInfoError:createAction('model/removeGlobalLegalInfoError'),

    updateGlobalLegalInfoConfirmationRequest:createAction('model/updateGlobalLegalInfoConfirmationRequest'),
    updateGlobalLegalInfoConfirmationSuccess:createAction('model/updateGlobalLegalInfoConfirmationSuccess'),
    updateGlobalLegalInfoConfirmationError:createAction('model/updateGlobalLegalInfoConfirmationError'),

    updateGlobalContactsAndSocialSaitSettingsRequest:createAction('model/updateGlobalContactsAndSocialSaitSettingsRequest'),
    updateGlobalContactsAndSocialSaitSettingsSuccess:createAction('model/updateGlobalContactsAndSocialSaitSettingsSuccess'),
    updateGlobalContactsAndSocialSaitSettingsError:createAction('model/updateGlobalContactsAndSocialSaitSettingsError'),

    updateGlobalMainSaitSettingsRequest:createAction('model/updateGlobalMainSaitSettingsRequest'),
    updateGlobalMainSaitSettingsSuccess:createAction('model/updateGlobalMainSaitSettingsSuccess'),
    updateGlobalMainSaitSettingsError:createAction('model/updateGlobalMainSaitSettingsError'),

    updateGlobalProductListTypeSaitSettingsRequest:createAction('model/updateGlobalProductListTypeSaitSettingsRequest'),
    updateGlobalProductListTypeSaitSettingsSuccess:createAction('model/updateGlobalProductListTypeSaitSettingsSuccess'),
    updateGlobalProductListTypeSaitSettingsError:createAction('model/updateGlobalProductListTypeSaitSettingsError'),

    updateGlobalAdditionalPropertyStatusRequest:createAction('model/updateGlobalAdditionalPropertyStatusRequest'),
    updateGlobalAdditionalPropertyStatusSuccess:createAction('model/updateGlobalAdditionalPropertyStatusSuccess'),
    updateGlobalAdditionalPropertyStatusError:createAction('model/updateGlobalAdditionalPropertyStatusError'),

    getGlobalPartnersSaitSettingsRequest:createAction('model/getGlobalPartnersSaitSettingsRequest'),
    getGlobalPartnersSaitSettingsSuccess:createAction('model/getGlobalPartnersSaitSettingsSuccess'),
    getGlobalPartnersSaitSettingsError:createAction('model/getGlobalPartnersSaitSettingsError'),

    getGlobalLegalInfoSaitSettingsListRequest:createAction('model/getGlobalLegalInfoSaitSettingsListRequest'),
    getGlobalLegalInfoSaitSettingsListSuccess:createAction('model/getGlobalLegalInfoSaitSettingsListSuccess'),
    getGlobalLegalInfoSaitSettingsListError:createAction('model/getGlobalLegalInfoSaitSettingsListError'),

    getGlobalProductListTypeSaitSettingsRequest:createAction('model/getGlobalProductListTypeSaitSettingsRequest'),
    getGlobalProductListTypeSaitSettingsSuccess:createAction('model/getGlobalProductListTypeSaitSettingsSuccess'),
    getGlobalProductListTypeSaitSettingsError:createAction('model/getGlobalProductListTypeSaitSettingsError'),

    getGlobalContactsAndSocialSaitSettingsRequest:createAction('model/getGlobalContactsAndSocialSaitSettingsRequest'),
    getGlobalContactsAndSocialSaitSettingsSuccess:createAction('model/getGlobalContactsAndSocialSaitSettingsSuccess'),
    getGlobalContactsAndSocialSaitSettingsError:createAction('model/getGlobalContactsAndSocialSaitSettingsError'),

    getGlobalMainSaitSettingsRequest:createAction('model/getGlobalMainSaitSettingsRequest'),
    getGlobalMainSaitSettingsSuccess:createAction('model/getGlobalMainSaitSettingsSuccess'),
    getGlobalMainSaitSettingsError:createAction('model/getGlobalMainSaitSettingsError'),

    getAllProductsInStockWithFilterRequest:createAction('model/getAllProductsInStockWithFilterRequest'),
    getAllProductsInStockWithFilterSuccess:createAction('model/getAllProductsInStockWithFilterSuccess'),
    getAllProductsInStockWithFilterError:createAction('model/getAllProductsInStockWithFilterError'),

    getItemsListWithFilterRequest:createAction('model/getItemsListWithFilterRequest'),
    getItemsListWithFilterSuccess:createAction('model/getItemsListWithFilterSuccess'),
    getItemsListWithFilterError:createAction('model/getItemsListWithFilterError'),

    linkUnlinkItemCatalogMarketplaceRequest:createAction('model/linkUnlinkItemCatalogMarketplaceRequest'),
    linkUnlinkItemCatalogMarketplaceSuccess:createAction('model/linkUnlinkItemCatalogMarketplaceSuccess'),
    linkUnlinkItemCatalogMarketplaceError:createAction('model/linkUnlinkItemCatalogMarketplaceError'),

    getItemsUnlinkCatalogMarketplaceRequest:createAction('model/getItemsUnlinkCatalogMarketplaceRequest'),
    getItemsUnlinkCatalogMarketplaceSuccess:createAction('model/getItemsUnlinkCatalogMarketplaceSuccess'),
    getItemsUnlinkCatalogMarketplaceError:createAction('model/getItemsUnlinkCatalogMarketplaceError'),

    getItemsLinkCatalogMarketplaceRequest:createAction('model/getItemsLinkCatalogMarketplaceRequest'),
    getItemsLinkCatalogMarketplaceSuccess:createAction('model/getItemsLinkCatalogMarketplaceSuccess'),
    getItemsLinkCatalogMarketplaceError:createAction('model/getItemsLinkCatalogMarketplaceError'),

    dragAndDropCatalogMarketplaceRequest:createAction('model/dragAndDropCatalogMarketplaceRequest'),
    dragAndDropCatalogMarketplaceSuccess:createAction('model/dragAndDropCatalogMarketplaceSuccess'),
    dragAndDropCatalogMarketplaceError:createAction('model/dragAndDropCatalogMarketplaceError'),

    getCatalogMarketplaceByIdRequest:createAction('model/getCatalogMarketplaceByIdRequest'),
    getCatalogMarketplaceByIdSuccess:createAction('model/getCatalogMarketplaceByIdSuccess'),
    getCatalogMarketplaceByIdError:createAction('model/getCatalogMarketplaceByIdError'),
    
    createUpdateCatalogMarketplaceRequest:createAction('model/createUpdateCatalogMarketplaceRequest'),
    createUpdateCatalogMarketplaceSuccess:createAction('model/createUpdateCatalogMarketplaceSuccess'),
    createUpdateCatalogMarketplaceError:createAction('model/createUpdateCatalogMarketplaceError'),

    getCatalogsMarketplaceRequest:createAction('model/getCatalogsMarketplaceRequest'),
    getCatalogsMarketplaceSuccess:createAction('model/getCatalogsMarketplaceSuccess'),
    getCatalogsMarketplaceError:createAction('model/getCatalogsMarketplaceError'),

    sendTestEmailRequest:createAction('model/sendTestEmailRequest'),
    sendTestEmailSuccess:createAction('model/sendTestEmailSuccess'),
    sendTestEmailError:createAction('model/sendTestEmailError'),

    getProductSettingsDiscountRequest:createAction('model/getProductSettingsDiscountRequest'),
    getProductSettingsDiscountSuccess:createAction('model/getProductSettingsDiscountSuccess'),
    getProductSettingsDiscountError:createAction('model/getProductSettingsDiscountError'),

    getExchangeAddressTransactionsRequest:createAction('model/getExchangeAddressTransactionsRequest'),
    getExchangeAddressTransactionsSuccess:createAction('model/getExchangeAddressTransactionsSuccess'),
    getExchangeAddressTransactionsError:createAction('model/getExchangeAddressTransactionsError'),

    updateRatesManagmentRequest:createAction('model/updateRatesManagmentRequest'),
    updateRatesManagmentSuccess:createAction('model/updateRatesManagmentSuccess'),
    updateRatesManagmentError:createAction('model/updateRatesManagmentError'),

    updateExchangeAddressRequest:createAction('model/updateExchangeAddressRequest'),
    updateExchangeAddressSuccess:createAction('model/updateExchangeAddressSuccess'),
    updateExchangeAddressError:createAction('model/updateExchangeAddressError'),

    getExchangeAddressRequest:createAction('model/getExchangeAddressRequest'),
    getExchangeAddressSuccess:createAction('model/getExchangeAddressSuccess'),
    getExchangeAddressError:createAction('model/getExchangeAddressError'),

    getMangmentRatesListRequest:createAction('model/getMangmentRatesListRequest'),
    getMangmentRatesListSuccess:createAction('model/getMangmentRatesListSuccess'),
    getMangmentRatesListError:createAction('model/getMangmentRatesListError'),

    getRatesManagmentRequest:createAction('model/getRatesManagmentRequest'),
    getRatesManagmentSuccess:createAction('model/getRatesManagmentSuccess'),
    getRatesManagmentError:createAction('model/getRatesManagmentError'),

    setPointDiscountDefaultValueRequest:createAction('model/setPointDiscountDefaultValueRequest'),
    setPointDiscountDefaultValueSuccess:createAction('model/setPointDiscountDefaultValueSuccess'),
    setPointDiscountDefaultValueError:createAction('model/setPointDiscountDefaultValueError'),

    setCashbackPointsDefaultValueRequest:createAction('model/setCashbackPointsDefaultValueRequest'),
    setCashbackPointsDefaultValueSuccess:createAction('model/setCashbackPointsDefaultValueSuccess'),
    setCashbackPointsDefaultValueError:createAction('model/setCashbackPointsDefaultValueError'),

    setPointDiscountMaxLimitRequest:createAction('model/setPointDiscountMaxLimitRequest'),
    setPointDiscountMaxLimitSuccess:createAction('model/setPointDiscountMaxLimitSuccess'),
    setPointDiscountMaxLimitError:createAction('model/setPointDiscountMaxLimitError'),

    setCashbackPointsMaxLimitRequest:createAction('model/setCashbackPointsMaxLimitRequest'),
    setCashbackPointsMaxLimitSuccess:createAction('model/setCashbackPointsMaxLimitSuccess'),
    setCashbackPointsMaxLimitError:createAction('model/setCashbackPointsMaxLimitError'),

    getDefaultSettingsDiscountRequest:createAction('model/getDefaultSettingsDiscountRequest'),
    getDefaultSettingsDiscountSuccess:createAction('model/getDefaultSettingsDiscountSuccess'),
    getDefaultSettingsDiscountError:createAction('model/getDefaultSettingsDiscountError'),

    setProductPercentDiscountRequest:createAction('model/setProductPercentDiscountRequest'),
    setProductPercentDiscountSuccess:createAction('model/setProductPercentDiscountSuccess'),
    setProductPercentDiscountError:createAction('model/setProductPercentDiscountError'),

    getProductsMarketplaceRequest:createAction('model/getProductsMarketplaceRequest'),
    getProductsMarketplaceSuccess:createAction('model/getProductsMarketplaceSuccess'),
    getProductsMarketplaceError:createAction('model/getProductsMarketplaceError'),

    getMailsWithFilterRequest:createAction('model/getMailsWithFilterRequest'),
    getMailsWithFilterSuccess:createAction('model/getMailsWithFilterSuccess'),
    getMailsWithFilterError:createAction('model/getMailsWithFilterError'),

    stopStartDistributionRequest:createAction('model/stopStartDistributionRequest'),
    stopStartDistributionSuccess:createAction('model/stopStartDistributionSuccess'),
    stopStartDistributionError:createAction('model/stopStartDistributionError'),

    removeTemplateDistributionRequest:createAction('model/removeTemplateDistributionRequest'),
    removeTemplateDistributionSuccess:createAction('model/removeTemplateDistributionSuccess'),
    removeTemplateDistributionError:createAction('model/removeTemplateDistributionError'),

    getSystemDistributionByIdRequest:createAction('model/getSystemDistributionByIdRequest'),
    getSystemDistributionByIdSuccess:createAction('model/getSystemDistributionByIdSuccess'),
    getSystemDistributionByIdError:createAction('model/getSystemDistributionByIdError'),

    getTemplatesDistributionRequest:createAction('model/getTemplatesDistributionRequest'),
    getTemplatesDistributionSuccess:createAction('model/getTemplatesDistributionSuccess'),
    getTemplatesDistributionError:createAction('model/getTemplatesDistributionError'),

    setTemplateDistributionRequest:createAction('model/setTemplateDistributionRequest'),
    setTemplateDistributionSuccess:createAction('model/setTemplateDistributionSuccess'),
    setTemplateDistributionError:createAction('model/setTemplateDistributionError'),

    createUpdateSystemDistributionRequest:createAction('model/createUpdateSystemDistributionRequest'),
    createUpdateSystemDistributionSuccess:createAction('model/createUpdateSystemDistributionSuccess'),
    createUpdateSystemDistributionError:createAction('model/createUpdateSystemDistributionError'),

    getDistributionSystemFunctionsRequest:createAction('model/getDistributionSystemFunctionsRequest'),
    getDistributionSystemFunctionsSuccess:createAction('model/getDistributionSystemFunctionsSuccess'),
    getDistributionSystemFunctionsError:createAction('model/getDistributionSystemFunctionsError'),

    getSystemDistributionListRequest:createAction('model/getSystemDistributionListRequest'),
    getSystemDistributionListSuccess:createAction('model/getSystemDistributionListSuccess'),
    getSystemDistributionListError:createAction('model/getSystemDistributionListError'),

    resendClientMailRequest:createAction('model/resendClientMailRequest'),
    resendClientMailSuccess:createAction('model/resendClientMailSuccess'),
    resendClientMailError:createAction('model/resendClientMailError'),

    getClientMailRequest:createAction('model/getClientMailRequest'),
    getClientMailSuccess:createAction('model/getClientMailSuccess'),
    getClientMailError:createAction('model/getClientMailError'),

    getMailsFromDistributionRequest:createAction('model/getMailsFromDistributionRequest'),
    getMailsFromDistributionSuccess:createAction('model/getMailsFromDistributionSuccess'),
    getMailsFromDistributionError:createAction('model/getMailsFromDistributionError'),

    removeDistributionRequest:createAction('model/removeDistributionRequest'),
    removeDistributionSuccess:createAction('model/removeDistributionSuccess'),
    removeDistributionError:createAction('model/removeDistributionError'),

    // removeTemplateDistributionRequest:createAction('model/removeTemplateDistributionRequest'),
    // removeTemplateDistributionSuccess:createAction('model/removeTemplateDistributionSuccess'),
    // removeTemplateDistributionError:createAction('model/removeTemplateDistributionError'),

    initDistributionRequest:createAction('model/initDistributionRequest'),
    initDistributionSuccess:createAction('model/initDistributionSuccess'),
    initDistributionError:createAction('model/initDistributionError'),

    createClientDistributionRequest:createAction('model/createClientDistributionRequest'),
    createClientDistributionSuccess:createAction('model/createClientDistributionSuccess'),
    createClientDistributionError:createAction('model/createClientDistributionError'),

    getDistributionTypesRequest:createAction('model/getDistributionTypesRequest'),
    getDistributionTypesSuccess:createAction('model/getDistributionTypesSuccess'),
    getDistributionTypesError:createAction('model/getDistributionTypesError'),

    getClientDistributionListRequest:createAction('model/getClientDistributionListRequest'),
    getClientDistributionListSuccess:createAction('model/getClientDistributionListSuccess'),
    getClientDistributionListError:createAction('model/getClientDistributionListError'),

    getDspTemplateByIdRequest:createAction('model/getDspTemplateByIdRequest'),
    getDspTemplateByIdSuccess:createAction('model/getDspTemplateByIdSuccess'),
    getDspTemplateByIdError:createAction('model/getDspTemplateByIdError'),

    removeDspTemplatesRequest:createAction('model/removeDspTemplatesRequest'),
    removeDspTemplatesSuccess:createAction('model/removeDspTemplatesSuccess'),
    removeDspTemplatesError:createAction('model/removeDspTemplatesError'),

    createUpdateDspTemplatesRequest:createAction('model/createUpdateDspTemplatesRequest'),
    createUpdateDspTemplatesSuccess:createAction('model/createUpdateDspTemplatesSuccess'),
    createUpdateDspTemplatesError:createAction('model/createUpdateDspTemplatesError'),

    removeDspTemplateImageRequest:createAction('model/removeDspTemplateImageRequest'),
    removeDspTemplateImageSuccess:createAction('model/removeDspTemplateImageSuccess'),
    removeDspTemplateImageError:createAction('model/removeDspTemplateImageError'),

    addDspTemplateImageRequest:createAction('model/addDspTemplateImageRequest'),
    addDspTemplateImageSuccess:createAction('model/addDspTemplateImageSuccess'),
    addDspTemplateImageError:createAction('model/addDspTemplateImageError'),

    getDspTemplateImagesRequest:createAction('model/getDspTemplateImagesRequest'),
    getDspTemplateImagesSuccess:createAction('model/getDspTemplateImagesSuccess'),
    getDspTemplateImagesError:createAction('model/getDspTemplateImagesError'),

    initDspTemplateIdRequest:createAction('model/initDspTemplateIdRequest'),
    initDspTemplateIdSuccess:createAction('model/initDspTemplateIdSuccess'),
    initDspTemplateIdError:createAction('model/initDspTemplateIdError'),

    getDspTemplatesRequest:createAction('model/getDspTemplatesRequest'),
    getDspTemplatesSuccess:createAction('model/getDspTemplatesSuccess'),
    getDspTemplatesError:createAction('model/getDspTemplatesError'),

    getDspSibscriptionByIdRequest:createAction('model/getDspSibscriptionByIdRequest'),
    getDspSibscriptionByIdSuccess:createAction('model/getDspSibscriptionByIdSuccess'),
    getDspSibscriptionByIdError:createAction('model/getDspSibscriptionByIdError'),

    removeDspSubscriptionsRequest:createAction('model/removeDspSubscriptionsRequest'),
    removeDspSubscriptionsSuccess:createAction('model/removeDspSubscriptionsSuccess'),
    removeDspSubscriptionsError:createAction('model/removeDspSubscriptionsError'),

    createUpdateDspSibscriptionsRequest:createAction('model/createUpdateDspSibscriptionsRequest'),
    createUpdateDspSibscriptionsSuccess:createAction('model/createUpdateDspSibscriptionsSuccess'),
    createUpdateDspSibscriptionsError:createAction('model/createUpdateDspSibscriptionsError'),

    getDspSubscriptionsRequest:createAction('model/getDspSubscriptionsRequest'),
    getDspSubscriptionsSuccess:createAction('model/getDspSubscriptionsSuccess'),
    getDspSubscriptionsError:createAction('model/getDspSubscriptionsError'),

    getTransactionsPayoutRequest:createAction('model/getTransactionsPayoutRequest'),
    getTransactionsPayoutSuccess:createAction('model/getTransactionsPayoutSuccess'),
    getTransactionsPayoutError:createAction('model/getTransactionsPayoutError'),

    createPayoutRequest:createAction('model/createPayoutRequest'),
    createPayoutSuccess:createAction('model/createPayoutSuccess'),
    createPayoutError:createAction('model/createPayoutError'),

    getPayoutMethodNamesRequest:createAction('model/getPayoutMethodNamesRequest'),
    getPayoutMethodNamesSuccess:createAction('model/getPayoutMethodNamesSuccess'),
    getPayoutMethodNamesError:createAction('model/getPayoutMethodNamesError'),
    
    createUpdatePayoutMethodRequest:createAction('model/createUpdatePayoutMethodRequest'),
    createUpdatePayoutMethodSuccess:createAction('model/createUpdatePayoutMethodSuccess'),
    createUpdatePayoutMethodError:createAction('model/createUpdatePayoutMethodError'),

    getPayoutMethodsRequest:createAction('model/getPayoutMethodsRequest'),
    getPayoutMethodsSuccess:createAction('model/getPayoutMethodsSuccess'),
    getPayoutMethodsError:createAction('model/getPayoutMethodsError'),

    getPayoutMethodTypesRequest:createAction('model/getPayoutMethodTypesRequest'),
    getPayoutMethodTypesSuccess:createAction('model/getPayoutMethodTypesSuccess'),
    getPayoutMethodTypesError:createAction('model/getPayoutMethodTypesError'),


    updateVendorImageRequest:createAction('model/updateVendorImageRequest'),
    updateVendorImageSuccess:createAction('model/updateVendorImageSuccess'),
    updateVendorImageError:createAction('model/updateVendorImageError'),

    getVendorInfoRequest:createAction('model/getVendorInfoRequest'),
    getVendorInfoSuccess:createAction('model/getVendorInfoSuccess'),
    getVendorInfoError:createAction('model/getVendorInfoError'),

    transactionBuyCoinsStripRequest:createAction('model/transactionBuyCoinsStripRequest'),
    transactionBuyCoinsStripSuccess:createAction('model/transactionBuyCoinsStripSuccess'),
    transactionBuyCoinsStripError:createAction('model/transactionBuyCoinsStripError'),

    getStripTokenRequest:createAction('model/getStripTokenRequest'),
    getStripTokenSuccess:createAction('model/getStripTokenSuccess'),
    getStripTokenError:createAction('model/getStripTokenError'),

    getStripClientSecretRequest:createAction('model/getStripClientSecretRequest'),
    getStripClientSecretSuccess:createAction('model/getStripClientSecretSuccess'),
    getStripClientSecretError:createAction('model/getStripClientSecretError'),

    transactionBuyCoinsPaypalRequest:createAction('model/transactionBuyCoinsPaypalRequest'),
    transactionBuyCoinsPaypalSuccess:createAction('model/transactionBuyCoinsPaypalSuccess'),
    transactionBuyCoinsPaypalError:createAction('model/transactionBuyCoinsPaypalError'),

    getPaypalClientIdRequest:createAction('model/getPaypalClientIdRequest'),
    getPaypalClientIdSuccess:createAction('model/getPaypalClientIdSuccess'),
    getPaypalClientIdError:createAction('model/getPaypalClientIdError'),

    transactionBuyCoinsBitcoinRequest:createAction('model/transactionBuyCoinsBitcoinRequest'),
    transactionBuyCoinsBitcoinSuccess:createAction('model/transactionBuyCoinsBitcoinSuccess'),
    transactionBuyCoinsBitcoinError:createAction('model/transactionBuyCoinsBitcoinError'),

    getBalanceInfoRequest:createAction('model/getBalanceInfoRequest'),
    getBalanceInfoSuccess:createAction('model/getBalanceInfoSuccess'),
    getBalanceInfoError:createAction('model/getBalanceInfoError'),

    saveProceedToCheckoutRequest:createAction('model/saveProceedToCheckoutRequest'),
    saveProceedToCheckoutSuccess:createAction('model/saveProceedToCheckoutSuccess'),
    saveProceedToCheckoutError:createAction('model/saveProceedToCheckoutError'),

    paymentSystemsCoinsAmountRequest:createAction('model/paymentSystemsCoinsAmountRequest'),
    paymentSystemsCoinsAmountSuccess:createAction('model/paymentSystemsCoinsAmountSuccess'),
    paymentSystemsCoinsAmountError:createAction('model/paymentSystemsCoinsAmountError'),

    getGlobalPaymentSystemsRequest:createAction('model/getGlobalPaymentSystemsRequest'),
    getGlobalPaymentSystemsSuccess:createAction('model/getGlobalPaymentSystemsSuccess'),
    getGlobalPaymentSystemsError:createAction('model/getGlobalPaymentSystemsError'),

    updateTransactionHashMetamaskRequest:createAction('model/updateTransactionHashMetamaskRequest'),
    updateTransactionHashMetamaskSuccess:createAction('model/updateTransactionHashMetamaskSuccess'),
    updateTransactionHashMetamaskError:createAction('model/updateTransactionHashMetamaskError'),

    getDataMetamaskRequest:createAction('model/getDataMetamaskRequest'),
    getDataMetamaskSuccess:createAction('model/getDataMetamaskSuccess'),
    getDataMetamaskError:createAction('model/getDataMetamaskError'),
    
    getUserBlockchainAddressRequest:createAction('model/getUserBlockchainAddressRequest'),
    getUserBlockchainAddressSuccess:createAction('model/getUserBlockchainAddressSuccess'),
    getUserBlockchainAddressError:createAction('model/getUserBlockchainAddressError'),

    updateUserBlockchainAddressRequest:createAction('model/updateUserBlockchainAddressRequest'),
    updateUserBlockchainAddressSuccess:createAction('model/updateUserBlockchainAddressSuccess'),
    updateUserBlockchainAddressError:createAction('model/updateUserBlockchainAddressError'),

    publishErc1155Request:createAction('model/publishErc1155Request'),
    publishErc1155Success:createAction('model/publishErc1155Success'),
    publishErc1155Error:createAction('model/publishErc1155Error'),

    dragAndDropItemsMarketplaceCatalogRequest:createAction('model/dragAndDropItemsMarketplaceCatalogRequest'),
    dragAndDropItemsMarketplaceCatalogSuccess:createAction('model/dragAndDropItemsMarketplaceCatalogSuccess'),
    dragAndDropItemsMarketplaceCatalogError:createAction('model/dragAndDropItemsMarketplaceCatalogError'),

    linkUnlinkItemMarketplaceCatalogRequest:createAction('model/linkUnlinkItemMarketplaceCatalogRequest'),
    linkUnlinkItemMarketplaceCatalogSuccess:createAction('model/linkUnlinkItemMarketplaceCatalogSuccess'),
    linkUnlinkItemMarketplaceCatalogError:createAction('model/linkUnlinkItemMarketplaceCatalogError'),

    getItemsUnlinkMarketplaceCatalogRequest:createAction('model/getItemsUnlinkMarketplaceCatalogRequest'),
    getItemsUnlinkMarketplaceCatalogSuccess:createAction('model/getItemsUnlinkMarketplaceCatalogSuccess'),
    getItemsUnlinkMarketplaceCatalogError:createAction('model/getItemsUnlinkMarketplaceCatalogError'),

    getItemsLinkMarketplaceCatalogRequest:createAction('model/getItemsLinkMarketplaceCatalogRequest'),
    getItemsLinkMarketplaceCatalogSuccess:createAction('model/getItemsLinkMarketplaceCatalogSuccess'),
    getItemsLinkMarketplaceCatalogError:createAction('model/getItemsLinkMarketplaceCatalogError'),

    dragAndDropMarketplaceCatalogRequest:createAction('model/dragAndDropMarketplaceCatalogRequest'),
    dragAndDropMarketplaceCatalogSuccess:createAction('model/dragAndDropMarketplaceCatalogSuccess'),
    dragAndDropMarketplaceCatalogError:createAction('model/dragAndDropMarketplaceCatalogError'),

    getMarketplaceCatalogByIdRequest:createAction('model/getMarketplaceCatalogByIdRequest'),
    getMarketplaceCatalogByIdSuccess:createAction('model/getMarketplaceCatalogByIdSuccess'),
    getMarketplaceCatalogByIdError:createAction('model/getMarketplaceCatalogByIdError'),

    createUpdateMarketplaceCatalogRequest:createAction('model/createUpdateMarketplaceCatalogRequest'),
    createUpdateMarketplaceCatalogSuccess:createAction('model/createUpdateMarketplaceCatalogSuccess'),
    createUpdateMarketplaceCatalogError:createAction('model/createUpdateMarketplaceCatalogError'),

    getMarketplaceCatalogsRequest:createAction('model/getMarketplaceCatalogsRequest'),
    getMarketplaceCatalogsSuccess:createAction('model/getMarketplaceCatalogsSuccess'),
    getMarketplaceCatalogsError:createAction('model/getMarketplaceCatalogsError'),

    updateWebSaitPaymentSystemStripRequest:createAction('model/updateWebSaitPaymentSystemStripRequest'),
    updateWebSaitPaymentSystemStripSuccess:createAction('model/updateWebSaitPaymentSystemStripSuccess'),
    updateWebSaitPaymentSystemStripError:createAction('model/updateWebSaitPaymentSystemStripError'),

    updateWebSaitPaymentSystemSquareupRequest:createAction('model/updateWebSaitPaymentSystemSquareupRequest'),
    updateWebSaitPaymentSystemSquareupSuccess:createAction('model/updateWebSaitPaymentSystemSquareupSuccess'),
    updateWebSaitPaymentSystemSquareupError:createAction('model/updateWebSaitPaymentSystemSquareupError'),

    dragAndDropGallaryRequest:createAction('model/dragAndDropGallaryRequest'),
    dragAndDropGallarySuccess:createAction('model/dragAndDropGallarySuccess'),
    dragAndDropGallaryError:createAction('model/dragAndDropGallaryError'),

    updateLocationStatusRequest:createAction('model/updateLocationStatusRequest'),
    updateLocationStatusSuccess:createAction('model/updateLocationStatusSuccess'),
    updateLocationStatusError:createAction('model/updateLocationStatusError'),

    removeShareSalesProfitRequest:createAction('model/removeShareSalesProfitRequest'),
    removeShareSalesProfitSuccess:createAction('model/removeShareSalesProfitSuccess'),
    removeShareSalesProfitError:createAction('model/removeShareSalesProfitError'),

    getShareSalesProfitListRequest:createAction('model/getShareSalesProfitListRequest'),
    getShareSalesProfitListSuccess:createAction('model/getShareSalesProfitListSuccess'),
    getShareSalesProfitListError:createAction('model/getShareSalesProfitListError'),

    updateShareSalesProfitStatusRequest:createAction('model/updateShareSalesProfitStatusRequest'),
    updateShareSalesProfitStatusSuccess:createAction('model/updateShareSalesProfitStatusSuccess'),
    updateShareSalesProfitStatusError:createAction('model/updateShareSalesProfitStatusError'),

    createShareSalesProfitRequest:createAction('model/createShareSalesProfitRequest'),
    createShareSalesProfitSuccess:createAction('model/createShareSalesProfitSuccess'),
    createShareSalesProfitError:createAction('model/createShareSalesProfitError'),

    getUserOrdersHistoryByUserRequest:createAction('model/getUserOrdersHistoryByUserRequest'),
    getUserOrdersHistoryByUserSuccess:createAction('model/getUserOrdersHistoryByUserSuccess'),
    getUserOrdersHistoryByUserError:createAction('model/getUserOrdersHistoryByUserError'),

    usersOrdersHistoryListRequest:createAction('model/usersOrdersHistoryListRequest'),
    usersOrdersHistoryListSuccess:createAction('model/usersOrdersHistoryListSuccess'),
    usersOrdersHistoryListError:createAction('model/usersOrdersHistoryListError'),

    currencyListRequest:createAction('model/currencyListRequest'),
    currencyListSuccess:createAction('model/currencyListSuccess'),
    currencyListError:createAction('model/currencyListError'),


    updateProductListTypeSaitSettingsRequest:createAction('model/updateProductListTypeSaitSettingsRequest'),
    updateProductListTypeSaitSettingsSuccess:createAction('model/updateProductListTypeSaitSettingsSuccess'),
    updateProductListTypeSaitSettingsError:createAction('model/updateProductListTypeSaitSettingsError'),

    getProductListTypeSaitSettingsRequest:createAction('model/getProductListTypeSaitSettingsRequest'),
    getProductListTypeSaitSettingsSuccess:createAction('model/getProductListTypeSaitSettingsSuccess'),
    getProductListTypeSaitSettingsError:createAction('model/getProductListTypeSaitSettingsError'),

    updateWebSaitPaymentSystemPaypalRequest:createAction('model/updateWebSaitPaymentSystemPaypalRequest'),
    updateWebSaitPaymentSystemPaypalSuccess:createAction('model/updateWebSaitPaymentSystemPaypalSuccess'),
    updateWebSaitPaymentSystemPaypalError:createAction('model/updateWebSaitPaymentSystemPaypalError'),

    updateWebSaitPaymentSystemBraintreeRequest:createAction('model/updateWebSaitPaymentSystemBraintreeRequest'),
    updateWebSaitPaymentSystemBraintreeSuccess:createAction('model/updateWebSaitPaymentSystemBraintreeSuccess'),
    updateWebSaitPaymentSystemBraintreeError:createAction('model/updateWebSaitPaymentSystemBraintreeError'),

    webSaitPaymentSystemByTypeRequest:createAction('model/webSaitPaymentSystemByTypeRequest'),
    webSaitPaymentSystemByTypeSuccess:createAction('model/webSaitPaymentSystemByTypeSuccess'),
    webSaitPaymentSystemByTypeError:createAction('model/webSaitPaymentSystemByTypeError'),

    webSaitPaymentSystemsTypesRequest:createAction('model/webSaitPaymentSystemsTypesRequest'),
    webSaitPaymentSystemsTypesSuccess:createAction('model/webSaitPaymentSystemsTypesSuccess'),
    webSaitPaymentSystemsTypesError:createAction('model/webSaitPaymentSystemsTypesError'),
   
    getWebSaitsProductStatusRequest:createAction('model/getWebSaitsProductStatusRequest'),
    getWebSaitsProductStatusSuccess:createAction('model/getWebSaitsProductStatusSuccess'),
    getWebSaitsProductStatusError:createAction('model/getWebSaitsProductStatusError'),

    updateMarketplaceProductStatusRequest:createAction('model/updateMarketplaceProductStatusRequest'),
    updateMarketplaceProductStatusSuccess:createAction('model/updateMarketplaceProductStatusSuccess'),
    updateMarketplaceProductStatusError:createAction('model/updateMarketplaceProductStatusError'),
    
    updateWebSaitProductStatusRequest:createAction('model/updateWebSaitProductStatusRequest'),
    updateWebSaitProductStatusSuccess:createAction('model/updateWebSaitProductStatusSuccess'),
    updateWebSaitProductStatusError:createAction('model/updateWebSaitProductStatusError'),

    cancelDeliveryRequest:createAction('model/cancelDeliveryRequest'),
    cancelDeliverySuccess:createAction('model/cancelDeliverySuccess'),
    cancelDeliveryError:createAction('model/cancelDeliveryError'),

    updateDeliveryStatusRequest:createAction('model/updateDeliveryStatusRequest'),
    updateDeliveryStatusSuccess:createAction('model/updateDeliveryStatusSuccess'),
    updateDeliveryStatusError:createAction('model/updateDeliveryStatusError'),

    updateDeliveryTrackingRequest:createAction('model/updateDeliveryTrackingRequest'),
    updateDeliveryTrackingSuccess:createAction('model/updateDeliveryTrackingSuccess'),
    updateDeliveryTrackingError:createAction('model/updateDeliveryTrackingError'),
    
    updateLocationDeliveryServiceRequest:createAction('model/updateLocationDeliveryServiceRequest'),
    updateLocationDeliveryServiceSuccess:createAction('model/updateLocationDeliveryServiceSuccess'),
    updateLocationDeliveryServiceError:createAction('model/updateLocationDeliveryServiceError'),

    getDeliveryTypesRequest:createAction('model/getDeliveryTypesRequest'),
    getDeliveryTypesSuccess:createAction('model/getDeliveryTypesSuccess'),
    getDeliveryTypesError:createAction('model/getDeliveryTypesError'),

    getDeliveryPackagesRequest:createAction('model/getDeliveryPackagesRequest'),
    getDeliveryPackagesSuccess:createAction('model/getDeliveryPackagesSuccess'),
    getDeliveryPackagesError:createAction('model/getDeliveryPackagesError'),

    updateLocationProductDeliveryRequest:createAction('model/updateLocationProductDeliveryRequest'),
    updateLocationProductDeliverySuccess:createAction('model/updateLocationProductDeliverySuccess'),
    updateLocationProductDeliveryError:createAction('model/updateLocationProductDeliveryError'),

    getLocationsByProductRequest:createAction('model/getLocationsByProductRequest'),
    getLocationsByProductSuccess:createAction('model/getLocationsByProductSuccess'),
    getLocationsByProductError:createAction('model/getLocationsByProductError'),

    getOrderProductsDeliveryRequest:createAction('model/getOrderProductsDeliveryRequest'),
    getOrderProductsDeliverySuccess:createAction('model/getOrderProductsDeliverySuccess'),
    getOrderProductsDeliveryError:createAction('model/getOrderProductsDeliveryError'),

    payCryptoOrderFromHistoryRequest:createAction('model/payCryptoOrderFromHistoryRequest'),
    payCryptoOrderFromHistorySuccess:createAction('model/payCryptoOrderFromHistorySuccess'),
    payCryptoOrderFromHistoryError:createAction('model/payCryptoOrderFromHistoryError'),

    getCryptoOrderForPayRequest:createAction('model/getCryptoOrderForPayRequest'),
    getCryptoOrderForPaySuccess:createAction('model/getCryptoOrderForPaySuccess'),
    getCryptoOrderForPayError:createAction('model/getCryptoOrderForPayError'),

    getOrdersHistoryRequest:createAction('model/getOrdersHistoryRequest'),
    getOrdersHistorySuccess:createAction('model/getOrdersHistorySuccess'),
    getOrdersHistoryError:createAction('model/getOrdersHistoryError'),

    updateAdditionalPropertyStatusRequest:createAction('model/updateAdditionalPropertyStatusRequest'),
    updateAdditionalPropertyStatusSuccess:createAction('model/updateAdditionalPropertyStatusSuccess'),
    updateAdditionalPropertyStatusError:createAction('model/updateAdditionalPropertyStatusError'),

    updateTranslateByCodeRequest:createAction('model/updateTranslateByCodeRequest'),
    updateTranslateByCodeSuccess:createAction('model/updateTranslateByCodeSuccess'),
    updateTranslateByCodeError:createAction('model/updateTranslateByCodeError'),

    getTranslateByCodeRequest:createAction('model/getTranslateByCodeRequest'),
    getTranslateByCodeSuccess:createAction('model/getTranslateByCodeSuccess'),
    getTranslateByCodeError:createAction('model/getTranslateByCodeError'),

    updatePaymentSaitSettingsAddressRequest:createAction('model/updatePaymentSaitSettingsAddressRequest'),
    updatePaymentSaitSettingsAddressSuccess:createAction('model/updatePaymentSaitSettingsAddressSuccess'),
    updatePaymentSaitSettingsAddressError:createAction('model/updatePaymentSaitSettingsAddressError'),

    updatePaymentSaitSettingsStatusRequest:createAction('model/updatePaymentSaitSettingsStatusRequest'),
    updatePaymentSaitSettingsStatusSuccess:createAction('model/updatePaymentSaitSettingsStatusSuccess'),
    updatePaymentSaitSettingsStatusError:createAction('model/updatePaymentSaitSettingsStatusError'),

    updatePaymentSaitSettingsIconsRequest:createAction('model/updatePaymentSaitSettingsIconsRequest'),
    updatePaymentSaitSettingsIconsSuccess:createAction('model/updatePaymentSaitSettingsIconsSuccess'),
    updatePaymentSaitSettingsIconsError:createAction('model/updatePaymentSaitSettingsIconsError'),

    updatePaymentSaitSettingsNameRequest:createAction('model/updatePaymentSaitSettingsNameRequest'),
    updatePaymentSaitSettingsNameSuccess:createAction('model/updatePaymentSaitSettingsNameSuccess'),
    updatePaymentSaitSettingsNameError:createAction('model/updatePaymentSaitSettingsNameError'),

    errCode:createAction('model/errCode'),
    
    updatePaymentSaitSettingsRequest:createAction('model/updatePaymentSaitSettingsRequest'),
    updatePaymentSaitSettingsSuccess:createAction('model/updatePaymentSaitSettingsSuccess'),
    updatePaymentSaitSettingsError:createAction('model/updatePaymentSaitSettingsError'),

    getPaymentSaitSettingsRequest:createAction('model/getPaymentSaitSettingsRequest'),
    getPaymentSaitSettingsSuccess:createAction('model/getPaymentSaitSettingsSuccess'),
    getPaymentSaitSettingsError:createAction('model/getPaymentSaitSettingsError'),
    
    removeLegalInfoRequest:createAction('model/removeLegalInfoRequest'),
    removeLegalInfoSuccess:createAction('model/removeLegalInfoSuccess'),
    removeLegalInfoError:createAction('model/removeLegalInfoError'),

    updateLegalInfoConfirmationRequest:createAction('model/updateLegalInfoConfirmationRequest'),
    updateLegalInfoConfirmationSuccess:createAction('model/updateLegalInfoConfirmationSuccess'),
    updateLegalInfoConfirmationError:createAction('model/updateLegalInfoConfirmationError'),

    updateLegalInfoStatusRequest:createAction('model/updateLegalInfoStatusRequest'),
    updateLegalInfoStatusSuccess:createAction('model/updateLegalInfoStatusSuccess'),
    updateLegalInfoStatusError:createAction('model/updateLegalInfoStatusError'),

    getLegalInfoTitleRequest:createAction('model/getLegalInfoTitleRequest'),
    getLegalInfoTitleSuccess:createAction('model/getLegalInfoTitleSuccess'),
    getLegalInfoTitleError:createAction('model/getLegalInfoTitleError'),

    getLegalInfoDeclineTextRequest:createAction('model/getLegalInfoDeclineTextRequest'),
    getLegalInfoDeclineTextSuccess:createAction('model/getLegalInfoDeclineTextSuccess'),
    getLegalInfoDeclineTextError:createAction('model/getLegalInfoDeclineTextError'),

    getLegalInfoDescriptionRequest:createAction('model/getLegalInfoDescriptionRequest'),
    getLegalInfoDescriptionSuccess:createAction('model/getLegalInfoDescriptionSuccess'),
    getLegalInfoDescriptionError:createAction('model/getLegalInfoDescriptionError'),

    updateLegalInfoDeclineTextRequest:createAction('model/updateLegalInfoDeclineTextRequest'),
    updateLegalInfoDeclineTextSuccess:createAction('model/updateLegalInfoDeclineTextSuccess'),
    updateLegalInfoDeclineTextError:createAction('model/updateLegalInfoDeclineTextError'),

    updateLegalInfoDescriptionRequest:createAction('model/updateLegalInfoDescriptionRequest'),
    updateLegalInfoDescriptionSuccess:createAction('model/updateLegalInfoDescriptionSuccess'),
    updateLegalInfoDescriptionError:createAction('model/updateLegalInfoDescriptionError'),

    createUpdateLegalInfoTitleRequest:createAction('model/createUpdateLegalInfoTitleRequest'),
    createUpdateLegalInfoTitleSuccess:createAction('model/createUpdateLegalInfoTitleSuccess'),
    createUpdateLegalInfoTitleError:createAction('model/createUpdateLegalInfoTitleError'),

    getLegalInfoSaitSettingsListRequest:createAction('model/getLegalInfoSaitSettingsListRequest'),
    getLegalInfoSaitSettingsListSuccess:createAction('model/getLegalInfoSaitSettingsListSuccess'),
    getLegalInfoSaitSettingsListError:createAction('model/getLegalInfoSaitSettingsListError'),


    getProductCategoriesMarketplaceRequest:createAction('model/getProductCategoriesMarketplaceRequest'),
    getProductCategoriesMarketplaceSuccess:createAction('model/getProductCategoriesMarketplaceSuccess'),
    getProductCategoriesMarketplaceError:createAction('model/getProductCategoriesMarketplaceError'),


    createUpdateProductCategoryMarketplaceRequest:createAction('model/createUpdateProductCategoryMarketplaceRequest'),
    createUpdateProductCategoryMarketplaceSuccess:createAction('model/createUpdateProductCategoryMarketplaceSuccess'),
    createUpdateProductCategoryMarketplaceError:createAction('model/createUpdateProductCategoryMarketplaceError'),

    getProductCategoriesMarketplaceBottomRequest:createAction('model/getProductCategoriesMarketplaceBottomRequest'),
    getProductCategoriesMarketplaceBottomSuccess:createAction('model/getProductCategoriesMarketplaceBottomSuccess'),
    getProductCategoriesMarketplaceBottomError:createAction('model/getProductCategoriesMarketplaceBottomError'),

    getProductCategoriesMarketplaceBottomTwoRequest:createAction('model/getProductCategoriesMarketplaceBottomTwoRequest'),
    getProductCategoriesMarketplaceBottomTwoSuccess:createAction('model/getProductCategoriesMarketplaceBottomTwoSuccess'),
    getProductCategoriesMarketplaceBottomTwoError:createAction('model/getProductCategoriesMarketplaceBottomTwoError'),

    getVendorsListInfoRequest:createAction('model/getVendorsListInfoRequest'),
    getVendorsListInfoSuccess:createAction('model/getVendorsListInfoSuccess'),
    getVendorsListInfoError:createAction('model/getVendorsListInfoError'),

    updateVendorStatusRequest:createAction('model/updateVendorStatusRequest'),
    updateVendorStatusSuccess:createAction('model/updateVendorStatusSuccess'),
    updateVendorStatusError:createAction('model/updateVendorStatusError'),

    getVendorProductListInfoRequest:createAction('model/getVendorProductListInfoRequest'),
    getVendorProductListInfoSuccess:createAction('model/getVendorProductListInfoSuccess'),
    getVendorProductListInfoError:createAction('model/getVendorProductListInfoError'),

    updateProductVerifiedStatusRequest:createAction('model/updateProductVerifiedStatusRequest'),
    updateProductVerifiedStatusSuccess:createAction('model/updateProductVerifiedStatusSuccess'),
    updateProductVerifiedStatusError:createAction('model/updateProductVerifiedStatusError'),

    getOrdersDeliveryRequest:createAction('model/getOrdersDeliveryRequest'),
    getOrdersDeliverySuccess:createAction('model/getOrdersDeliverySuccess'),
    getOrdersDeliveryError:createAction('model/getOrdersDeliveryError'),

    setCompleteOrdersDeliveryRequest:createAction('model/setCompleteOrdersDeliveryRequest'),
    setCompleteOrdersDeliverySuccess:createAction('model/setCompleteOrdersDeliverySuccess'),
    setCompleteOrdersDeliveryError:createAction('model/setCompleteOrdersDeliveryError'),

    setRefundOrdersDeliveryRequest:createAction('model/setRefundOrdersDeliveryRequest'),
    setRefundOrdersDeliverySuccess:createAction('model/setRefundOrdersDeliverySuccess'),
    setRefundOrdersDeliveryError:createAction('model/setRefundOrdersDeliveryError'),

    removeProductCategoryMarketplaceByIdRequest:createAction('model/removeProductCategoryMarketplaceByIdRequest'),
    removeProductCategoryMarketplaceByIdSuccess:createAction('model/removeProductCategoryMarketplaceByIdSuccess'),
    removeProductCategoryMarketplaceByIdError:createAction('model/removeProductCategoryMarketplaceByIdError'),

    getLegalInfoTypesRequest:createAction('model/getLegalInfoTypesRequest'),
    getLegalInfoTypesSuccess:createAction('model/getLegalInfoTypesSuccess'),
    getLegalInfoTypesError:createAction('model/getLegalInfoTypesError'),

    getLegalInfoTitleRequest:createAction('model/getLegalInfoTitleRequest'),
    getLegalInfoTitleSuccess:createAction('model/getLegalInfoTitleSuccess'),
    getLegalInfoTitleError:createAction('model/getLegalInfoTitleError'),

    getGlobalLegalInfoTitleRequest:createAction('model/getGlobalLegalInfoTitleRequest'),
    getGlobalLegalInfoTitleSuccess:createAction('model/getGlobalLegalInfoTitleSuccess'),
    getGlobalLegalInfoTitleError:createAction('model/getGlobalLegalInfoTitleError'),

    getVendorsBannersAdsRequest:createAction('model/getVendorsBannersAdsRequest'),
    getVendorsBannersAdsSuccess:createAction('model/getVendorsBannersAdsSuccess'),
    getVendorsBannersAdsError:createAction('model/getVendorsBannersAdsError'),

    getVendorBannerImageRequest:createAction('model/getVendorBannerImageRequest'),
    getVendorBannerImageSuccess:createAction('model/getVendorBannerImageSuccess'),
    getVendorBannerImageError:createAction('model/getVendorBannerImageError'),

    updateVendorBannerImageRequest:createAction('model/updateVendorBannerImageRequest'),
    updateVendorBannerImageSuccess:createAction('model/updateVendorBannerImageSuccess'),
    updateVendorBannerImageError:createAction('model/updateVendorBannerImageError'),

    updateVendorBannerStatusRequest:createAction('model/updateVendorBannerStatusRequest'),
    updateVendorBannerStatusSuccess:createAction('model/updateVendorBannerStatusSuccess'),
    updateVendorBannerStatusError:createAction('model/updateVendorBannerStatusError'),

    getVendorBannerTextRequest:createAction('model/getVendorBannerTextRequest'),
    getVendorBannerTextSuccess:createAction('model/getVendorBannerTextSuccess'),
    getVendorBannerTextError:createAction('model/getVendorBannerTextError'),

    updateVendorBannerTextRequest:createAction('model/updateVendorBannerTextRequest'),
    updateVendorBannerTextSuccess:createAction('model/updateVendorBannerTextSuccess'),
    updateVendorBannerTextError:createAction('model/updateVendorBannerTextError'),

    getProductIssueListRequest:createAction('model/getProductIssueListRequest'),
    getProductIssueListSuccess:createAction('model/getProductIssueListSuccess'),
    getProductIssueListError:createAction('model/getProductIssueListError'),

    productCloseIssueRequest:createAction('model/productCloseIssueRequest'),
    productCloseIssueSuccess:createAction('model/productCloseIssueSuccess'),
    productCloseIssueError:createAction('model/productCloseIssueError'),

    getFeedbackListRequest:createAction('model/getFeedbackListRequest'),
    getFeedbackListSuccess:createAction('model/getFeedbackListSuccess'),
    getFeedbackListError:createAction('model/getFeedbackListError'),

    removeFeedbackRequest:createAction('model/removeFeedbackRequest'),
    removeFeedbackSuccess:createAction('model/removeFeedbackSuccess'),
    removeFeedbackError:createAction('model/removeFeedbackError'),

    removePartnerSaitSettingsRequest,
    removePartnerSaitSettingsSuccess,
    removePartnerSaitSettingsError,
    
    createUpdatePartnerSaitSettingsRequest,
    createUpdatePartnerSaitSettingsSuccess,
    createUpdatePartnerSaitSettingsError,

    getPartnerByIdSaitSettingsRequest,
    getPartnerByIdSaitSettingsSuccess,
    getPartnerByIdSaitSettingsError,

    getPartnersSaitSettingsRequest,
    getPartnersSaitSettingsSuccess,
    getPartnersSaitSettingsError,

    updateContactsAndSocialSaitSettingsRequest,
    updateContactsAndSocialSaitSettingsSuccess,
    updateContactsAndSocialSaitSettingsError,

    getContactsAndSocialSaitSettingsRequest,
    getContactsAndSocialSaitSettingsSuccess,
    getContactsAndSocialSaitSettingsError,

    getSaitSettingsListRequest,
    getSaitSettingsListSuccess,
    getSaitSettingsListError,

    createSaitRequest,
    createSaitSuccess,
    createSaitError,

    updateMainSaitSettingsRequest,
    updateMainSaitSettingsSuccess,
    updateMainSaitSettingsError,

    getMainSaitSettingsRequest,
    getMainSaitSettingsSuccess,
    getMainSaitSettingsError,

    getVisibleGroupNameRequest,
    getVisibleGroupNameSuccess,
    getVisibleGroupNameError,
    
    updateVisibleGroupNameRequest,
    updateVisibleGroupNameSuccess,
    updateVisibleGroupNameError,

    getWidgetRecordsRequest,
    getWidgetRecordsSuccess,
    getWidgetRecordsError,
    
    clearErrorText,
    getAllProductsInStockRequest,
    getAllProductsInStockSuccess,
    getAllProductsInStockError,

    getFeedbackListRequest,
    getFeedbackListSuccess,
    getFeedbackListError,
    
    getFeedbackShortInfoRequest,
    getFeedbackShortInfoSuccess,
    getFeedbackShortInfoError,

    drugAndDropCatalogsRequest,
    drugAndDropCatalogsSuccess,
    drugAndDropCatalogsError,

    getNotLinkedCatalogsRequest,
    getNotLinkedCatalogsSuccess,
    getNotLinkedCatalogsError,

    getLinkedCatalogsRequest,
    getLinkedCatalogsSuccess,
    getLinkedCatalogsError,

    linkUnlinkSpecialProductsForProductRequest,
    linkUnlinkSpecialProductsForProductSuccess,
    linkUnlinkSpecialProductsForProductError,

    getSpecialProductsNotLinkedProductRequest,
    getSpecialProductsNotLinkedProductSuccess,
    getSpecialProductsNotLinkedProductError,

    getSpecialProductsForProductRequest,
    getSpecialProductsForProductSuccess,
    getSpecialProductsForProductError,

    dragAndDropItemListLinkGroupRequest,
    dragAndDropItemListLinkGroupSuccess,
    dragAndDropItemListLinkGroupError,

    dragAndDropSpecialProductsForProductRequest,
    dragAndDropSpecialProductsForProductSuccess,
    dragAndDropSpecialProductsForProductError,

    getItemListNotLinkGroupRequest,
    getItemListNotLinkGroupSuccess,
    getItemListNotLinkGroupError,

    getItemListLinkGroupRequest,
    getItemListLinkGroupSuccess,
    getItemListLinkGroupError,

    removeManualMeasureRequest,
    removeManualMeasureSuccess,
    removeManualMeasureError,

    getMeasureByIdRequest,
    getMeasureByIdSuccess,
    getMeasureByIdError,

    createUpdateManualMeasureRequest,
    createUpdateManualMeasureSuccess,
    createUpdateManualMeasureError,

    getMeasuresRequest,
    getMeasuresSuccess,
    getMeasuresError,

    removePricingAttributeByProductRequest,
    removePricingAttributeByProductSuccess,
    removePricingAttributeByProductError,

    addPricingAttributeByProductRequest,
    addPricingAttributeByProductSuccess,
    addPricingAttributeByProductError,

    notLinkedPricingAttributesByProductRequest,
    notLinkedPricingAttributesByProductSuccess,
    notLinkedPricingAttributesByProductError,

    changePricingOptionsFlagRequest,
    changePricingOptionsFlagSuccess,
    changePricingOptionsFlagError,

    getPricingAttributesByProductRequest,
    getPricingAttributesByProductSuccess,
    getPricingAttributesByProductError,

    dragAndDropPropertiesWidgetByProductRequest,
    dragAndDropPropertiesWidgetByProductSuccess,
    dragAndDropPropertiesWidgetByProductError,
    
    getAttributesByGroupAndProductRequest,
    getAttributesByGroupAndProductSuccess,
    getAttributesByGroupAndProductError,

    addRemovePropertiesWidgetByProductRequest,
    addRemovePropertiesWidgetByProductSuccess,
    addRemovePropertiesWidgetByProductError,

    updateProductCategoryRequest,
    updateProductCategorySuccess,
    updateProductCategoryError,

    getProductCategoriesRequest,
    getProductCategoriesSuccess,
    getProductCategoriesError,

    removeAttributeByProductRequest,
    removeAttributeByProductSuccess,
    removeAttributeByProductError,

    getWidgetsNoAttributesProductRequest,
    getWidgetsNoAttributesProductSuccess,
    getWidgetsNoAttributesProductError,

    addAttributeByProductRequest,
    addAttributeByProductSuccess,
    addAttributeByProductError,

    getAttributesByProductRequest,
    getAttributesByProductSuccess,
    getAttributesByProductError,

    portalRemoveProductByIdRequest,
    portalRemoveProductByIdSuccess,
    portalRemoveProductByIdError,

    getProductFromLuceneRequest,
    getProductFromLuceneSuccess,
    getProductFromLuceneError,
    
    updateSpecialPriceProductOrOptionRequest,
    updateSpecialPriceProductOrOptionSuccess,
    updateSpecialPriceProductOrOptionError,
    
    removeImageFromGalleryRequest,
    removeImageFromGallerySuccess,
    removeImageFromGalleryError,

    getMovementProductHistoryRequest,
    getMovementProductHistorySuccess,
    getMovementProductHistoryError, 

    getLocationsWithProductsQtyRequest,
    getLocationsWithProductsQtySuccess,
    getLocationsWithProductsQtyError,

    moveProductToLocationRequest,
    moveProductToLocationSuccess,
    moveProductToLocationError,

    addProductBarcodeRequest,
    addProductBarcodeSuccess,
    addProductBarcodeError,

    getProductBarcodesListRequest,
    getProductBarcodesListSuccess,
    getProductBarcodesListError,

    initProductInLocationRequest,
    initProductInLocationSuccess,
    initProductInLocationError,

    getProductsInStockNotLocationRequest,
    getProductsInStockNotLocationSuccess,
    getProductsInStockNotLocationError,

    getLocationShortListRequest,
    getLocationShortListSuccess,
    getLocationShortListError,

    getProductsInStockRequest,
    getProductsInStockSuccess,
    getProductsInStockError, 

    linkUnlinkWidgetToCatalogRequest,
    linkUnlinkWidgetToCatalogSuccess,
    linkUnlinkWidgetToCatalogError,

    getCatalogsListRequest,
    getCatalogsListSuccess,
    getCatalogsListError,

    getWidgetsListNotCatalogRequest,
    getWidgetsListNotCatalogSuccess,
    getWidgetsListNotCatalogError,

    getLocationByIdRequest,
    getLocationByIdSuccess,
    getLocationByIdError, 

    getLocationsRequest,
    getLocationsSuccess,
    getLocationsError,

    createUpdateLocationRequest,
    createUpdateLocationSuccess,
    createUpdateLocationError,

    updatePriceProductOrOptionRequest,
    updatePriceProductOrOptionSuccess,
    updatePriceProductOrOptionError,

    copyPriceToOptionsRequest,
    copyPriceToOptionsSuccess,
    copyPriceToOptionsError,

    getProductListWithOptionsAndPricesRequest,
    getProductListWithOptionsAndPricesSuccess,
    getProductListWithOptionsAndPricesError,

    updateGalleryFlagProductOrOptionRequest,
    updateGalleryFlagProductOrOptionSuccess,
    updateGalleryFlagProductOrOptionError,
    
    getProductListWithOptionsRequest,
    getProductListWithOptionsSuccess,
    getProductListWithOptionsError,

    removePropertiesWidgetByProductRequest,
    removePropertiesWidgetByProductSuccess,
    removePropertiesWidgetByProductError,
    
    addPropertiesWidgetByProductRequest,
    addPropertiesWidgetByProductSuccess,
    addPropertiesWidgetByProductError,

    getWidgetsNoPropertiesProductRequest,
    getWidgetsNoPropertiesProductSuccess,
    getWidgetsNoPropertiesProductError,

    getPropertiesByProductRequest,
    getPropertiesByProductSuccess,
    getPropertiesByProductError,

    getWidgetTypesRequest,
    getWidgetTypesSuccess,
    getWidgetTypesError,

    updateItemStartPricingRequest,
    updateItemStartPricingSuccess,
    updateItemStartPricingError,
    
    updateItemTypePricingRequest,
    updateItemTypePricingSuccess,
    updateItemTypePricingError,

    updateItemPackingPricingRequest,
    updateItemPackingPricingSuccess,
    updateItemPackingPricingError,
    updateItemPackingPricingClear,

    convertPriceRequest,
    convertPriceSuccess,
    convertPriceError,

    enableDisablePackagesPricingRequest,
    enableDisablePackagesPricingSuccess,
    enableDisablePackagesPricingError,

    updateItemConvertationWidgetRequest,
    updateItemConvertationWidgetSuccess,
    updateItemConvertationWidgetError,

    updateItemPackingWidgetRequest,
    updateItemPackingWidgetSuccess,
    updateItemPackingWidgetError,

    removeWidgetRecordRequest,
    removeWidgetRecordSuccess,
    removeWidgetRecordError,

    updateItemPricingRequest,
    updateItemPricingSuccess,
    updateItemPricingError,
    
    getItemsPricingRequest,
    getItemsPricingSuccess,
    getItemsPricingError,

    updateItemPricingWidgetRequest,
    updateItemPricingWidgetSuccess,
    updateItemPricingWidgetError,

    getItemWidgetsPricingRequest,
    getItemWidgetsPricingSuccess,
    getItemWidgetsPricingError,

    getSchemeByItemListRequest,
    getSchemeByItemListSuccess,
    getSchemeByItemListError,

    updateItemPropertiesRequest,
    updateItemPropertiesSuccess,
    updateItemPropertiesError,

    getSchemeByProductRequest,
    getSchemeByProductSuccess,
    getSchemeByProductError,

    linkProductToWidgetSchemeRequest,
    linkProductToWidgetSchemeSuccess,
    linkProductToWidgetSchemeError,

    updateWidgetLevelInWidgetSchemeRequest,
    updateWidgetLevelInWidgetSchemeSuccess,
    updateWidgetLevelInWidgetSchemeError,

    getLinkedWidgetsRequest,
    getLinkedWidgetsSuccess,
    getLinkedWidgetsError,

    linkWidgetToWidgetSchemeRequest,
    linkWidgetToWidgetSchemeSuccess,
    linkWidgetToWidgetSchemeError,

    getWidgetsNoSchemaWithTypeRequest,
    getWidgetsNoSchemaWithTypeSuccess,
    getWidgetsNoSchemaWithTypeError,

    getWidgetGroupTypeRequest,
    getWidgetGroupTypeSuccess,
    getWidgetGroupTypeError,

    getItemGroupByIdRequest,
    getItemGroupByIdSuccess,
    getItemGroupByIdError,

    changeLevelItemInGroupRequest,
    changeLevelItemInGroupSuccess,
    changeLevelItemInGroupError,

    createUpdateWidgetRecordRequest,
    createUpdateWidgetRecordSuccess,
    createUpdateWidgetRecordError,

    getWidgetRecordByIdRequest,
    getWidgetRecordByIdSuccess,
    getWidgetRecordByIdError,

    getWidgetRecordListRequest,
    getWidgetRecordListSuccess,
    getWidgetRecordListError,

    createUpdateItemGroupRequest,
    createUpdateItemGroupSuccess,
    createUpdateItemGroupError,

    GetItemGroupTypesRequest,
    GetItemGroupTypesSuccess,
    GetItemGroupTypesError,

    getWidgetConvertationListRequest,
    getWidgetConvertationListSuccess,
    getWidgetConvertationListError,

    removeWidgetRequest,
    removeWidgetSuccess,
    removeWidgetError,

    linkUnlinkItemGroupRequest,
    linkUnlinkItemGroupSuccess,
    linkUnlinkItemGroupError,

    getItemListSortGroupRequest,
    getItemListSortGroupSuccess,
    getItemListSortGroupError,

    remoevItemGroupRequest,
    remoevItemGroupSuccess,
    remoevItemGroupError,

    createItemGroupRequest,
    createItemGroupSuccess,
    createItemGroupError,

    getItemGroupListRequest,
    getItemGroupListSuccess,
    getItemGroupListError,

    createUpadetWidgetRequest,
    createUpadetWidgetSuccess,
    createUpadetWidgetError,

    getWidgetByIdRequest,
    getWidgetByIdSuccess,
    getWidgetByIdError,

    updateWidgetActiveRequest,
    updateWidgetActiveSuccess,
    updateWidgetActiveError,

    createUpdateGallaryRequest,
    createUpdateGallarySuccess,
    createUpdateGallaryError,

    getGallaryByIDRequest,
    getGallaryByIDSuccess,
    getGallaryByIDError,

    getGallariesListWidgetQtyRequest,
    getGallariesListWidgetQtySuccess,
    getGallariesListWidgetQtyError,

    createUpdateItemRequest,
    createUpdateItemSuccess,
    createUpdateItemError,

    getItemByIdRequest,
    getItemByIdSuccess,
    getItemByIdError,

    updateItemStatusRequest,
    updateItemStatusSuccess,
    updateItemStatusError,

    getItemsListWidgetRequest,
    getItemsListWidgetSuccess,
    getItemsListWidgetError,

    getWidgetListRequest,
    getWidgetListSuccess,
    getWidgetListError,

    removeKeywordRequest,
    removeKeywordSuccess,
    removeKeywordError,

    createUpdateKeyRequest,
    createUpdateKeySuccess,
    createUpdateKeyError,

    getKeywordRequest,
    getKeywordSuccess,
    getKeywordError,

    getKeywordsRequest,
    getKeywordsSuccess,
    getKeywordsError,

    createUpdateKeywordRequest,
    createUpdateKeywordSuccess,
    createUpdateKeywordError,


    getKeywordLinkRequest,
    getKeywordLinkSuccess,
    getKeywordLinkError,

    getWidgetSchemeWithWidgetsRequest,
    getWidgetSchemeWithWidgetsSuccess,
    getWidgetSchemeWithWidgetsError,

    getWidgetSchemeByIdRequest,
    getWidgetSchemeByIdSuccess,
    getWidgetSchemeByIdError,

    createUpdateWidgetSchemeRequest,
    createUpdateWidgetSchemeSuccess,
    createUpdateWidgetSchemeError,

    getWidgetsSchemeRequest,
    getWidgetsSchemeSuccess,
    getWidgetsSchemeError,

    searchGetLinkRequest,
    searchGetLinkSuccess,
    searchGetLinkError,

    searchGetRequestsRequest,
    searchGetRequestsSuccess,
    searchGetRequestsError,

    removeTranslatorRequest,
    removeTranslatorSuccess,
    removeTranslatorError,

    updateTranslatorRequest,
    updateTranslatorSuccess,
    updateTranslatorError,

    getAppPagesListRequest,
    getAppPagesListSuccess,
    getAppPagesListError,

    getSaitListRequest,
    getSaitListSuccess,
    getSaitListError,

    getTranslatorRequest,
    getTranslatorSuccess,
    getTranslatorError,

    updatePageRequest,
    updatePageSuccess,
    updatePageError,

    loader
}