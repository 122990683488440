import { Fragment, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { modelOperations } from "../../redux/model"
import Popup from "../Popup/Popup"
import PopupEditKey from './PopupEditKey';
import PopupPayoutRequest from "./PopupPayoutRequest";
import PopupReload from './PopupReload';
import upload from './../../modules/upload'

import {WEB_PICTURES_FILE, PREVIEW_URL, WEB_PICTURES_FILE_RESIZER} from './../../config/Url'
import PopupHistory from "./PopupHistory";

import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';

const PopupVendorCard = ({
    lang,
    closePopup,
    errCode,
    onGetUserBlockchainAddress,
    getUserBlockchainAddress,
    onGetVendorInfo,
    getVendorInfo,
    onUpdateVendorImage,
    updateVendorImage,
    onUpdateVendorNameAndCountry,
    // onGetPayoutMethods,
    // getPayoutMethods
}) => {

    const refImgCrop = useRef();
    const [fileList, setFileList] = useState([]);
    const [popupEditKey, setPopupEditKey] = useState(false);
    const [popupReload, setPopupReload] = useState(false);
    const [popupPayoutRequest, setPopupPayoutRequest] = useState(false);
    const [popupVendor, setPopupVendor] = useState(false);
    const [popupHistory, setPopupHistory] = useState(false);
    // const [key, setKey] = useState('')
    // const [use, setUse] = useState(0)

    const [name, setName] = useState('')
    const [country, setCountry] = useState('')

    useEffect(()=>{
        // onGetUserBlockchainAddress({DEFAULT_COLUMN:lang})
        onGetVendorInfo({DEFAULT_COLUMN:lang})
        // onGetPayoutMethods({DEFAULT_COLUMN:lang})
    }, [])

    useEffect(()=>{
        if(errCode === 1){
            // onGetUserBlockchainAddress({DEFAULT_COLUMN:lang})
            onGetVendorInfo({DEFAULT_COLUMN:lang})
            // onGetPayoutMethods({DEFAULT_COLUMN:lang})


            setPopupVendor(false)
        }
    }, [errCode])

    // useEffect(()=>{


    //     if(Object.keys(getUserBlockchainAddress).length !== 0){
    //         const {USED_ADDRESS_TYPE, INTERNAL_ADDRESS, EXTERNAL_ADDRESS} = getUserBlockchainAddress
    //         setKey(USED_ADDRESS_TYPE === 0 ? INTERNAL_ADDRESS : EXTERNAL_ADDRESS)
    //         setUse(USED_ADDRESS_TYPE)
    //     }

    // }, [getUserBlockchainAddress])

    useEffect(()=>{
        if(Object.keys(getVendorInfo).length !== 0){
            const {COUNTRY_CODE, PROFILE_NAME, PROFILE_IMAGE} = getVendorInfo
            setName(PROFILE_NAME)
            setCountry(COUNTRY_CODE)

            setFileList([
                {
                    uid: '-1',
                    url: `${WEB_PICTURES_FILE_RESIZER}${PROFILE_IMAGE}/240`,
                  },
            ])
        }
    }, [getVendorInfo])

    const handleChangeFileId = (e) =>{
        const files = e.target.files

        console.log(e.target.files);

        upload(files, ({uuid})=>{
            onUpdateVendorImage({PROFILE_IMAGE:uuid})
        })
    }

    const save = () => {
        onUpdateVendorNameAndCountry({
            PROFILE_NAME:name, //- название вендора
            COUNTRY_CODE:country // - код страны вендора
        })
    }

    const onChange = ({ fileList: newFileList }) => {
        console.log({newFileList});
        setFileList(newFileList);

        if(newFileList[0]?.originFileObj){

            let list = new DataTransfer();
            list.items.add(newFileList[0]?.originFileObj);
            // console.log(newFileList[0]?.originFileObj);
            upload(list.files, ({uuid})=>{
                onUpdateVendorImage({PROFILE_IMAGE:uuid})
            })
        }
    };
    const onPreview = async (file) => {

        console.log({file});

        let src = file.url;
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
    
        if (imgWindow) {
          imgWindow.document.write(image.outerHTML);
        } else {
          window.location.href = src;
        }
    };

    const clickImgCrop = () => {
        console.log(refImgCrop.current);
    }

    return (<>
        <Popup closePopup={closePopup} title="Vendor card">
            <label className="vendorUpload" hidden>
                <input type="file" onChange={e=>handleChangeFileId(e)} /> 
                {getVendorInfo?.PROFILE_IMAGE !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${getVendorInfo?.PROFILE_IMAGE}/240`} style={{width: '100%', height: '100%', objectFit: 'contain'}} /> }
                {getVendorInfo?.PROFILE_IMAGE === '' && `Click to upload vendor logo` }
            </label>

            <div className="imgCrop1" style={{width:208, padding:0, maxWidth:208, paddingRight: 12, margin: '0 auto'}}>
                <ImgCrop grid  >
                    <Upload
                        ref={refImgCrop}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={state => onChange(state, 1)}
                        onPreview={onPreview}
                    >
                        {fileList.length < 1 && 
                            <label className="productFileUpload" onClick={()=>clickImgCrop()}>
                                <p>Click to upload vendor logo</p>
                            </label>
                        }
                    </Upload>
                </ImgCrop>
            </div>

            <div className="text-center">{getVendorInfo?.PROFILE_NAME} { getVendorInfo?.COUNTRY_CODE} <i className="fa fa-pencil" onClick={()=>setPopupVendor(true)} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} /> </div>
            <br/>
            <br/>
            <h3 className="text-center">Product collection address</h3>
            <p  className="text-center">{getVendorInfo?.USED_ADDRESS} ({getVendorInfo?.USED_ADDRESS_TYPE === 0 ? 'internal' : 'external'}) <i onClick={()=>setPopupEditKey(true)} className="fa fa-pencil" style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} /> </p>
            <div className="text-center">
                <div className="divss" >{getVendorInfo?.PRODUCTS_QTY} products</div>
            </div>

            <br/>
            <br/>
            {/* <h3 className="text-center">Balance</h3>
            <p  className="text-center">{getVendorInfo?.VENDOR_ADDRESS} </p>

            <div className="text-center">
                <div className="divss">{ Number((getVendorInfo?.BALANCE ).toFixed(2)) } {getVendorInfo?.CURRENCY} <br/> ({ Number((getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS ).toFixed(2)) } Hiiroad coins) </div>
            </div> */}

            {/* <h3 className="text-center">Balance</h3>
            <p  className="text-center">{getVendorInfo?.VENDOR_ADDRESS} </p>

            <div className="text-center">
                <div className="divss">{ Number((getVendorInfo?.BALANCE ).toFixed(2)) } {getVendorInfo?.CURRENCY} <br/> ({ Number((getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS ).toFixed(2)) } Hiiroad coins) </div>
            </div> */}

            {
                getVendorInfo?.BALANCES?.filter(({TYPE_INDEX})=>[2, 3].indexOf(TYPE_INDEX) > -1).map(({TYPE_INDEX, BALANCE}, k)=>{

                    const balance = Number(BALANCE.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === getVendorInfo?.CURRENCY).map(({PRICE})=>PRICE).join(''))
                    const balanceToken = Number(BALANCE.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>PRICE).join(''))

                    return(<Fragment key={k}>

                         <h3 className="text-center">
                                {TYPE_INDEX === 2 && 'Reward balance:'}
                                {TYPE_INDEX === 3 && 'Payout balance:'}
                         </h3>
                         <h4 className="text-center">Balance</h4>
                        <p  className="text-center">
                            {TYPE_INDEX === 2 && getVendorInfo?.LOYALTY_ADDRESS} 
                            {TYPE_INDEX === 3 && getVendorInfo?.VENDOR_ADDRESS} 
                        </p>

                        <div className="text-center">
                            <div className="divss">
                                <div style={{lineHeight: "8px", textAlign: 'center',display: 'flex', alignItems: 'center'}}> <i className='hiiroad'></i> <b style={{marginRight:6}}>{balanceToken}</b>({ balance } {getVendorInfo?.CURRENCY})</div>
                            </div>
                            {/* <div className="divss">{ Number((getVendorInfo?.BALANCE ).toFixed(2)) } {getVendorInfo?.CURRENCY} <br/> ({ Number((getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS ).toFixed(2)) } Hiiroad coins) </div> */}
                        </div>
                        {TYPE_INDEX === 2 &&<>
                            <br/>
                             <div className="text-center">
                                <button type="button" className="btn btn-primary" onClick={()=>setPopupReload(true)} >Reload </button>
                            </div>
                        </>}
                        <br/>
                    </Fragment>)   
                })
            }

            <br/>
            <div className="text-center">
                {/* <button type="button" className="btn btn-primary" onClick={()=>setPopupReload(true)} >Invest </button> */}
                <button type="button" className="btn btn-primary" onClick={()=>setPopupPayoutRequest(true)} style={{marginLeft:12}}  >Payout </button>
                <button type="button" className="btn btn-primary" onClick={()=>setPopupHistory(true)} style={{marginLeft:12}} >History </button>
            </div>
            <p className="text-center" >minimum balance 10 Hiiroad coins</p>
        
        </Popup>
        {popupVendor && <Popup>
            <div className="form-group gr_f">
                <label  htmlFor="input_112" style={{width: '20%',textAlign: 'right'}}>Name</label>
                <input type="name" className="form-control" value={name} onChange={({target:{value}}) => setName(value)} /> 
            </div>
            <div className="form-group gr_f">
                <label  htmlFor="input_112" style={{width: '20%',textAlign: 'right'}}>Country</label>
                <select className="form-control" value={country} onChange={({target:{value}}) => setCountry(value)} >
                    <option value="CA">Canada</option>
                    <option value="US">USA</option>
                    <option value="UA">Ukraine</option>
                </select>
            </div>

                <div className="text-center">
                    <button type="button" className="btn btn-primary" onClick={()=>save()} > Save </button>
                    <button type="button" className="btn btn-danger" style={{marginLeft:12}} onClick={() => setPopupVendor(false)} >Close </button>
                </div>
            </Popup>}
        {popupEditKey && <PopupEditKey closePopup={()=>setPopupEditKey(false)} />}
        {popupReload && <PopupReload closePopup={()=>setPopupReload(false)} />}
        {popupPayoutRequest && <PopupPayoutRequest closePopup={()=>setPopupPayoutRequest(false)} />}
        {popupHistory && <PopupHistory closePopup={()=>setPopupHistory(false)} />}
        </>
    ) 
}
const mapStateToPorps = (state) => ({
    // isAuthenticated:authSelectors.getIsAuthenticated(state),
    // fullName:authSelectors.getAdminName(state),
    lang:state.lang.LANG,
    // balance:state.auth.balance,
    errCode:state.model.err_code,
    getUserBlockchainAddress:state.model.getUserBlockchainAddress,
    getVendorInfo:state.model.getVendorInfo,
    updateVendorImage:state.model.updateVendorImage,
    // getPayoutMethods:state.model.getPayoutMethods,

})

const mapDispatchToProps = {
    // onLogout:authOperations.logout,
    // updateLang:langActions.updateLang,
    onUpdateVendorNameAndCountry:modelOperations.updateVendorNameAndCountry,
    onGetUserBlockchainAddress:modelOperations.getUserBlockchainAddress,
    onGetVendorInfo:modelOperations.getVendorInfo,
    onUpdateVendorImage:modelOperations.updateVendorImage,
    // onGetPayoutMethods:modelOperations.getPayoutMethods,

}

export default connect(mapStateToPorps, mapDispatchToProps)(PopupVendorCard);
