

const Footer = () => {
    return (
        <footer id="footer">
            <a href="/"> Hiiroad</a> © 2021 <br/> Version 0.1
        </footer>
    )
}

export default Footer;