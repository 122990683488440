import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import {authSelectors} from './../redux/auth'

// import Header from './Header';
// import Footer from './Footer';
// import Nav from './Nav';
// import LoaderRequest from "./LoaderRequest/LoaderRequest";
// import { modelSelectors } from "../redux/model";

// import './../app/stylesheet/bootstrap.css'
// import './../app/javascript/font-awesome/css/font-awesome.min.css'
// import './../app/javascript/jquery/datetimepicker/bootstrap-datetimepicker.min.css'
// import './../app/stylesheet/stylesheet.css'
// import './../style.css'



import MainLayoutPrivate from "./MainLayoutPrivate";



// function MainLayout({ children }) {
//     const isAuthenticated = useSelector(authSelectors.getIsAuthenticated)
//     const loader = useSelector(modelSelectors.getLoader)
//     return(
//       <>
//         <Header />
//         {isAuthenticated && <Nav />}
//         <div id="content">{children}</div>
//         <Footer />

//         {loader && <LoaderRequest />}
//       </>
    
//     );
//   }; 

const PrivateRoute = ({component:Component, redirectTo, ...routeProps}) =>{
    const isAuthenticated = useSelector(authSelectors.getIsAuthenticated)
   
    return <Route 
        {...routeProps} 
        render={props => 
            isAuthenticated ? (<MainLayoutPrivate><Component {...props} /></MainLayoutPrivate>) : <Redirect to={redirectTo} />
        }
    />
}

PrivateRoute.defaultProps = {
    redirectTo:'/'
}



export default PrivateRoute