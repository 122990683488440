import { connect } from "react-redux";
// import { userPrivateActions, userPrivateOperations } from "../../redux/userPrivate";
import { useCallback, useEffect, useRef, useState } from "react"
import Popup from "../../components/Popup/Popup"
// import {DOMAIN} from './../../config/Url'

import { loadStripe } from "@stripe/stripe-js";
import { Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useHistory } from "react-router-dom";
import { modelOperations } from "../../redux/model";
// import { modelOperations } from "../../redux/model";

// const promise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
// const promise = loadStripe("pk_test_51JDp99IGDOo9yI9XWjbZvyYtmDbfqzdKiQStFXyABy1TyZXVzvxVq04rAYlwRoUa6D6WQAh7ixl10rRzdZztwXld00AEG37RjJ");


const PopupStrip = ({
    lang,
    closePopup,
    setErrorCode,
    onGetUserStripToken,
    getUserStripToken,
    onGetUserStripClientSecret,
    getUserStripClientSecret,
    orderId,
    onUserTransactionStrip,
    userTransactionStrip,
    payment,
    currency,
    total,

    onGetStripToken,
    getStripToken,
    onGetStripClientSecret,
    getStripClientSecret,
    onTransactionBuyCoinsStrip,
    errCode
}) => { 

    const [promise, setPromise] = useState('')
    const [clientSecret, setClientSecret] = useState('');
    const [payload, setPayload] = useState({});
    const history = useHistory();

    const [flag, setFlag] = useState(0)

    useEffect(()=>{

        onGetStripToken({DEFAULT_COLUMN:lang})
        onGetStripClientSecret({
            DEFAULT_COLUMN:lang,
            RATE_SHORT_NAME:currency,
            PRICE:total
        })

        // onGetUserStripToken({DOMAIN})
        // onGetUserStripClientSecret({DOMAIN, ID_ORDER:orderId})
        // setErrorCode(0)
    }, [])

    useEffect(()=>{
        if(getStripToken !== ''){
            setPromise(loadStripe(getStripToken, {locale:'en'}))
        }
    }, [getStripToken])

    useEffect(()=>{
        console.log({getStripClientSecret});
        if(getStripClientSecret !== ''){
            setClientSecret(getStripClientSecret)
        }
    }, [getStripClientSecret])

    useEffect(()=>{
        if(payload.error){

        }else if(payload?.paymentIntent?.status === "succeeded"){
            // onUserTransactionStrip({DOMAIN, ID_ORDER:orderId})
            onTransactionBuyCoinsStrip({
                DEFAULT_COLUMN:lang,
                RATE_SHORT_NAME:currency,
                PRICE:total
            })
        }
    }, [payload])

    // useEffect(()=>{
    //     if(userTransactionStrip === 1){
    //         history.push({
    //             pathname: '/thanks',
    //             search: `?record=${payment?.ID_RECORD}`,
    //         });
    //     }
    // }, [userTransactionStrip])

    useEffect(()=>{
        if(errCode === 1 && flag === 1){
             closePopup()
            setFlag(0)    
        }
    }, [errCode, flag])

    return (
        <Popup closePopup={()=>{closePopup()}} width={700} >
            <div style={{padding:12}}>
                {promise !== '' && clientSecret !== '' && <Elements stripe={promise}>
                    <CheckoutForm ClientSecret={clientSecret} onPayload = {state => setPayload(state)} onFlag={flag => setFlag(flag)} />
                </Elements>}
            </div>
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.lang.LANG,
    errCode:state.model.err_code,
    // rate:state.auth.rate,
    // errCode:state.userPrivate.err_code,
    // getUserStripToken:state.userPrivate.getUserStripToken,
    // getUserStripClientSecret:state.userPrivate.getUserStripClientSecret,
    getStripToken:state.model.getStripToken,
    getStripClientSecret:state.model.getStripClientSecret,
})

const mapDispatchToProps = {
    // onGetUserStripToken:userPrivateOperations.getUserStripToken,
    // onGetUserStripClientSecret:userPrivateOperations.getUserStripClientSecret,
    // onUserTransactionStrip:userPrivateOperations.userTransactionStrip,
    // setErrorCode:userPrivateActions.setErrorCode,
    onGetStripToken:modelOperations.getStripToken,
    onGetStripClientSecret:modelOperations.getStripClientSecret,
    onTransactionBuyCoinsStrip:modelOperations.transactionBuyCoinsStrip,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupStrip);