import Navigation from './../Navigation'

const Nav = () =>{
    return(
        // <nav id="column-left">
        //     <Navigation />
        // </nav>

        <nav style={{display: 'flex', width: '100%', backgroundColor: '#242d37'}}>
            <Navigation />
        </nav>
    )
}
export default Nav