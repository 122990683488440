import React, { lazy, Suspense, useEffect } from 'react';
import {Route, Switch } from 'react-router-dom';

import PublicRoute from './../components/PublicRoute';
import PrivateRoute from './../components/PrivateRoute';
// import ViewRoute from './../components/ViewRoute';
import DemoRoute from './../components/DemoRoute';

import AuthLayout from './../containers/layouts/AutyhLayout'
import MainLayout from './../containers/layouts/MainLayout'
import AppLayout from './../containers/layouts/AppLayout'

import Loader from './../components/Loader';
// import WebSiteConfiguration from '../view/Admin/WebSiteConfiguration/WebSiteConfiguration';

const Home = lazy (() => import('./../view/Home'))
const Auth = lazy (() => import('./../view/Admin/Auth'))
const ForgetPass = lazy (() => import('./../view/Admin/Auth/ForgottenPassword'))
const SignUp = lazy (() => import('./../view/Admin/Auth/SignUp'))
const NotFound = lazy (() => import('./../view/Admin/NotFound'))
const Translate = lazy (() => import('./../view/Admin/Translate'))
const SEO = lazy (() => import('./../view/Admin/SEO'))
const Products = lazy (() => import('./../view/Admin/Products'))
const ProductsAttributes = lazy (() => import('./../view/Admin/ProductsAttributes'))
const Lists = lazy (() => import('./../view/Admin/Lists'))
const LocationsAndStorages = lazy (() => import('./../view/Admin/LocationsAndStorages'))
const InStock = lazy (() => import('./../view/Admin/InStock'))
const CatalogsandGroups = lazy (() => import('./../view/Admin/CatalogsandGroups'))
const Orders = lazy (() => import('./../view/Admin/Orders'))
const PaymentMethods = lazy (() => import('./../view/Admin/PaymentMethods'))
const Configuration = lazy (() => import('./../view/Admin/Configuration'))
const Clients = lazy (() => import('./../view/Admin/Clients'))
const MarketplaceCatalogs = lazy (() => import('./../view/Admin/MarketplaceCatalogs'))
const NotificationsManager = lazy (() => import('./../view/Admin/NotificationsManager'))

const CatalogsAndGroups_Marketplace = lazy (() => import('./../view/Admin/CatalogsAndGroups_Marketplace'))
const Categories = lazy (() => import('./../view/Admin/Categories'))
const ProductsManagement = lazy (() => import('./../view/Admin/ProductsManagement'))
const VendorsProducts = lazy (() => import('./../view/Admin/VendorsProducts'))
const LoyaltyManagement = lazy (() => import('./../view/Admin/LoyaltyManagement'))
const RatesManagement = lazy (() => import('./../view/Admin/RatesManagement'))
const PaymentsProcessing = lazy (() => import('./../view/Admin/PaymentsProcessing'))
const WebSiteConfiguration = lazy (() => import('./../view/Admin/WebSiteConfiguration'))
const VendorsAndProducts = lazy (() => import('./../view/Admin/VendorsAndProducts'))
const VendorsBanners = lazy (() => import('./../view/Admin/VendorsBanners'))




const Preview = lazy (() => import('./../view/Previews/Preview'))
const Demo = lazy (() => import('./../view/Previews/Demo'))


const router = {
    home:'/',
    auth:'/login',
    login:'/login',
    forgotten:'/forgotten_password',
    signUp:'/sign-up',
    translate:'/translate',
    seo:'/seo',
    lists:'/lists',
    productsAttributes:'/products-attributes',
    products:'/products',
    locationsAndStorages:'/locations-and-storages',
    inStock:'/in-stock',
    catalogsandGroups:'/catalogs-and-groups',
    oredrs:'/orders',
    paymentMethods:'/payment-methods',
    configuration:'/configuration',
    сlients:'/сlients',
    marketplaceCatalogs:'/marketplace-catalogs',
    notificationsManager:'/notifications-manager',
    webSiteConfiguration:'/web-site-configuration',

    catalogsAndGroups_Marketplace:'/catalogs-and-groups_marketplace',
    сatalogs:'/сatalogs',
    productsManagement:'/products-management',
    vendorsProducts:'/vendors-products',
    loyaltyManagement:'/loyalty-management',
    ratesManagement:'/rates-management',
    paymentsProcessing:'/payments-processing',
    vendorsAndProducts:'/vendors-and-products',
    vendorsBanners:'/vendors-banners',

    // preview
    preview:'/preview/:id',
    demo:'/demo/'
}

export default function Routers(){

    useEffect(()=>{
        window.onunload = function() { debugger; }
    }, [])

    return(
     
    <Suspense fallback={<Loader/>}>
      <Switch>
        {/* <PublicRoute exact path={router.home}  component={Home}  /> */}
        <PrivateRoute 
            exact
            path={router.home} 
            // component={Home} 
            component={Products} 
            redirectTo={router.login}
        /> 
        <PublicRoute 
            path={router.auth} 
            component={Auth} 
            restricted
            redirectTo={router.home} 
        />
         <PublicRoute 
            path={router.forgotten} 
            component={ForgetPass} 
            restricted
            redirectTo={router.home} 
        />

        <PublicRoute 
            path={router.signUp} 
            component={SignUp} 
            restricted
            redirectTo={router.home} 
        />

        <PrivateRoute 
            path={router.translate} 
            component={Translate} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.vendorsBanners} 
            component={VendorsBanners} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.seo} 
            component={SEO} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.lists} 
            component={Lists} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.products} 
            component={Products} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.productsAttributes} 
            component={ProductsAttributes} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.locationsAndStorages} 
            component={LocationsAndStorages} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.inStock} 
            component={InStock} 
            redirectTo={router.login}
        />
        <PrivateRoute 
            path={router.catalogsandGroups} 
            component={CatalogsandGroups} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.oredrs} 
            component={Orders} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.paymentMethods} 
            component={PaymentMethods} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.configuration} 
            component={Configuration} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.сlients} 
            component={Clients} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.marketplaceCatalogs} 
            component={MarketplaceCatalogs} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.notificationsManager} 
            component={NotificationsManager} 
            redirectTo={router.login}
        />
        
        <PrivateRoute 
            path={router.catalogsAndGroups_Marketplace} 
            component={CatalogsAndGroups_Marketplace} 
            redirectTo={router.login}
        />


        <PrivateRoute 
            path={router.productsManagement} 
            component={ProductsManagement} 
            redirectTo={router.login}
        />
        <PrivateRoute 
            path={router.vendorsProducts} 
            component={VendorsProducts} 
            redirectTo={router.login}
        />
        <PrivateRoute 
            path={router.loyaltyManagement} 
            component={LoyaltyManagement} 
            redirectTo={router.login}
        />
        <PrivateRoute 
            path={router.ratesManagement} 
            component={RatesManagement} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.paymentsProcessing} 
            component={PaymentsProcessing} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            path={router.webSiteConfiguration} 
            component={WebSiteConfiguration} 
            redirectTo={router.login}
        />

        <PrivateRoute 
            exact
            path={router.vendorsAndProducts} 
            component={VendorsAndProducts} 
            redirectTo={router.login}
        />

       

        <PrivateRoute 
            path={router.catalogs} 
            component={Categories} 
            redirectTo={router.login}
        /> 

        

        {/* ----------------------------------------------- */}

        {/* <ViewRoute 
            path={router.preview} 
            component={Preview} 
        /> */}

        <DemoRoute
            path={router.demo} 
            component={Demo} 
        />

        <AppLayout component={NotFound} />
          

      </Switch>
      </Suspense>
    );
}