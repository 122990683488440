import React, { Component, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Popup.module.css';

const modal = document.querySelector('#modal')


export default function Popup({className, width, type, closePopup, children, title, style={}, titleRight}) {
    
    const [arrStyle, setArrStyle] = useState({})

    useEffect(()=>{
        window.addEventListener('keydown', handler)
        document.body.style.overflow = 'hidden';
        return () => {
            window.removeEventListener('keydown', handler) 
            document.body.style.overflow = 'auto';
        }
    }, [])

    useEffect(()=>{
        if(type === 'top')
        setArrStyle(prevState=>({...prevState, 
                    ['alignItems'] : 'flex-start',
                    ['padding'] : '45px 0',
                }))

    }, [type])

    useEffect(()=>{
        if(Object.keys(style).length !== 0)
            setArrStyle(prevState=>({...prevState, style}))

    }, [style])

    useEffect(()=>{
        console.log({arrStyle});
    }, [arrStyle])

    const handler = evt => {
        if (evt.key === 'Escape') {
            closePopup({status:0})
        }
    }
    const handleBackdrop = evt =>{
        if(evt.currentTarget === evt.target) closePopup({status:0})
    }

    // const arrStyle = {}

    // if(type === "top"){
    //     arrStyle['alignItems'] = 'flex-start'
    //     arrStyle['padding'] = '45px 0'
    // }

    
    return createPortal(
            <>
            <div className={[styles.Popup, className].join(' ')} style={arrStyle}  onClick={handleBackdrop} >
                <div className={styles.PopupBody} style={{width:width}}>
                    <div className="panel panel-default" style={{ minWidth: '300px',}}>
                        <div className="panel-heading">
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <h3 className="panel-title"><i className="fa fa-list"></i> {title}</h3>
                                <span style={{marginRight:24}}>{titleRight}</span>
                            </div>
                            <b className={styles.close} onClick={()=>closePopup({status:0})}> &times;</b>
                            
                        </div>
                        <div className="panel-body">{children}</div>
                    </div>
                </div>
            </div>
            </>,
            modal
        ) 
}

Popup.defaultProps = {
    width:"520px",
    type:"",
    closePopup: () => {},
    title:'',
    titleRight:'',
}



// export default class Popup extends Component {
//     constructor(props){
//         super(props);

//         this.state = {
//             width:this.props.widthl
//         }
//     }

//     static defaultProps = {
//         width:"520px",
//         type:""
//     }

//     componentDidMount = () => {
//         window.addEventListener('keydown', this.handler)
//     }
//     componentWillUnmount = () => {
//         window.removeEventListener('keydown', this.handler)
//     }
//     handler = evt => {
//         if (evt.key === 'Escape') {
//             this.props.closePopup()
//         }
//     }

//     render(){
//         const { children } = this.props;
//         const { width, type } = this.props;
//         const arrStyle = {}

//         if(type === "top"){
//             arrStyle['alignItems'] = 'flex-start'
//             arrStyle['padding'] = '45px 0'
//         }
//         return createPortal(
//             <>
//             <div className={styles.Popup} style={arrStyle} >
//                 <div className={styles.PopupBody} style={{width:width}}>{children}</div>
//             </div>
//             </>,
//             modal
//         )
//     }
// }

