import React from 'react';
import { Route } from 'react-router-dom';

import Header from './../../components/Header';
import Footer from './../../components/Footer';
import Nav from './../../components/Nav';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../redux/auth';




function MainLayout({ children }) {
  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated)
  return(
    <>
      <Header />
      {isAuthenticated && <Nav />}
      <div id="content">{children}</div>
      <Footer />
    </>
  
  );
}; 

  
export default ({component: Component, ...rest}) => {
  // console.log(rest);
  // console.log(Component);
    return (
      <Route {...rest} render={matchProps => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )} />
    )
  };

