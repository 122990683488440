import { createSelector } from '@reduxjs/toolkit';

const getMenu = state =>  Boolean(state.lang.menu);
const getLang = state =>  state.lang.LANG;


// const getProjectId = state => state.auth.project_id
// const getThree3dProjectId = state => state.three3d.json2dModel.project_id || 0;

// const getAuthUserName = state => state.auth.user.name;
// const getError = state => state.auth.error;
// const authLoading = state => state.auth.loading;

// const projectId = createSelector([getIsAuthenticated, getProjectId, getThree3dProjectId], (isAuthenticated, project_id, three3d_project_id)=>{
//     return isAuthenticated ? project_id : three3d_project_id
// })

export default {
    getMenu,
    getLang
}