
import { Redirect, Route } from "react-router-dom"
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import { NavLink } from "react-router-dom"
// import Footer from "../componentsViews/Footer";
// import Header from "../componentsViews/Header";
// import MainLayoutView from "./MainLayoutView";

// import './../assets/css/navbar-top.css'
import './../assets/css/style_demo.css'
import './../assets/css/dist/css/bootstrap-demo.css'
// import './../../node_modules/bootstrap/dist/css/bootstrap.min.css'


// import './../assetsViews/css/bootstrap.min.css'
// import './../assetsViews/css/stylesheet.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faImage, faInfoCircle, faCheckSquare, faQrcode, faCoffee, faChevronRight, faChevronLeft, faPen, faTrash, faCheck, faEllipsisV, faPlus, faMinus, faPlusCircle, faSortDown, faPhoneAlt, faLink, faChevronUp, faChevronDown, faCloudUploadAlt, faArrowUp, faArrowDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from "react";

library.add(fab, faImage, faInfoCircle, faQrcode, faCheckSquare, faCoffee, faChevronRight, faChevronLeft, faPen, faTrash, faCheck, faEllipsisV, faPlus, faMinus, faPlusCircle, faSortDown, faPhoneAlt, faLink, faChevronUp, faChevronDown, faCloudUploadAlt, faArrowUp, faArrowDown, faSearch)


const MainRoute = ({ children}) =>{

    useEffect(()=>{

        document.querySelector('body').classList.add('demo')

        // document.querySelector('head').innerHTML += `<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">`
        // <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    }, [])

    return(
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/demo" style={{fontSize: '3.25rem'}}>Demo</Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {/* <Nav.Link href="#features">Features</Nav.Link>
                        <Nav.Link href="#pricing">Pricing</Nav.Link>
                        <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                    {/* <Nav>
                        <NavLink to="/login" className="nav-link">Vender login</NavLink>
                    </Nav> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
            <div className="container-fluid">    
                <div className="row content">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 text-left"> 
                        {children}
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

const DemoRoute = ({component:Component, redirectTo, ...routeProps}) =>{
    // const isAuthenticated = useSelector(authSelectors.getIsAuthenticated)s
    return <Route 
        {...routeProps}

        render={props => <MainRoute> <Component {...props} /> </MainRoute>}
    />
}

export default DemoRoute