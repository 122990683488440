import { useSelector } from "react-redux";
import { authSelectors } from "../redux/auth";
import { modelSelectors } from "../redux/model";
import Footer from "./Footer";
import Header from "./Header";
import LoaderRequest from "./LoaderRequest/LoaderRequest";
import Nav from "./Nav";

import CSSModules from 'react-css-modules';


import './../../node_modules/jquery/dist/jquery.min.js'
// import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../../node_modules/bootstrap/dist/js/bootstrap.min.js';

import bootstrap from './../app/stylesheet/bootstrap.css'
import fontAwesome from './../app/javascript/font-awesome/css/font-awesome.min.css'
import bootstrapDatetimepicker from './../app/javascript/jquery/datetimepicker/bootstrap-datetimepicker.min.css'
import stylesheet from './../app/stylesheet/stylesheet.css'
import style from './../style.css'

import slick from "slick-carousel/slick/slick.css";
import slickTheme from "slick-carousel/slick/slick-theme.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faImage, faInfoCircle, faCheckSquare, faQrcode, faCoffee, faChevronRight, faChevronLeft, faPen, faTrash, faCheck, faEllipsisV, faPlus, faMinus, faPlusCircle, faSortDown, faPhoneAlt, faLink, faChevronUp, faChevronDown, faCloudUploadAlt, faArrowUp, faArrowDown, faSearch, faSortAlphaDown, faSortAlphaUpAlt } from '@fortawesome/free-solid-svg-icons'


library.add(fab, faImage, faInfoCircle, faQrcode, faCheckSquare, faCoffee, faChevronRight, faChevronLeft, faPen, faTrash, faCheck, faEllipsisV, faPlus, faMinus, faPlusCircle, faSortDown, faPhoneAlt, faLink, faChevronUp, faChevronDown, faCloudUploadAlt, faArrowUp, faArrowDown, faSearch, faSortAlphaDown, faSortAlphaUpAlt)



const multiStyles = {
    ...bootstrap,
    ...fontAwesome,
    ...bootstrapDatetimepicker,
    ...stylesheet,
    ...style,
    ...slick,
    ...slickTheme
}

const MainLayoutPrivate = ({children}) => {
    const isAuthenticated = useSelector(authSelectors.getIsAuthenticated)
    const loader = useSelector(modelSelectors.getLoader)
    return(
      <>
        <Header />

    
        {isAuthenticated && <Nav />}
        <div id="content">{children}</div>
        <Footer />

        {loader && <LoaderRequest />}
      </>
    
    );
}
export default CSSModules(MainLayoutPrivate, multiStyles);
