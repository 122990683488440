import { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux";
import { modelOperations } from "../../redux/model";
import Popup from "../Popup/Popup"


const PopupPayoutRequest = ({
    closePopup,
    lang,
    errCode,
    onGetPayoutMethodNames,
    getPayoutMethodNames,
    onGetPayoutMethodTypes,
    getPayoutMethodTypes,
    onGetPayoutMethods,
    getPayoutMethods,
    onCreateUpdatePayoutMethod,
    onCreatePayout,
    getVendorInfo
}) => {

    const [range, setRange] = useState(0)

    const [popupPayoutMethod, setPopupPayoutMethod] = useState(false);
    const [rel, setRel] = useState(0);
    const [address, setAddress] = useState('')

    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [methodId, setMethodId] = useState(0)
    
    const [id, setId] = useState(0)
    const [disabled, setDisabled] = useState(false);

    const [token, setToken] = useState(0)
    const [flag, setFlag] = useState(0)


    useEffect(()=>{
        if(popupPayoutMethod){
            onGetPayoutMethodNames({DEFAULT_COLUMN:lang})
            onGetPayoutMethodTypes({DEFAULT_COLUMN:lang})
        }else{
            onGetPayoutMethods({DEFAULT_COLUMN:lang})
        }
    }, [popupPayoutMethod])

    useEffect(()=>{
        if(rel === 1){
            
        }else if(rel === 0){
            onGetPayoutMethods({DEFAULT_COLUMN:lang})
            setName(getPayoutMethodNames[0]?.METHOD_NAME)
            setType(getPayoutMethodTypes[0]?.METHOD_TYPE)
            setMethodId(0)
            setAddress('')
        }
    }, [rel])

    useEffect(()=>{
        if(Object.keys(getVendorInfo).length > 0){
            const {BALANCES} = getVendorInfo;
            // BALANCES?.filter(({TYPE_INDEX})=>[3].indexOf(TYPE_INDEX) > -1)

            const index = BALANCES?.findIndex(({TYPE_INDEX})=>[3].indexOf(TYPE_INDEX) > -1)
            const {BALANCE} = BALANCES[index];
            const balanceToken = Number(BALANCE.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>PRICE).join(''))


            setToken(balanceToken)

        }
    }, [getVendorInfo])

    useEffect(()=>{
        if(getPayoutMethodNames.length !== 0){
            setName(getPayoutMethodNames[0]?.METHOD_NAME)
        }
    }, [getPayoutMethodNames])

    useEffect(()=>{
        if(getPayoutMethodTypes.length !== 0){
            setType(getPayoutMethodTypes[0]?.METHOD_TYPE)
        }
    }, [getPayoutMethodTypes])

    useEffect(()=>{
        if(errCode === 1 && rel === 0 && flag === 1){
            setFlag(0)
            closePopup()
        }else if(errCode === 1 && rel === 1){
            setRel(0)
            onGetPayoutMethods({DEFAULT_COLUMN:lang})
        }
    }, [errCode, rel])

    useEffect(()=>{
        if(methodId !== 0){
            const index = getPayoutMethods.findIndex(({METHOD_ID}) => METHOD_ID === methodId )
            if(index !== -1){
                const {METHOD_NAME, METHOD_TYPE, METHOD_ADDRESS} = getPayoutMethods[index];

                setName(METHOD_NAME)
                setType(METHOD_TYPE)
                setAddress(METHOD_ADDRESS)
            }
        }
    }, [methodId])

    useEffect(()=>{
        if(id === 0){
            setDisabled(true) 
            return false;
        }

        setDisabled(false)
    }, [id])


    const save = () => {
        onCreateUpdatePayoutMethod({
            DEFAULT_COLUMN:lang,
            METHOD_ID:methodId,                //  - id метода (0 - создать)
            METHOD_TYPE:type,    //   - тип метода
            METHOD_NAME:name,           //    - название
            METHOD_ADDRESS:address         //    - public address
        })
    }

    const request = () => {
        setFlag(1)

        onCreatePayout({
            METHOD_ID: id,     //   - id метода
            COINS_QTY: range     //   - кол-во коинов
        })
    }

    return (
        <>
        <Popup closePopup={closePopup} title="Payout Request">
            {/* <h3 className="text-center">Balance</h3> */}
            {/* <p  className="text-center">{getVendorInfo?.VENDOR_ADDRESS} </p> */}

            {/* <div className="text-center">
                <div className="divss">{ Number((getVendorInfo?.BALANCE ).toFixed(2)) } {getVendorInfo?.CURRENCY} <br/> ({ Number((getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS ).toFixed(2)) } Hiiroad coins) </div>
            </div> */}

{
                getVendorInfo?.BALANCES?.filter(({TYPE_INDEX})=>[3].indexOf(TYPE_INDEX) > -1).map(({TYPE_INDEX, BALANCE}, k)=>{

                    const balance = Number(BALANCE.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === getVendorInfo?.CURRENCY).map(({PRICE})=>PRICE).join(''))
                    const balanceToken = Number(BALANCE.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>PRICE).join(''))

                    return(<Fragment key={k}>

                         {/* <h3 className="text-center">
                                {TYPE_INDEX === 2 && 'Reward balance:'}
                                {TYPE_INDEX === 3 && 'Payout balance:'}
                         </h3> */}
                         <h3 className="text-center">Balance</h3>
                        <p  className="text-center">
                            {/* {TYPE_INDEX === 2 && getVendorInfo?.LOYALTY_ADDRESS}  */}
                            {TYPE_INDEX === 3 && getVendorInfo?.VENDOR_ADDRESS} 

                        </p>

                        <div className="text-center">
                            <div className="divss">
                                <div style={{lineHeight: "8px", textAlign: 'center',display: 'flex', alignItems: 'center'}}> <i className='hiiroad'></i> <b style={{marginRight:6}}>{balanceToken}</b>({ balance } {getVendorInfo?.CURRENCY})</div>
                            </div>
                            {/* <div className="divss">{ Number((getVendorInfo?.BALANCE ).toFixed(2)) } {getVendorInfo?.CURRENCY} <br/> ({ Number((getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS ).toFixed(2)) } Hiiroad coins) </div> */}
                        </div>
                        <br/>
                    </Fragment>)   
                })
            }
            <br/>
            <div style={{display:'flex', justifyContent:'space-between'}}> <span>10</span> <span>{range} Hiiroad</span> <span>{token}</span> </div>
            <input type="range" name="vol" min="10" max={token} value={range} onChange={({target:{value}}) => setRange(Number(value))} />
            <div style={{display:'flex', justifyContent:'space-between'}}> 
                {/* <span>{Number(  (getVendorInfo?.BALANCE / (getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS) * 10 ).toFixed(2)) } {getVendorInfo?.CURRENCY} </span>  */}
                {/* <span>{Number(  (getVendorInfo?.BALANCE / (getVendorInfo?.COINS_QTY / getVendorInfo?.DECIMALS) * range ).toFixed(2)) } {getVendorInfo?.CURRENCY} </span>  */}
                {/* <span>{Number((getVendorInfo?.BALANCE ).toFixed(2))} {getVendorInfo?.CURRENCY}</span>  */}
            </div>

            <br/>
            <div style={{display: 'flex'}}>
                <div style={{    width: '100%'}}>
                <select className="form-control" style={{borderColor: id === 0 && 'red'}} value={id} onChange={({target:{value}}) => setId(Number(value))}>
                    <option value={0} disabled hidden></option>
                    {
                        getPayoutMethods.map(({METHOD_ID, METHOD_NAME}, k)=>{
                            return(<option key={k} value={METHOD_ID} >{METHOD_NAME}</option>)
                        })
                    }
                </select>
                {id === 0 && <small style={{color:'red'}}>Please select payout source</small>}
                </div>
                <i onClick={()=>setPopupPayoutMethod(true)} className="fa fa-pencil" style={{color: 'rgb(30, 145, 207)', fontSize: 16, marginLeft:12, marginTop: 8,  cursor:'pointer'}} />
            </div>
            <br/>
            <div className="text-center">
                <button type="button" className="btn btn-primary" onClick={()=>request()} disabled={disabled} >Request </button>
                <button type="button" className="btn btn-danger" onClick={()=>closePopup()} style={{marginLeft:12}} >Close </button>
            </div>

            {errCode === 101 && <span style={{color:'red', textAlign: 'center', display: 'block'}}>You have a pending payment request</span>}
        
        </Popup>

        {popupPayoutMethod &&
            <Popup closePopup={()=>setPopupPayoutMethod(false)} title="Payout methods" width={rel === 0 ? '1000px' : '600px'}>
                {rel === 0 &&
                <>
                    <button type="button" className="btn btn-primary" onClick={()=>{setMethodId(0); setRel(1)}} style={{position: 'absolute', top: 19, right: 58}} > Add new method </button>
               
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <td className="text-left"> Type </td>
                                <td className="text-left"> Name </td>
                                <td className="text-left"> Account </td>
                                <td className="text-left" width="5%">  </td>
                            </tr>
                    </thead>
                        <tbody>
                            {
                                getPayoutMethods.map(({METHOD_ID, METHOD_ADDRESS, METHOD_NAME, METHOD_TYPE}, k)=>{
                                    return(<tr key={k}>
                                                <td className="text-left"> {METHOD_TYPE} </td>
                                                <td className="text-left"> {METHOD_NAME} </td>
                                                <td className="text-left"> {METHOD_ADDRESS} </td>
                                                <td><i className="fa fa-pencil" onClick={()=>{setMethodId(METHOD_ID); setRel(1)}} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} /></td>
                                            </tr>)
                                })
                            }
                            {/* <tr>
                                <td className="text-left"> Blockchain </td>
                                <td className="text-left"> Bitcoin </td>
                                <td className="text-left"> 0xjuvshfiuerfjaorifjaorifaofiaf </td>
                                <td><i className="fa fa-pencil" onClick={()=>setRel(1)} style={{color: 'rgb(30, 145, 207)', fontSize: 16, cursor:'pointer'}} /></td>
                            </tr> */}
                        </tbody>
                    </table>
                    <div className="text-center">
                        <button type="button" className="btn btn-danger" onClick={()=>setPopupPayoutMethod(false)} style={{marginLeft:12}} >Close </button>
                    </div>
                </>

                }
                
            {rel === 1 &&
                <>
                    <div className="form-group gr_f">
                        <label  htmlFor="input_112" style={{width: '20%',textAlign: 'right'}}>Type</label>
                        <select className="form-control" value={type} onChange={({target:{value}})=>setType(value)}>
                            <option value={''} disabled hidden></option>
                            {
                                getPayoutMethodTypes.map(({METHOD_TYPE}, k)=>{
                                    return(<option key={k} value={METHOD_TYPE}>{METHOD_TYPE}</option>)
                                })
                            }
                        </select>
                        {/* <input type="name" className="form-control" value={address} onChange={({target:{value}}) => setAddress(value)} />  */}
                    </div>
                    <div className="form-group gr_f">
                        <label  htmlFor="input_112" style={{width: '20%',textAlign: 'right'}}>Name</label>
                        <select className="form-control" value={name} onChange={({target:{value}})=>setName(value)}>
                            <option value={''} disabled hidden></option>
                            {
                                getPayoutMethodNames.map(({METHOD_NAME}, k)=>{
                                    return(<option key={k} value={METHOD_NAME} >{METHOD_NAME}</option>)
                                })
                            }
                        </select>
                        {/* <input type="name" className="form-control" value={address} onChange={({target:{value}}) => setAddress(value)} />  */}
                    </div>
                    <div className="form-group gr_f">
                        <label  htmlFor="input_112" style={{width: '20%',textAlign: 'right'}}>Address</label>
                        <input type="name" className="form-control" value={address} onChange={({target:{value}}) => setAddress(value)} /> 
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-primary" onClick={()=>save()} > {methodId === 0 ? 'Save' : 'Update'} </button>
                        <button type="button" className="btn btn-danger" onClick={()=>setRel(0)} style={{marginLeft:12}} >Close </button>
                    </div>
                </>
                }
            </Popup>
        }
        </>
    ) 
}
const mapStateToPorps = (state) => ({
    lang:state.lang.LANG,
    errCode:state.model.err_code,
    getPayoutMethodNames:state.model.getPayoutMethodNames,
    getPayoutMethodTypes:state.model.getPayoutMethodTypes,
    getPayoutMethods:state.model.getPayoutMethods,
    getVendorInfo:state.model.getVendorInfo,

})

const mapDispatchToProps = {
    onGetPayoutMethodNames:modelOperations.getPayoutMethodNames,
    onGetPayoutMethodTypes:modelOperations.getPayoutMethodTypes,
    onGetPayoutMethods:modelOperations.getPayoutMethods,
    onCreateUpdatePayoutMethod:modelOperations.createUpdatePayoutMethod,
    onCreatePayout:modelOperations.createPayout,
}

export default connect(mapStateToPorps, mapDispatchToProps)(PopupPayoutRequest);
