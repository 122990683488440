import { createSelector } from '@reduxjs/toolkit';

const getIsAuthenticated = state =>  Boolean(state.auth.token);
const getToken = state =>  state.auth.token;
const getErrorCode = state => state.auth.errCode
const getErrorMessage = state => state.auth.errMessage
const getAdminName = state => state.auth.fullAdminName


// const getProjectId = state => state.auth.project_id
// const getThree3dProjectId = state => state.three3d.json2dModel.project_id || 0;

// const getAuthUserName = state => state.auth.user.name;
// const getError = state => state.auth.error;
// const authLoading = state => state.auth.loading;

// const projectId = createSelector([getIsAuthenticated, getProjectId, getThree3dProjectId], (isAuthenticated, project_id, three3d_project_id)=>{
//     return isAuthenticated ? project_id : three3d_project_id
// })

export default {
    getIsAuthenticated,
    getErrorCode,
    getErrorMessage,
    getAdminName,
    getToken

}