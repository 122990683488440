import Popup from "../Popup/Popup"
import { Col, Form, Row } from "react-bootstrap"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { modelOperations } from "../../redux/model"
import { faSave } from "@fortawesome/free-solid-svg-icons"

const PopupEditKey = ({
    closePopup,
    errCode,
    lang,
    onGetUserBlockchainAddress,
    getUserBlockchainAddress,
    onUpdateUserBlockchainAddress
}) => {

    const [use, setUse] = useState(0)
    const [key, setKey] = useState('')
    const [disabled, setDisabled] = useState(false);

    useEffect(()=>{
        onGetUserBlockchainAddress({DEFAULT_COLUMN:lang})
    }, [])

    useEffect(()=>{
        console.log(getUserBlockchainAddress);

        if(Object.keys(getUserBlockchainAddress).length !== 0){
            const {USED_ADDRESS_TYPE, INTERNAL_ADDRESS, EXTERNAL_ADDRESS} = getUserBlockchainAddress
            setUse(USED_ADDRESS_TYPE)
            setKey(EXTERNAL_ADDRESS)
        }

    }, [getUserBlockchainAddress])

    useEffect(()=>{
        if(errCode === 1) closePopup()
    }, [errCode])

    useEffect(()=>{

        if(use === 1 && (key === '' || key.length < 20)){
            setDisabled(true)
            return;
        }

        setDisabled(false)

    }, [use, key])

    const save = () => {
        onUpdateUserBlockchainAddress({
            DEFAULT_COLUMN:lang,
            EXTERNAL_ADDRESS: key, //- адрес пользователя
            USED_ADDRESS_TYPE:use // - тип использования (0 - hiiroad, 1- ropsten)        
        })
    }

    return (
        <Popup closePopup={closePopup} title="Change public key">
            <div className="modal-body">

                <Form.Group className="mb-3" as={Row} controlId="formBasicname">
                    
                    <Col >
                        <label><input type="radio" name="use" checked={use === 0}  value={0} onChange={()=>setUse(0)} /> Use internal public key </label><br/>
                        <Form.Text className="text-muted" style={{fontSize:14}}>all transactions will be signed automatically</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row} controlId="formBasicname">
                    
                    <Col >
                        <label><input type="radio" name="use" checked={use === 1}  value={1} onChange={()=>setUse(1)} /> Use external public key </label><br/>
                        <Form.Text className="text-muted" style={{fontSize:14}}>all transactions must be signed every-time for each operation</Form.Text>
                    </Col>
                </Form.Group>
               {use === 1 && <Form.Group className="mb-3" as={Row} controlId="formBasicname">
                    {/* <Form.Label column sm={3} className="text-right "></Form.Label> */}
                    <Col>
                        <Form.Control type="text" minLength={20} value={key} onChange={({target:{value}})=>setKey(value)} style={{borderColor:(key === '' || key.length < 20 ) && 'red'}} />
                        {key === '' && <Form.Text style={{fontSize:14, color:'red'}}>The field is required</Form.Text>}
                        {key !== '' && key.length < 20 && <Form.Text  style={{fontSize:14, color:'red'}}>The field must contain a minimum of: 20</Form.Text>}
                    </Col>
                </Form.Group>}
            </div>
            <div className="modal-footer">
               <button type="button" className="btn btn-primary" disabled={disabled} onClick={()=>save()}>Save</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{closePopup()}}>Close</button>
            </div>
        </Popup>
    ) 
}

const mapStateToProps = (state) => ({
    errCode:state.model.err_code,
    lang:state.lang.LANG,
    getUserBlockchainAddress:state.model.getUserBlockchainAddress,
})

const mapDispatchToProps = {
    onUpdateUserBlockchainAddress:modelOperations.updateUserBlockchainAddress,
    onGetUserBlockchainAddress:modelOperations.getUserBlockchainAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupEditKey);
