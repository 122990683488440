import { useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"

const ToLink = ({
    name,
    link,
    icon,
    subMenu=[]
}) => {
    
    const [show, setShow] = useState(false);
    const ref = useRef()

    useEffect(()=>{
        document.addEventListener('click', handle)
        return () => {
            document.removeEventListener('click', handle)
        }
    }, [])

    const handle = evt => {
        // console.log(evt);
        const {target} = evt
        if(ref.current === target.closest('.menuLink')){
            return
        }
        setShow(false)
    }

    return (
        <li className="menuLink" style={{position:'relative'}} ref={ref}>
            {subMenu.length === 0 && <NavLink to={link}><i className={icon}></i> {name}</NavLink>}

            {subMenu.length > 0 &&
            <>
                <a className="parent collapsed" onClick={()=>setShow(!show)}><i className={icon}></i> {name}</a> 
                {show && <ul  style={{ position: 'absolute', top: 42, zIndex: 100, width: 'max-content'}}>
                    {
                        subMenu.map((props, k)=>(<ToLink {...props} key={k} />))
                    }
                </ul>}
            </>}
        
        </li>
    ) 
}

export default ToLink