import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

// import './../node_modules/jquery/dist/jquery.min.js'
// // import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import './../node_modules/bootstrap/dist/js/bootstrap.min.js';


// // import './app/javascript/jquery/datetimepicker/moment/moment.min.js'
// // import './app/javascript/jquery/datetimepicker/moment/moment-with-locales.min.js'
// // import './app/javascript/jquery/datetimepicker/bootstrap-datetimepicker.min.js'
// // import './app/javascript/common.js'

// import './app/stylesheet/bootstrap.css'
// import './app/javascript/font-awesome/css/font-awesome.min.css'
// import './app/javascript/jquery/datetimepicker/bootstrap-datetimepicker.min.css'
// import './app/stylesheet/stylesheet.css'
// import './style.css'

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import './assets/V3/Content/fonts/fontawesome/css/all.css'
import './all.css'


ReactDOM.render(<BrowserRouter>
  <App />
</BrowserRouter>, 
document.querySelector('#root'));

