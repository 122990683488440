import moment from "moment"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { modelOperations } from "../../redux/model"
import Popup from "../Popup/Popup"
import PopupBrainTree from "./PopupBrainTree"
import PopupCrypto from "./PopupCrypto"
import PopupPayPal from "./PopupPayPal"
import PopupStrip from "./PopupStrip"

const PopupReload = ({
    closePopup,
    lang,
    errCode,
    onGetGlobalPaymentSystems,
    getGlobalPaymentSystems,
    getGlobalPaymentSystemsHistory,
    onPaymentSystemsCoinsAmount,
    paymentSystemsCoinsAmount,
    onSaveProceedToCheckout,
    saveProceedToCheckout,
    getVendorInfo
}) => {

    const [coins, setCoins] = useState(0);
    const [idPayment, setIdPayment] = useState(0);
    const [namePayment, setNamePayment] = useState('');
    const [payment, setPayment] = useState({})

    const [disabled, setDisabled] = useState(false);
    const [errShow, setErrShow] = useState(false);

    const [popupCrypto, setPopupCrypto] = useState(false);
    const [cointAmount, setCointAmoint] = useState({})

    const [flag, setFlag] = useState(0)

    const [popupPayPal, setPopupPayPal] = useState(false);
    const [popupBrainTree, setPopupBrainTree] = useState(false);
    const [popupStrip, setPopupStrip] = useState(false);


    useEffect(()=>{
        setCoins(0)
        setIdPayment(0)
        setNamePayment('')
        setPayment({})
        reset()
    }, [])

    useEffect(()=>{
        if(Object.keys(paymentSystemsCoinsAmount).length !== 0){
            const { data } = paymentSystemsCoinsAmount;
            setCointAmoint(data)
        }
    }, [paymentSystemsCoinsAmount])

    useEffect(()=>{
        if(errCode === 1){
            const {TYPE} = payment;

            if(flag === 0){
                // closePopup()
                return false;
            } 

            if(TYPE === 0){ 
                console.log('setPopupCrypto');
                setFlag(0)
                setPopupCrypto(true);
            }

            if(TYPE === 3){ 
                setFlag(0)
                setPopupPayPal(true);
            }

            if(TYPE === 1){ 
                setFlag(0)
                setPopupStrip(true);
            }


            // closePopup()
        }
    }, [errCode, flag])

    useEffect(()=>{

        // if(coins === 0 || idPayment === 0) return false;

        if(coins !== 0 && idPayment !== 0){
            setDisabled(false)
            return false;
        }   

        setDisabled(true)

    }, [coins, idPayment])

    const handlePayment = (prop) =>{
        const {NAME, ID_RECORD} = prop;
        setIdPayment(ID_RECORD)
        setNamePayment(NAME)
        setErrShow(false)

        setPayment(prop)

        if(coins !== 0)
            onPaymentSystemsCoinsAmount({
                COINS_QTY:coins,
                PAYMENT_NAME:NAME
            })
        else setErrShow(true)
    }

    const handleCoins = ({target:{value}}) => {
        setCoins(Number(value))

        if(namePayment !== ''){
            setErrShow(false)
            onPaymentSystemsCoinsAmount({
                COINS_QTY:Number(value),
                PAYMENT_NAME:namePayment
            }) 
        }
    }

    const proceed = () => {
        setFlag(1)
        onSaveProceedToCheckout({
            DEFAULT_COLUMN:lang
        })
    }

    const reset = () => {
        onGetGlobalPaymentSystems({DEFAULT_COLUMN:lang})
    }

    return (
        <>
        <Popup closePopup={closePopup} width={1200} >
            <div className="modal-body">

                <div style={{display:'flex'}}>
                    <div style={{borderRight: '1px solid gray', paddingRight: 8, marginRight: 8, width:'30%'}}>
                        <h2>Reload balance</h2>

                        <h4><b>Coins amount</b></h4>

                        <div style={{display:'flex'}}>
                            <div style={{width:'50%'}}>
                                <label style={{fontWeight:'normal'}}><input type="radio" name="coin" value={10} checked={coins === 10} onChange={handleCoins} /> 10 Hiiroad coins </label><br/>
                                <label style={{fontWeight:'normal'}}><input type="radio" name="coin" value={20} checked={coins === 20} onChange={handleCoins} /> 20 Hiiroad coins </label>
                        
                            </div>
                            <div style={{width:'50%'}}>
                                <label style={{fontWeight:'normal'}}><input type="radio" name="coin" value={50} checked={coins === 50} onChange={handleCoins} /> 50 Hiiroad coins </label><br/>
                                <label style={{fontWeight:'normal'}}><input type="radio" name="coin" value={100} checked={coins === 100} onChange={handleCoins} /> 100 Hiiroad coins </label>
                            </div>
                        </div>
                        <br/>
                        <h4><b>Payment method</b></h4>

                        {
                            getGlobalPaymentSystems.map((v, k)=>{
                                const {NAME, ID_RECORD, CURRENCY_SHORT_NAME} = v;
                                let value = ''

                                if(Object.keys(paymentSystemsCoinsAmount).length !== 0){
                                    const { credential:{PAYMENT_NAME},  data:{RATE_SHORT_NAME, PRICE}} = paymentSystemsCoinsAmount;
                                    
                                    // if(CURRENCY_SHORT_NAME === RATE_SHORT_NAME){
                                    if(PAYMENT_NAME === NAME && idPayment !== 0){
                                        value = `${Number(PRICE.toFixed(2))}  ${RATE_SHORT_NAME}`;
                                    }
                                }   

                                return(
                                    <div key={k} style={{display:'flex', justifyContent:'space-between'}}>
                                        <label style={{fontWeight:'normal'}}><input type="radio" name="payment" value={ID_RECORD} checked={ID_RECORD === idPayment} onChange={()=>handlePayment(v)} /> {NAME}  </label>
                                        {value}
                                    </div>
                                )
                            })
                        }
                        {errShow && <span style={{color:'red'}}>Please select coins amount </span>}

                        {/* <div style={{display:'flex', justifyContent:'space-between'}}>
                            <label style={{fontWeight:'normal'}}><input type="radio" name="payment" /> Bitcoin  </label>
                            {`{value}`}
                        </div>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <label style={{fontWeight:'normal'}}><input type="radio" name="payment" /> Ethereum  </label>
                            {`{value}`}
                        </div>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <label style={{fontWeight:'normal'}}><input type="radio" name="payment" /> Visa/Master card  </label>
                            {`{value}`}
                        </div> */}
                       
                        <br/>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <button type="button" className="btn btn-primary" disabled={disabled} onClick={()=>proceed()}>Proceed</button>
                            <button type="button" className="btn btn-secondary" style={{marginLeft:12}} onClick={()=>{closePopup()}}>Close</button>
                        </div>
                    </div>
                    <div style={{width:'70%'}}>
                        <h2>Reload history</h2>
                        <table className="table table-bordered table-hover" style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <td >Date</td>
                                    <td >Coins QTY</td>
                                    <td > Payment type </td>
                                    <td > Amount </td>
                                    <td > Type </td>
                                    <td > Status </td>
                                </tr>
                        </thead>
                            <tbody>
                                {
                                    getGlobalPaymentSystemsHistory.map(({transaction_date, payment_type, transaction_type, amount, status, currency, coins_qty}, k )=>{
                                    
                                        const amountA = Number(amount.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === getVendorInfo?.CURRENCY).map(({PRICE})=>PRICE).join(''))
                                        const amountToken = Number(amount.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>PRICE).join(''))

                                        
                                     return(<tr key={k}>
                                        <td> {moment.unix(transaction_date).format('DD-MM-YYYY hh:mm A')} </td>
                                        <td> {Number(coins_qty.toFixed(2))}</td>
                                        <td> {payment_type}</td>
                                        <td> 
                                             {/* {currency} */}
                                             <div style={{lineHeight: "8px", textAlign: 'center',display: 'flex', alignItems: 'center'}}> <i className='hiiroad'></i> <b style={{marginRight:6}}>{amountToken}</b>({ amountA } {getVendorInfo?.CURRENCY})</div>
                                        </td>
                                        <td>
                                            {transaction_type === 0 && 'Order'}
                                            {transaction_type === 1 && 'Reward'}
                                            {transaction_type === 2 && 'Reload balance'}
                                            {transaction_type === 3 && 'Reward distribution'}
                                            {transaction_type === 4 && 'Not confirmed sale'}
                                            {transaction_type === 5 && 'Confirmed sale'}
                                            {transaction_type === 6 && 'Payout'}
                                            {transaction_type === 7 && 'Sending out'}
                                            {transaction_type === 8 && 'External Received'}
                                        </td>
                                        <td>
                                            {status === 0 && <span className="label label-default">Pending</span>}
                                            {status === 1 && <span className="label label-dnager">Declined</span>}
                                            {status === 2 && <span className="label label-success">Approved</span>}
                                        </td>
                                    </tr>)   
                                    })
                                }
                                {/* <tr>
                                    <td> 1 </td>
                                    <td> 2</td>
                                    <td> 2</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
               
            </div>
        </Popup>

        {popupCrypto && <PopupCrypto payment={payment} currency={cointAmount?.RATE_SHORT_NAME} total={cointAmount?.PRICE} closePopup={()=>{reset(); setPopupCrypto(false)}} />}
        {popupPayPal && <PopupPayPal payment={payment} currency={cointAmount?.RATE_SHORT_NAME} total={cointAmount?.PRICE} closePopup={()=>{reset();setPopupPayPal(false)}} /> }
        {popupBrainTree && <PopupBrainTree payment={payment} currency={cointAmount?.RATE_SHORT_NAME} total={cointAmount?.PRICE} closePopup={()=>{reset(); setPopupBrainTree(false)}} /> }
        {popupStrip && <PopupStrip payment={payment} currency={cointAmount?.RATE_SHORT_NAME} total={cointAmount?.PRICE} closePopup={()=>{reset(); setPopupStrip(false)}} /> }
        </>
    ) 
}
const mapStateToProps = (state) => ({
    errCode:state.model.err_code,
    lang:state.lang.LANG,
    getGlobalPaymentSystems:state.model.getGlobalPaymentSystems,
    getGlobalPaymentSystemsHistory:state.model.getGlobalPaymentSystemsHistory,
    paymentSystemsCoinsAmount:state.model.paymentSystemsCoinsAmount,
    saveProceedToCheckout:state.model.saveProceedToCheckout,
    getVendorInfo:state.model.getVendorInfo,
})

const mapDispatchToProps = {
    onGetGlobalPaymentSystems:modelOperations.getGlobalPaymentSystems,
    onPaymentSystemsCoinsAmount:modelOperations.paymentSystemsCoinsAmount,
    onSaveProceedToCheckout:modelOperations.saveProceedToCheckout,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupReload);
// export default PopupReload